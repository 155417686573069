import { render, staticRenderFns } from "./m-sendModal.vue?vue&type=template&id=bc7c19ee&scoped=true&"
import script from "./m-sendModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./m-sendModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./m-sendModal.vue?vue&type=style&index=0&id=bc7c19ee&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc7c19ee",
  null
  
)

export default component.exports