<template>
  <div class="login">
    <div class="warp">
      <div class="bg_img">
        <img src="../assets/image/loginimg.svg" alt="" />
      </div>
      <div
        class="form_warp"
        v-loading="isLogin"
        :element-loading-text="loginLoadingText"
      >
        <div class="form_title">登录</div>
        <div class="form">
          <div>
            <div class="title">手机号</div>
            <div class="flex">
              <a-select
                v-model="areaCode"
                class="mr8"
                show-search
                showArrow
                :filter-option="
                  (input, option) =>
                    option.componentOptions.children[0].text
                      .toLowerCase()
                      .trim()
                      .includes(input.toLowerCase())
                "
                id="login_area_code_select"
              >
                <a-select-option
                  v-for="(item, index) in areaCodeOptions"
                  :key="index"
                  :value="item.area_code"
                  >{{ item.country }}</a-select-option
                >
              </a-select>
              <input
                type="text"
                name=""
                class="input inline pl5"
                id="login_phone_input"
                v-model="loginMsg.phone"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div>
            <div class="title">密码</div>
            <div class="input">
              <input
                type="password"
                name=""
                id="login_password_input"
                v-model="loginMsg.pwd"
                placeholder="请输入密码"
                @keyup.enter="login"
              />
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="current_btn" @click="login" id="login_log_btn">登录</div>
          <div class="to_btn">
            <span class="left" @click="toEditPwd" id="login_forgot_password_btn"
              >忘记密码</span
            >
            <span class="rignt" @click="toRegister" id="login_register_btn"
              >注册账号</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { areaCodeOptions } from '@/util/area_code'
import md5 from 'md5'

import commonFun, { fetchMaterialColors } from '../util/commonFun'

export default {
  data() {
    return {
      loginMsg: {
        phone: '',
        pwd: '',
      },
      isLogin: false,
      loginLoadingText: '正在登录中,请稍等...',
      areaCode: '+86',
      areaCodeOptions,
    }
  },
  mounted() {
    if (this.$store.state.showPerson) {
      this.$store.commit('setShowPerson', false)
    }
  },
  methods: {
    toRegister() {
      this.$router.push({
        path: '/register',
      })
    },
    toEditPwd() {
      this.$router.push({
        path: '/editPwd',
      })
    },
    login() {
      if (!this.loginMsg.phone.match(/^1[3|4|7|5|8|6|9][0-9]\d{4,8}$/)) {
        this.$message({
          message: '手机号码格式不正确',
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (!this.loginMsg.pwd) {
        this.$message({
          message: '请输入密码',
          type: 'error',
          duration: 2000,
        })
        return
      }
      if (this.isLogin) {
        return
      }
      this.isLogin = true

      // 登录成功后跳转到pageTo页面
      let key = {
        phone: this.loginMsg.phone,
        password: md5(this.loginMsg.pwd),
      }
      let RSAKey = commonFun.calcKeyByRSA(key)
      this.$post(
        '/login',
        key,
        async (res) => {
          let pageTo = this.$route.query.pageTo
          if (res.status == 1) {
            sessionStorage.token = res.data.token
            sessionStorage.setItem('refresh_token', res.data.refresh_token)
            this.$store.commit('setUserInfo', res.data)
            this.$message({
              message: '登录成功',
              duration: 2000,
              type: 'success',
            })
            fetchMaterialColors()
            // 登录成功，清空preLayout数据
            this.$store.commit('setPreLayoutData', [])
            if (sessionStorage.thinkerx_material) {
              this.$router.push({
                path:
                  '/materialList?thinkerx_material=' +
                  sessionStorage.thinkerx_material,
              })
            } else {
              if (pageTo) {
                this.$router.push({
                  path: decodeURIComponent(pageTo),
                })
              } else {
                this.$router.push({
                  path: '/taskList',
                })
              }
            }
            this.$store.commit('setIsShowSample', true)
            // 不关闭加载页,因为加载料单可能会比较慢
            this.loginLoadingText = '登录成功,正在加载页面结构中...'
            // 如果公告的firstNotice参数不存在
            this.$emit('getNoReadNotice')
            if (!sessionStorage.getItem('firstNotice')) {
              // 调用父组件的方法
              this.$emit('getNoReadNotice')
            }
          } else {
            this.$message({
              type: 'error',
              duration: 2000,
              message: res.msg,
            })
            this.isLogin = false
          }
        },
        { sign: RSAKey }
      )
    },
  },
}
</script>

<style lang="less">
.login {
  width: 100%;
  height: 100vh;
  color: #333;
  background-color: #fff;

  .warp {
    position: relative;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 900px;
    height: 500px;
    margin: 0 auto;
    transform: translateY(-50%);

    .bg_img {
      width: 441px;
      height: 491px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .form_warp {
      width: 332px;

      .form_title {
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 32px;
      }

      .form {
        > div {
          margin-bottom: 40px;

          .title {
            margin-bottom: 8px;
            font-size: 14px;
          }

          .input {
            display: flex;
            flex-grow: 1;
            align-items: center;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 4px;
            outline: none;

            input {
              width: 100%;
              text-indent: 16px;
              border: none;
              outline: none;

              &::input-placeholder {
                color: #b0b0b0;
              }
            }
          }
        }
      }

      .btns {
        margin-top: 144px;

        .current_btn {
          width: 332px;
          height: 48px;
          color: #fff;
          font-size: 16px;
          line-height: 48px;
          letter-spacing: 4px;
          text-align: center;
          background: #18a8c7;
          border-radius: 4px;
          cursor: pointer;
        }
        .to_btn {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          color: #18a8c7;
          font-size: 14px;

          > span {
            cursor: pointer;
          }
        }
      }
    }
  }
  .ant-select-selection--single {
    min-width: 140px;
    height: 40px;
  }
  .ant-select-selection-selected-value {
    line-height: 40px;
  }
}
</style>
