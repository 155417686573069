<template>
  <div class="new-material-list ppppClass" v-loading="isTaskLoading">
    <MCustomizeTableHeader :table-headers="currentHeadPropList" />
    <!-- TODO wc 获取全部的板件数据 -->
    <!-- <div
      id="material_plankList_jsonData"
      style="width: 0px; height: 0px; overflow: hidden; position: fixed"
    >
      {{ JSON.stringify(filterPlankList) }}
    </div> -->
    <loading
      :loading="isShowLoading"
      type="1"
      useSlot
      :loadingTitle="
        isSendToFactory
          ? $t('arrangedPage.sendToFactory.title')
          : $t('materialPage.startArrange')
      "
    >
      <template slot="text">
        <div v-if="isSendToFactory">
          {{ $t('materialPage.sendLoadingText') }}
        </div>
        <div v-else>
          <span> {{ $t('materialPage.arrangeLoading') }}</span>
          <span class="fs18 ml5 mr5" style="color: #18a8c7">{{
            recordChoosePlankDataLength
          }}</span>
          {{ $t('materialPage.arrangeLoading2') }}
        </div>
      </template>
    </loading>
    <div class="material-list-main">
      <div class="top-operation pr0 pl0">
        <div class="top-left-operation">
          <a-button @click="handleBackTotask" v-if="dataFrom != 'ggpc'">
            <a-icon type="left" />
          </a-button>
          <!-- 开始排版 -->
          <a-button
            @click="showProLineBox"
            class="paiban-btn"
            id="material_paiban_btn"
            v-if="dataFrom != 'ggpc'"
            :title="translateLang('materialPage.startArrange')"
          >
            <span class="iconfont icon-typesetting"></span>
            <span>{{ $t('materialPage.startArrange') }}</span>
          </a-button>

          <a-button
            @click="openLocalFile"
            id="material_import_btn"
            block
            :title="translateLang('materialPage.importMaterial')"
          >
            <a-icon type="import" />
            <span class="ml2">{{ $t('materialPage.importMaterial') }}</span>
          </a-button>

          <input
            type="file"
            id="material_import_file_input"
            ref="fileUpload"
            @input="getFileData"
          />
          <a-button
            @click="showSavePaiban"
            :disabled="dataFrom == 'ggpc'"
            id="material_save_history_btn"
            :title="translateLang('materialPage.saveToHistory')"
          >
            <span class="iconfont icon-save"></span>
            <span>{{ $t('materialPage.saveToHistory') }}</span>
          </a-button>

          <a-button
            @click="openDrawer"
            :disabled="dataFrom == 'ggpc'"
            id="material_add_plank_btn"
            :title="translateLang('materialPage.addPlank')"
          >
            <span class="iconfont icon-add"></span>
            <span>{{ $t('materialPage.addPlank') }}</span>
          </a-button>

          <a-button
            @click="changeShowMsgDialog"
            :disabled="dataFrom == 'ggpc'"
            id="material_delete_plank_btn"
            :title="translateLang('materialPage.deletePlank')"
          >
            <span class="iconfont icon-delete"></span>
            <span>{{ $t('materialPage.deletePlank') }}</span>
          </a-button>

          <a-button
            @click="handlePlankEdit"
            :disabled="dataFrom == 'ggpc'"
            id="material_planks_edit_btn"
            :title="translateLang('materialPage.batchEdit')"
          >
            <span class="iconfont icon-xiugai"></span>
            <span>{{ $t('materialPage.batchEdit') }}</span>
          </a-button>

          <!-- 更多设置 -->
          <a-dropdown
            :trigger="['click']"
            class="more-setting"
            id="material_more_setting_dropdown"
          >
            <a-button
              id="material_more_setting_btn"
              :title="translateLang('materialPage.moreSetting')"
            >
              <span class="iconfont icon-expand01 ml5"></span>
              <span>{{ $t('materialPage.moreSetting') }}</span>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-popover v-show="paibanData.length == 0" class="w100">
                  <template slot="content">
                    <span :title="translateLang('materialPage.checkErr')">{{
                      $t('materialPage.checkErr')
                    }}</span>
                  </template>
                  <a-button
                    disabled
                    block
                    id="material_more_setting_check_paiban_btn_disable"
                    style="text-align: left"
                    :title="translateLang('materialPage.checkArranged')"
                  >
                    <span class="iconfont icon-yuliao mr10"></span>
                    <span>{{ $t('materialPage.checkArranged') }}</span>
                  </a-button>
                </a-popover>
                <a-button
                  @click="viewPaiban"
                  v-show="paibanData.length > 0"
                  block
                  id="material_more_setting_check_paiban_btn"
                  style="text-align: left"
                  :title="translateLang('materialPage.checkArranged')"
                >
                  <span class="iconfont icon-typesetting mr10"></span>
                  <span>{{ $t('materialPage.checkArranged') }}</span>
                </a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button
                  @click="() => downloadCsv()"
                  block
                  id="material_more_setting_download_excel_btn"
                  style="text-align: left"
                  :title="translateLang('materialPage.downloadSheet')"
                >
                  <span class="iconfont icon-download mr10"></span>
                  <span>{{ $t('materialPage.downloadSheet') }}</span>
                </a-button>
              </a-menu-item>
              <!-- 下载样表（展示科学计数法） -->
              <a-menu-item>
                <a-button
                  @click="() => downloadCsv(false)"
                  block
                  id="material_more_setting_download_excel_btn"
                  style="text-align: left"
                >
                  <span class="iconfont icon-download mr10"></span>
                  <span>{{
                    $t('materialPage.downloadSheetShowScientific')
                  }}</span>
                </a-button>
              </a-menu-item>
              <a-menu-item>
                <a-tooltip
                  placement="right"
                  overlayClassName="material-tooltip-card"
                >
                  <template slot="title">
                    <a-button
                      class="w100"
                      @click="exportHoleSlot(null)"
                      :disabled="dataFrom == 'ggpc' || isExportHoleSlotPdf"
                      :loading="isExportHoleSlotPdf"
                      id="material_export_hole_slot_pic_btn"
                      style="text-align: left"
                      :title="translateLang('materialPage.style1')"
                    >
                      <span class="ml2">{{ $t('materialPage.style1') }}</span>
                    </a-button>
                    <a-button
                      class="w100"
                      @click="handleExportThreeView"
                      id="material_export_three_view_btn"
                      style="text-align: left"
                      :title="translateLang('materialPage.style2')"
                    >
                      {{ $t('materialPage.style2') }}
                    </a-button>
                    <a-button
                      @click="exportHoleSlot('other')"
                      :disabled="dataFrom == 'ggpc' || isExportHoleSlotPdfOther"
                      :loading="isExportHoleSlotPdfOther"
                      id="material_export_hole_slot_pic_btn_other"
                      style="text-align: left"
                      :title="translateLang('materialPage.style3')"
                    >
                      {{ $t('materialPage.style3') }}
                    </a-button>
                  </template>
                  <a-button
                    class="w100"
                    :disabled="dataFrom == 'ggpc' || isExportHoleSlotPdf"
                    :loading="isExportHoleSlotPdf"
                    id="material_export_pdf_tooltip"
                    style="text-align: left"
                    :title="translateLang('materialPage.exportHoleSlotPDF')"
                  >
                    <a-icon
                      class="mr10"
                      type="file-pdf"
                      v-show="!isExportHoleSlotPdf"
                    />
                    <span class="ml2">{{
                      $t('materialPage.exportHoleSlotPDF')
                    }}</span>
                  </a-button>
                </a-tooltip>
              </a-menu-item>
              <a-menu-item>
                <a-button class="w100">
                  <div class="flex flex-main--left">
                    <a-checkbox
                      class="mr10"
                      v-model="isLoadMoreTable"
                      @change="savePreferences"
                      id="material_import_list_noncover_checkbox"
                      ><span
                        id="material_import_list_noncover_checkbox_label"
                        :title="translateLang('materialPage.importNoCover')"
                        >{{ $t('materialPage.importNoCover') }}</span
                      ></a-checkbox
                    >
                  </div>
                </a-button>
              </a-menu-item>
              <a-menu-item>
                <a-button class="w100">
                  <div class="flex flex-main--left">
                    <a-checkbox
                      class="mr10"
                      :checked="isDefaultFoldData"
                      @change="savePreferencesForDefaultFoldData"
                      id="material_list_unfold_btn"
                    >
                      <span
                        id="material_list_unfold_btn_label"
                        :title="translateLang('materialPage.defaultFold')"
                        >{{ $t('materialPage.defaultFold') }}</span
                      ></a-checkbox
                    >
                  </div>
                </a-button>
              </a-menu-item>
              <!-- 开启补件推荐 -->
              <a-menu-item>
                <new-content>
                  <a-button class="w100">
                    <div class="flex flex-main--left">
                      <a-checkbox
                        class="mr10"
                        v-model="preferencesSetting.isBujianRecommend"
                        @change="savePreferences"
                        id="material_open_bujian_plank_recommend_checkbox"
                      >
                      </a-checkbox>
                      {{ $t('materialPage.openBujianPlankRecommend') }}
                    </div>
                  </a-button>
                </new-content>
              </a-menu-item>
              <!-- 开启待排版小板推荐 -->
              <a-menu-item>
                <new-content>
                  <a-button class="w100">
                    <div class="flex flex-main--left">
                      <a-checkbox
                        class="mr10"
                        v-model="preferencesSetting.isPaibanStoreRecommend"
                        @change="savePreferences"
                        id="material_open_await_store_plank_recommend_checkbox"
                      ></a-checkbox>
                      {{ $t('materialPage.openAwaitStorePlankRecommend') }}
                    </div>
                  </a-button>
                </new-content>
              </a-menu-item>
              <!-- 开启余料大板推荐 -->
              <a-menu-item>
                <new-content>
                  <a-button class="w100">
                    <div class="flex flex-main--left">
                      <a-checkbox
                        class="mr10"
                        v-model="preferencesSetting.isSurplusRecommend"
                        @change="savePreferences"
                        id="material_open_surplus_plank_recommend_checkbox"
                      ></a-checkbox>
                      {{ $t('materialPage.openSurplusPlankRecommend') }}
                    </div>
                  </a-button>
                </new-content>
              </a-menu-item>
              <!-- 添加板件到待排版库 -->
              <a-menu-item>
                <new-content>
                  <a-button
                    class="w100"
                    @click="handleOpenPushStoreDialog"
                    id="storage_plank_to_await_store_btn"
                    block
                    style="text-align: left"
                  >
                    <a-icon type="cloud" class="mr10" />
                    <span class="ml2">{{
                      $t('materialPage.pushToAwaitStore')
                    }}</span>
                  </a-button>
                </new-content>
              </a-menu-item>
              <a-menu-item>
                <a-button
                  @click="handleSettingTableHeader"
                  id="material_set_tableHead_btn"
                  block
                  style="text-align: left"
                  :title="translateLang('materialPage.setTableHead')"
                >
                  <a-icon type="setting" class="mr10" />
                  <span class="ml2">{{ $t('materialPage.setTableHead') }}</span>
                </a-button>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>

        <div class="top-right-operation">
          <div class="plank-range flex flex-cross--center mr10">
            <span class="label">{{ $t('materialPage.plankSize') }}:</span>
            <div class="range-input flex flex-cross--center">
              <a-input
                v-model="minSize"
                style="width: 80px"
                @change="handleChangeSize"
                id="material_plank_size_min_input"
              ></a-input>
              <span class="m5">-</span>
              <a-input
                v-model="maxSize"
                style="width: 80px"
                @change="handleChangeSize"
                id="material_plank_size_max_input"
              ></a-input>
            </div>
          </div>
          <div>
            <a-input-search
              v-model="plankKeyWord"
              :placeholder="$t('materialPage.searchPlaceholder')"
              @search="() => searchPlank()"
              id="material_search_input"
            >
              <a-button slot="enterButton" id="material_search_btn">
                <span class="iconfont icon-search"></span>
              </a-button>
            </a-input-search>
          </div>

          <div class="toggle-box">
            <span
              :class="[
                'toggle-icon iconfont icon-list',
                toggleListTile ? 'active' : '',
              ]"
              @click="changeListTile(true)"
              id="material_change_to_list_btn"
            ></span>
            <span
              :class="[
                'toggle-icon iconfont icon-picture',
                toggleListTile ? '' : 'active',
              ]"
              @click="changeListTile(false)"
              id="material_change_to_pic_btn"
            ></span>
          </div>
        </div>
      </div>
      <!-- 补件列表 -->
      <MBujianTable
        v-if="preferencesSetting.isBujianRecommend"
        ref="bujianTableRef"
        :material-parts="alreadySelectParts"
        :isDefaultFoldData="isDefaultFoldData"
        class="m-bujian-table"
      ></MBujianTable>
      <!-- 待排版库列表 -->
      <m-await-paiban-store
        v-if="preferencesSetting.isPaibanStoreRecommend"
        :material-parts="alreadySelectParts"
        :isDefaultFoldData="isDefaultFoldData"
        ref="awaitStoreRef"
      >
      </m-await-paiban-store>
      <!-- 余料列表 -->
      <div class="surplus-table" v-if="preferencesSetting.isSurplusRecommend">
        <div class="surplus-info common-height">
          <div class="surplus-select">
            <a-checkbox
              v-model="isSelectedAllSurplus"
              @change="selectAllSurplus()"
              id="material_all_choose_surplus_list_checkbox"
            ></a-checkbox>
          </div>
          <div
            class="surplus-title ml20"
            @click="() => onChangeIsShowSurplusList()"
          >
            <a-icon v-if="isShowSurplusList" type="up" />
            <a-icon v-else type="down" />
            <span class="bold color-0 ml0">
              <!-- 可用余料共  种，已选  种 -->
              <span
                v-html="
                  $t('materialPage.chooseSurplusQuantity', {
                    total: `<i style='color:#18a8c7'>${surplusNum}</i>`,
                    quantity: `<i style='color:#18a8c7'>${selectedSurplusNum}</i>`,
                  })
                "
              >
              </span>
            </span>
          </div>
        </div>
        <div
          class="surplus-headers common-height"
          v-show="isShowSurplusList && surplusNum > 0"
        >
          <div
            style="display: inline-block; width: 3%"
            :title="translateLang('common.select')"
          >
            {{ $t('common.select') }}
          </div>
          <span
            v-for="(item, index) in surplusHeaders"
            :key="index"
            class="ellipsis"
            :style="{
              width: `${item.width}%`,
              textAlign: 'center',
              verticalAlign: 'bottom',
            }"
            :title="translateLang(item.label)"
            >{{ translateLang(item.label) }}{{ item.unit ?? '' }}</span
          >
        </div>
        <div
          class="surplus-list"
          v-show="isShowSurplusList && surplusNum > 0"
          ref="surplusList"
          @scroll="loadMoreSurplus"
        >
          <div
            v-for="(surplus, index) in surplusList"
            :key="index"
            class="surplus-row"
          >
            <div style="width: 3%">
              <a-checkbox
                v-model="surplus.isSelected"
                @change="judgeIsSelectAll()"
                :id="`material_surplus_list_${index}_choose`"
              ></a-checkbox>
            </div>
            <div
              v-for="(header, indexs) in surplusHeaders"
              :key="indexs"
              :style="`width: ${header.width}%`"
              :ref="`surplus_${surplus.id}_${header.props}`"
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
              "
            >
              <span v-if="header.props == 'shape'">{{
                surplus[header.props] == '1'
                  ? $t('common.Ltype')
                  : $t('common.rect')
              }}</span>
              <span v-else-if="header.props === 'type'">
                {{ surplus[header.props]?.replace('高光_', '') }}
              </span>

              <div v-else-if="header.props == 'showAmount'">
                <a-input-number
                  ref="surplusInput"
                  v-model="surplus[header.props]"
                  :min="1"
                  :max="Number(surplus.amount)"
                  @blur="checkSurplusNum(index, surplus)"
                  :formatter="(value) => surplusAmountLimit(value, surplus)"
                  id="material_surplus_list_number_input"
                ></a-input-number>
              </div>
              <el-select
                v-else-if="header.props === 'is_high_gloss_plank'"
                v-model="surplus.is_high_gloss_plank"
                :id="`supplus_storage_table_${index}_${header.props}_select`"
                size="mini"
                :disabled="true"
              >
                <el-option
                  v-for="(item, i) in highPlankOptions"
                  :key="item.value"
                  :label="$t(item.label)"
                  :value="item.value"
                  :id="`supplus_storage_table_${index}_${header.props}_select_${i}`"
                >
                </el-option>
              </el-select>
              <a-tooltip
                v-else
                placement="bottomLeft"
                @visibleChange="
                  (value) => showTooltip(value, surplus, header.props)
                "
                :visible="surplus[`show${header.props}`]"
              >
                <template #title>{{ surplus[header.props] }}</template>
                <span class="center">{{ surplus[header.props] }}</span>
              </a-tooltip>
            </div>
          </div>
        </div>

        <div v-show="isShowSurplusList && surplusNum == 0" class="no-surplus">
          <img :src="require('../../assets/image/noMatchSurplus.png')" alt="" />
          <span>
            <span :title="translateLang('materialPage.surplusTip')">{{
              $t('materialPage.surplusTip')
            }}</span>
            <span
              class="new-surplus"
              @click="addNewSurplus"
              :title="translateLang('materialPage.creatSurplus')"
              >{{ $t('materialPage.creatSurplus') }}</span
            ></span
          >
        </div>
        <div
          v-show="!isShowSurplusList || surplusNum == 0"
          class="surplus-blank"
        ></div>
      </div>
      <div class="main-table" v-if="toggleListTile">
        <div
          class="order-info common-height"
          :style="{ width: virtualHeadRowWidth + 'px', minWidth: '100%' }"
        >
          <a-checkbox
            @change="selectAllTable"
            v-model="isSelectedAllPlank"
            id="material_all_choose_material_list_checkbox"
          ></a-checkbox>
          <div class="order-title-box">
            <a-tooltip placement="top" v-show="!isShowSample">
              <template slot="title">
                <span class="bold">{{
                  plankList.length == 0
                    ? $t('materialPage.orderInfo')
                    : orderInfo.order_address
                }}</span>
              </template>
              <span class="order-info-name bold">{{
                plankList.length == 0
                  ? $t('materialPage.orderInfo')
                  : orderInfo.order_address
              }}</span>
            </a-tooltip>
          </div>
          <!-- 样表 -->
          <BaseFrom
            v-show="isShowSample"
            v-bind="samPleConfig"
            :form-data.sync="samPleFormData"
            class="sampleTable"
          />
        </div>
        <!-- 料单 -->
        <virtualList
          ref="virtualListRef"
          class="virtualList"
          :table-data="isTaskLoading ? [] : materialBaseData"
          :filter-area="filterArea"
          :filter-amount="filterAmount"
          :isempty="isTableEmpty"
          :tableHeadProp="currentHeadPropList"
          :no-ellipsis="['type']"
          :filterKeys="Object.keys(filterObj)"
          :hasFilter="true"
          :extraLongPlatesUniqueID="extraLongPlatesUniqueID"
          @inputBlur="materialInputBlur"
          @updateBlur="updateBlur"
          @cellClick="materialCellClick"
          @changeSelect="changeSelect"
          @changeSelectAll="changeSelectAll"
          @headRowChange="headRowChange"
          @onPlankFilter="handlePlankFilter"
        >
          <template #is_high_gloss_plank="scope">
            <el-select
              v-model="scope.part['is_high_gloss_plank']"
              @focus="focusPlankTexDir(scope.part)"
              @change="
                changePlankHigh(scope.part['is_high_gloss_plank'], scope.part)
              "
              :id="`material_virtual_list${scope.index}_highPlank_select`"
            >
              <el-option
                v-for="item in highPlankOptions"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
                :id="`material_virtual_list_${scope.index}_highPlank_option_${item.value}`"
              >
              </el-option>
            </el-select>
          </template>
          <template #texDir="scope">
            <el-select
              v-model="scope.part['texDir']"
              @focus="focusPlankTexDir(scope.part)"
              @change="
                changePlankTexDir(scope.part['texDir'], scope.part, true)
              "
              :id="`material_virtual_list${scope.index}_texture_select`"
            >
              <el-option
                v-for="item in [
                  { value: 'reverse', label: $t('common.reverse') },
                  { value: 'normal', label: $t('common.normal') },
                  { value: 'notcare', label: $t('common.notCare') },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :id="`material_virtual_list_${scope.index}_texture_option_${item.value}`"
              >
              </el-option>
            </el-select>
          </template>
          <!-- 是否是异形 -->
          <template #specialShape="scope">
            {{ checkIsSpecial(scope.part) }}
          </template>
          <!-- 孔槽信息 -->
          <template #hsInfo="scope">
            <div
              class="hsinfo"
              style="color: #18a8c7"
              @click="showHSInfo(scope.part)"
            >
              {{ scope.part['hsInfo'] }}
            </div>
          </template>
          <!-- 复制行 -->
          <template #after="scope">
            <GCopyLine
              class="copy-line"
              type="upDown"
              :index="materialBaseData.length"
              @copyClick="copyLine(scope)"
            />
          </template>
          <!-- 门板 -->
          <template #type="scope">
            <aSelect
              :class="[scope.part['type'] === 'Plank' ? 'grey' : 'common']"
              v-model="scope.part['type']"
              dropdownMatchSelectWidth
              @change="changePlankType($event, scope.part)"
              id="material_choose_door_plank_select"
            >
              <a-select-option
                v-for="item in [
                  { value: 'Plank', label: $t('common.notDoorPlank') },
                  { value: 'SingleDoor', label: $t('common.isDoorPlank') },
                ]"
                :key="item.value"
                :value="item.value"
                :id="`material_choose_door_plank_${item.value}_select`"
              >
                <span class="plank-type-option">
                  {{ item.label }}
                </span>
              </a-select-option>
            </aSelect>
          </template>
          <template #headType>
            <newContent :is-new-content="false">
              <div class="flex plank-door-new-content">
                <div
                  class="ellipsis"
                  :class="{
                    'is-filter': Object.keys(filterObj).includes('type'),
                  }"
                  :title="translateLang('common.isDoorPlank')"
                >
                  {{ $t('common.isDoorPlank') }}
                </div>
                <i
                  :class="[
                    'iconfont',
                    'filter-icon',
                    Object.keys(filterObj).includes('type')
                      ? 'icon-shaixuan'
                      : 'icon-shaixuan1',
                  ]"
                  style="color: #18a8c7"
                  id="material_table_plank_type_filter_button"
                  @click="
                    handlePlankFilter(
                      currentHeadPropList.find((item) => item.prop === 'type')
                    )
                  "
                ></i>
              </div>
            </newContent>
          </template>
        </virtualList>
      </div>
      <el-empty :description="$t('common.dataLoading')" v-if="emptyShow">
        <i class="el-icon-loading fs20" style="color: #67c23a"></i>
      </el-empty>

      <div class="main-table" v-show="!toggleListTile">
        <div v-for="(item, index) in filterPlankList" :key="index">
          <div class="plank-list-table-info">
            <div class="plank-describe" @click="handleChangeFold(item)">
              <a-checkbox
                v-model="item.isAllSelect"
                @change="selectTableAllPlank(item)"
              ></a-checkbox>
              <span
                :class="[
                  'fold-icon',
                  'iconfont',
                  item.isUnfold ? 'icon-arrow' : 'icon-zhankai',
                  'ml20',
                  'mr5',
                ]"
              ></span>
              <span
                >{{ item.thick }}mm{{ item.matCode }}（{{ item.texture }}） -
                [{{ filterAmount(item) }}块, {{ filterArea(item) }}m²]</span
              >
              <span v-show="item.high_plank" class="high-plank bold fs12"
                >高光
              </span>
            </div>
          </div>
          <div class="draw-part" v-show="item.isUnfold">
            <div v-for="(items, indexs) in item.parts" :key="indexs">
              <drawItem
                :plankInfo="items"
                :plankIndex="indexs + 1"
                :arrIndex="index"
              ></drawItem>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="showDrawer"
      direction="rtl"
      :size="500"
      :show-close="false"
      :close-on-press-escape="false"
      @close="clearNewPlankInfo"
    >
      <div slot="title" class="drawer-title">
        <span :title="translateLang('materialPage.add.title')">{{
          $t('materialPage.add.title')
        }}</span>

        <span
          class="iconfont icon-close"
          @click="closeDrawer"
          id="material_close_add_plank_btn"
        ></span>
      </div>

      <div class="drawer-main">
        <div class="content">
          <div class="new-plank-form">
            <div class="new-plank-form-item">
              <span
                class="plank-form-title"
                :title="translateLang('common.plankNo')"
                >{{ $t('common.plankNo') }}：</span
              >

              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.plankID"
                  :disabled="true"
                  id="material_plank_number_input_disabled"
                ></a-input>
              </div>
            </div>

            <div class="new-plank-form-item">
              <span
                class="plank-form-title"
                :title="translateLang('common.plankName')"
                >{{ $t('common.plankName') }}：</span
              >

              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.partName"
                  :maxLength="10"
                  id="material_plank_name_input"
                >
                  <span class="plank-form-limit" slot="suffix"
                    >{{ newPlankInfo.partName.length }}/10</span
                  >
                </a-input>
              </div>
            </div>

            <div class="new-plank-form-item">
              <span
                class="plank-form-title"
                :title="translateLang('common.matCode')"
                ><span class="important-icon">*</span
                >{{ $t('common.matCode') }}：</span
              >
              <!-- <div class="plank-form-input">
              <a-input
                v-model="newPlankInfo.matCode"
                :maxLength="10"
                @input="dealTrim('matCode')"
                id="material_plank_shader_input"
              >
                <span class="plank-form-limit" slot="suffix"
                  >{{ newPlankInfo.matCode.length }}/10</span
                >
              </a-input>
            </div> -->
              <div class="w100">
                <el-select
                  v-model="newPlankInfo.matCode"
                  filterable
                  clearable
                  @input="dealTrim('matCode')"
                  @keydown.native.enter="handleEnterMatCode"
                  @change="handleSelectMatCode"
                  size="small"
                  :placeholder="$t('materialPage.enterAutoCreateMatCode')"
                  id="material_plank_shader_input"
                  class="w100"
                >
                  <el-option
                    v-for="(item, index) in matcodeList"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                    :id="`material_create_plank_matcode_option_${index}`"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 高光板 -->
            <div class="new-plank-form-item">
              <span class="plank-form-title"
                ><span class="important-icon">*</span>
                <!-- 高光板 -->
                {{ $t('common.heighGlossPlank') }}：
              </span>
              <div class="plank-form-high-plank-select plank-form-select">
                <el-select
                  v-model="newPlankInfo.is_high_gloss_plank"
                  id="`material_highPlank_select"
                  size="small"
                  :placeholder="$t('common.pleaseChoose')"
                >
                  <el-option
                    v-for="item in highPlankOptions"
                    :key="item.value"
                    :label="$t(item.label)"
                    :value="item.value"
                    :id="`material_highPlank_option_${item.value}`"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="new-plank-form-item">
              <span class="plank-form-title"
                ><span class="important-icon">*</span
                >{{ $t('common.color') }}：</span
              >
              <div class="plank-form-input" v-loading="isLoadingColors">
                <el-cascader
                  id="material_color_input"
                  size="small"
                  v-model="newPlankInfoTexture"
                  :options="materialColorOptions"
                  @change="handleSelectTexture"
                  @keydown.enter.native="handleEnterColor"
                  @keyup.delete.native="handleDeleteColorSearch"
                  ref="addColorCascaderRef"
                  :before-filter="onBeforeFilter"
                  :show-all-levels="false"
                  filterable
                  clearable
                  :placeholder="$t('common.colorPlaceHolder')"
                />
              </div>
            </div>

            <div class="new-plank-form-item">
              <span class="plank-form-title"
                ><span class="important-icon">*</span
                >{{ $t('common.thick') }}：</span
              >
              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.thick"
                  @input="inputCheck('thick', true)"
                  id="material_thickness_input"
                >
                  <span slot="addonAfter" class="plank-form-after">mm</span>
                </a-input>
              </div>
            </div>

            <div class="new-plank-form-item">
              <span class="plank-form-title"
                ><span class="important-icon">*</span
                >{{ $t('common.finishedHeight') }}：</span
              >
              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.specHeight"
                  @input="inputCheck('specHeight', true)"
                  id="material_finished_length_input"
                >
                  <span slot="addonAfter" class="plank-form-after">mm</span>
                </a-input>
              </div>
            </div>

            <div class="new-plank-form-item">
              <span class="plank-form-title"
                ><span class="important-icon">*</span
                >{{ $t('common.finishedWidth') }}：</span
              >
              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.specWidth"
                  @input="inputCheck('specWidth', true)"
                  id="material_finished_width_input"
                >
                  <span slot="addonAfter" class="plank-form-after">mm</span>
                </a-input>
              </div>
            </div>

            <div class="new-plank-form-item">
              <span class="plank-form-title"
                ><span class="important-icon">*</span
                >{{ $t('common.count') }}：</span
              >
              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.amount"
                  @input="inputCheck('amount', false)"
                  id="material_plank_number_input"
                ></a-input>
              </div>
            </div>

            <div class="new-plank-form-item">
              <span class="plank-form-title">{{ $t('common.texture') }}：</span>

              <div class="plank-form-input plank-form-select">
                <a-select
                  v-model="newPlankInfo.texDir"
                  :options="texDirOptions"
                  dropdownClassName="plank-form-select"
                  id="material_choose_texture_select"
                >
                </a-select>
              </div>
            </div>

            <div class="new-plank-form-item has-extra-info">
              <span class="plank-form-title"
                >{{ $t('common.edge.front') }}：</span
              >

              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.frontEdge"
                  @input="inputCheck('frontEdge', true)"
                  id="material_front_edge_banding_input"
                ></a-input>
              </div>
            </div>

            <div class="new-plank-form-item has-extra-info">
              <span class="plank-form-title"
                >{{ $t('common.edge.back') }}：</span
              >

              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.backEdge"
                  @input="inputCheck('backEdge', true)"
                  id="material_behind_edge_banding_input"
                ></a-input>
              </div>
            </div>

            <div class="new-plank-form-item has-extra-info">
              <span class="plank-form-title"
                >{{ $t('common.edge.left') }}：</span
              >

              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.leftEdge"
                  @input="inputCheck('leftEdge', true)"
                  id="material_left_edge_banding_input"
                ></a-input>
              </div>
            </div>

            <div class="new-plank-form-item has-extra-info">
              <span class="plank-form-title"
                >{{ $t('common.edge.right') }}：</span
              >

              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.rightEdge"
                  @input="inputCheck('rightEdge', true)"
                  id="material_right_edge_banding_input"
                ></a-input>
              </div>
            </div>
            <div
              class="new-plank-form-item has-extra-info"
              v-if="
                currentHeadPropList &&
                currentHeadPropList.find((e) => e.prop == 'plank_remarks')
              "
            >
              <span class="plank-form-title"
                >{{ $t('common.plankRemark') }}：</span
              >
              <div class="plank-form-input">
                <a-input
                  v-model="newPlankInfo.plank_remarks"
                  id="material_plank_remarks_banding_input"
                ></a-input>
              </div>
            </div>
          </div>
        </div>

        <div class="drawer-bottom">
          <span
            class="drawer-bottom-btn"
            @click="closeDrawer"
            id="cancel_add_plank_btn"
            >{{ $t('common.cancel') }}</span
          >
          <span
            class="drawer-bottom-btn"
            @click="addNewRowContinue"
            id="sure_and_continue_add_plank_btn"
            >{{ $t('common.saveAndContinue') }}</span
          >
          <span
            class="drawer-bottom-btn confirm-btn"
            @click="addNewRow(false)"
            id="sure_add_plank_btn"
            >{{ $t('common.confirm') }}</span
          >
        </div>
      </div>
    </el-drawer>
    <div
      class="addBaseMModal"
      v-if="showAddBaseMModal"
      @click="hideAddBaseMModal"
      id="material_add_base_plank_modal"
    >
      <div class="content" @click.stop>
        <div class="head flex flex-main--justify">
          <div class="flex flex-cross--center">
            <span class="iconfont icon-warn"></span>
            <span class="fs20 bold ml5">{{ $t('common.warmTip') }}</span>
          </div>

          <span @click="hideAddBaseMModal" id="material_oversize_hide_modal_btn"
            ><i class="iconfont icon-close"></i
          ></span>
        </div>

        <div class="detailInfo">
          <p class="info-space">
            {{ $t('materialPage.overSize.warning') }}
          </p>
          <p
            class="info-detail cursor-pointer"
            @click="handleViewBordDetail"
            id="material_oversize_detail_check_btn"
          >
            {{ $t('materialPage.overSize.checkInfo') }}
          </p>
        </div>

        <div class="btn flex flex-main--right">
          <a-button
            @click="hideAddBaseMModal"
            id="material_add_base_plank_cancel_btn"
            >{{ $t('common.cancel') }}</a-button
          >

          <a-button
            @click="toBaseMaterial"
            class="add-btn"
            id="material_add_base_plank_add_btn"
            >{{ $t('materialPage.overSize.addPlank') }}</a-button
          >
        </div>
      </div>
    </div>

    <!-- 查看板件详情弹窗 -->
    <div class="plate-details bg-white fixed r16" v-if="isShowPlateDetails">
      <div class="title flex flex-main--justify">
        <div class="flex flex-cross--center">
          <!-- <span class="iconfont icon-warn"></span> -->
          <img :src="require('../../assets/image/showWarning.png')" alt="" />
          <span class="fs20 bold ml5">{{
            $t('materialPage.overSize.nonWorkInfo')
          }}</span>
        </div>
        <span
          @click="isShowPlateDetails = false"
          id="material_oversize_close_detail_btn"
          ><i class="iconfont icon-close"></i
        ></span>
      </div>

      <div
        class="content flex flex-cross--center mt20 relative"
        @mouseover="isShowAllText = true"
        @mouseout="isShowAllText = false"
      >
        <div class="absolute over-span" v-if="isShowAllText">
          {{ extraLongPlatesName }}
        </div>
        <span class="fs14">{{ $t('common.plankName') }}：</span>
        <div class="input-box flex flex-main--justify">
          <div class="show-detail ml5">{{ extraLongPlatesName }}</div>
          <span class="mr20"
            >{{ extraLongPlatesIndex }}/{{ extraLongPlates.length }}</span
          >
        </div>
      </div>
      <div class="footer flex flex-main--justify mt20">
        <a-button @click="handleGoBack" id="material_oversize_back_btn">{{
          $t('common.return')
        }}</a-button>
        <a-button
          @click="handleOperate('last')"
          id="material_oversize_presious_btn"
          >{{ $t('common.previous') }}</a-button
        >
        <a-button
          class="next-btn"
          @click="handleOperate('next')"
          id="material_oversize_next_btn"
          >{{ $t('common.next') }}</a-button
        >
      </div>
    </div>
    <!-- 超尺板件自动添加弹窗 -->
    <div
      class="auto-add-big-plate t0 l0 w100 h100"
      v-if="showAddSpecialPlate"
      id="material_auto_add_big_plate_modal"
    >
      <div class="content relative r0 l0 bg-white br4 pb15" @click.stop>
        <div class="head flex flex-main--justify flex-cross--center">
          <div>
            <span class="fs18 bold mr20 color-0">{{
              $t('materialPage.overSize.title')
            }}</span>
            <span
              class="detail cursor-pointer"
              @click="handleGoDetail"
              id="material_oversize_list_detail"
              >{{ $t('materialPage.overSize.subTitle') }}</span
            >
          </div>
          <i
            class="iconfont icon-close cursor-pointer"
            style="font-size: 14px"
            @click="handleCloseBigPlate"
          ></i>
        </div>
        <div class="tip">
          <span class="bold color-0"
            >{{ $t('materialPage.overSize.overSizeTip') }}：</span
          ><span v-html="$t('materialPage.overSize.tipContent')"></span>
        </div>
        <!-- 表格复制的云排版已有表格 -->
        <el-table
          border
          max-height="375"
          :header-cell-style="{ backgroundColor: '#f5f5f5' }"
          :data="bigPlateList"
        >
          <el-table-column
            prop="matCode"
            :label="$t('common.plankMatCode')"
            align="center"
            width="180"
          >
            <template #default="{ row }">
              <el-tooltip
                v-if="row.matCode.length > 10"
                effect="dark"
                :content="row.matCode"
                placement="bottom"
              >
                <span>{{ row.matCode.slice(0, 10) + '...' }}</span>
              </el-tooltip>
              <span v-else>{{ row.matCode }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="thick"
            :label="$t('common.plankThick')"
            align="center"
          >
            <template #default="{ row }">
              <el-tooltip
                v-if="row.thick.length > 10"
                effect="dark"
                :content="row.thick"
                placement="bottom"
              >
                <span>{{ row.thick.slice(0, 10) + '...' }}</span>
              </el-tooltip>
              <span v-else>{{ row.thick }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="color"
            :label="$t('common.plankColor')"
            align="center"
          >
            <template #default="{ row }">
              <el-tooltip
                v-if="row.color.length > 10"
                effect="dark"
                :content="row.color"
                placement="bottom"
              >
                <span>{{ row.color.slice(0, 10) + '...' }}</span>
              </el-tooltip>
              <span v-else>{{ row.color }}</span>
            </template>
          </el-table-column>
          <!-- 高光板 -->
          <el-table-column
            prop="highPlank"
            :label="$t('common.heighGlossPlank')"
            align="center"
          >
            <template #default="{ row }">
              <span>{{
                row.is_high_gloss_plank
                  ? $t('common.heighGlossPlank')
                  : $t('common.notHeighGlossPlank')
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="height"
            :label="`${$t('common.plankHeight')}mm`"
            align="center"
          >
            <template #default="{ row, $index }">
              <el-input
                :ref="`height${$index}`"
                v-model="row.height"
                class="custom-style"
                @input="
                  (event) => handleBigPlateInput(event, row, 'height', $index)
                "
              >
                <i
                  slot="prefix"
                  class="iconfont icon-warning"
                  v-if="row.showHInputIcon"
                  style="color: red; line-height: 40px"
                  title="板件尺寸可能有误"
                ></i>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="width"
            :label="`${$t('common.plankWidth')}mm`"
            align="center"
          >
            <template #default="{ row, $index }">
              <el-input
                :ref="`width${$index}`"
                v-model="row.width"
                class="custom-style"
                @input="
                  (event) => handleBigPlateWInput(event, row, 'width', $index)
                "
              >
                <i
                  slot="prefix"
                  class="iconfont icon-warning"
                  v-if="row.showWInputIcon"
                  style="color: red; line-height: 40px"
                  title="板件尺寸可能有误"
                ></i>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')" align="center">
            <template #default="{ $index }">
              <div>
                <el-button type="text" @click="handleDelBigPlate($index)"
                  >{{ $t('common.delete') }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex flex-main--right">
          <a-button
            class="btn mt15 mr10 cursor-pointer"
            @click="handleAddBigPlate"
            type="primary"
            :loading="isAddBigPlateLoading"
          >
            <i
              class="iconfont icon-add color-f mr8"
              style="font-size: 12px; font-weight: bold"
            ></i>
            <span class="color-f fs14">{{
              $t('materialPage.overSize.add')
            }}</span>
          </a-button>
        </div>
      </div>
    </div>

    <!-- 超尺板件清单 -->
    <div
      class="big-plate-detail t0 l0 w100 h100"
      id="material_auto_add_big_plate_detail_modal"
      v-if="showBigPlateDetail"
    >
      <div class="content relative r0 l0 bg-white br4 pb15">
        <div class="head flex flex-main--justify">
          <div>
            <i
              class="iconfont icon-left mr5 cursor-pointer"
              style="font-size: 14px"
              @click="handleCloseDetail"
            ></i>
            <span class="bold">{{ $t('materialPage.overSize.subTitle') }}</span>
          </div>
          <i
            class="iconfont icon-close cursor-pointer"
            style="font-size: 14px"
            @click="handleCloseDetail"
          ></i>
        </div>
        <el-table
          border
          max-height="550"
          :header-cell-style="{ backgroundColor: '#f5f5f5' }"
          :data="bigPlateDetailList"
        >
          <el-table-column
            v-for="item in bigPlateDetailColumns"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <el-tooltip
                v-if="
                  (item.prop === 'blankHeight' || item.prop === 'blankWidth'
                    ? row[item.prop] && row[item.prop].length > 5
                    : row[item.prop] && row[item.prop].length > 10) &&
                  item.prop !== 'plankNum'
                "
                effect="dark"
                :content="row[item.prop]"
                placement="bottom"
              >
                <span>{{
                  item.prop === 'blankHeight' || item.prop === 'blankWidth'
                    ? row[item.prop].slice(0, 5) + '...'
                    : row[item.prop].slice(0, 10) + '...'
                }}</span>
              </el-tooltip>
              <span v-else>{{ row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 未排小板清单 -->
    <MNotPaibanDetail
      :show-part-detail="showPartDetail"
      :paibanMessage="paibanMessage"
      :filterPlankList="filterPlankList"
      @onClosePartDetail="handleClosePartDetail"
    />
    <g-base-modal
      :visible="showMsgDialog"
      v-if="showMsgDialog"
      @ok="deleteRow"
      @cancel="showMsgDialog = false"
      :title="$t('common.sysTip')"
      :ok-text="$t('common.confirm')"
      :cancel-text="$t('common.cancel')"
      :contain="$t('materialPage.delete.tip')"
      preffix="material_delete_row"
    >
    </g-base-modal>
    <!-- 板件数据错误 -->
    <g-base-modal
      :visible="plankErrVisible"
      v-if="plankErrVisible"
      :title="$t('common.sysTip')"
      :ok-text="$t('common.confirm')"
      :cancel-text="$t('common.cancel')"
      :contain="$t('materialPage.arrangeErr')"
      @ok="plankErrVisible = false"
      @cancel="plankErrVisible = false"
      preffix="material_plank_err"
    >
    </g-base-modal>
    <!-- 同步柜柜原片原片数据弹窗 -->
    <g-base-modal
      :visible="showSyncGuiguiBaseMModal"
      v-if="showSyncGuiguiBaseMModal"
      :title="$t('common.warmTip')"
      :cancel-text="$t('common.cancel')"
      :ok-text="$t('common.sync')"
      :contain="$t('materialPage.syncConfirm')"
      @ok="syncGuiguiBaseM(true)"
      @cancel="syncGuiguiBaseM(false)"
      preffix="sync_guigui_base_m"
    >
      <div class="prompt flex flex-dir--top flex-main--center">
        <p class="prompt-info fs12 mb4" v-if="isCheckBigPlankSynchronous">
          {{ $t('materialPage.handleSync') }}
        </p>
        <div>
          <label>
            <a-checkbox v-model="isCheckBigPlankSynchronous"></a-checkbox
            ><span class="ml6">{{ $t('materialPage.noMoreTip') }}</span>
          </label>
        </div>
      </div>
    </g-base-modal>
    <!-- 保存至历史弹窗 -->
    <g-base-modal
      :visible="showSaveDialog"
      v-if="showSaveDialog"
      :isLoading="isSavePaiBanLoading"
      :showIcon="false"
      :ok-text="$t('common.save')"
      :title="$t('materialPage.save.title')"
      :subtitle="`${$t('materialPage.save.subTitle')}`"
      @ok="savePaiban"
      @cancel="showSaveDialog = false"
      preffix="material_save"
    >
      <div class="flex flex-main--center flex-cross--center">
        <span class="flex-box--1">{{ $t('materialPage.save.name') }}</span>
        <div class="flex-box--3">
          <a-input
            v-model="paibanWayName"
            id="material_save_history_input"
          ></a-input>
        </div>
      </div>
    </g-base-modal>
    <g-table-dialog
      @confirm="handleClearMissingData"
      @cancel="handleClearMissingData"
      :lackTableString="lackTableString"
      :necessaryTableLackString="necessaryTableLackString"
      :isShowLackNeedTableHeader="isShowLackNeedTableHeader"
      :isShowLackNeedTableHeaderData="isShowLackNeedTableHeaderData"
      v-if="showTableDialog"
    ></g-table-dialog>

    <holesSlotsPopUp
      v-if="HS_show"
      :plank="nowPlankDetail"
      @hide="hideHSPopUp"
    ></holesSlotsPopUp>

    <div v-if="false">
      <a-modal
        v-model="visible"
        :title="$t('common.wellcome')"
        @ok="handleOk"
        centered
        :footer="null"
        :maskClosable="false"
        dialogClass="custom-model"
      >
        <article>
          <aside>
            <div
              class="import"
              @click="openLocalFile"
              id="material_import_file_btn"
            ></div>
            <div
              class="add"
              @click="openDrawer"
              id="material_add_part_btn"
            ></div>
          </aside>

          <main>
            <section class="header">
              <span>{{ $t('main.sideBar.history') }}</span>

              <router-link to="/paibanHistory" @click="visible = false">
                <span id="material_more_recent_history_btn">{{
                  $t('materialPage.checkMore')
                }}</span>

                <i class="iconfont icon-right"></i>
              </router-link>
            </section>

            <section class="body">
              <ul class="list" v-if="recentHistory.length">
                <li
                  class="item"
                  v-for="(el, idx) in recentHistory"
                  :key="el.id"
                  @click="toDetail(el)"
                  :id="`material_recent_history_list_${idx}`"
                >
                  <div class="info">
                    <span class="data" v-if="el.plank_info.used_rate"
                      >{{ (el.plank_info.used_rate * 100).toFixed(2) }}%</span
                    >
                    <span class="title">{{
                      dealRecentHistoryName(el.name)
                    }}</span>
                  </div>

                  <div class="time">
                    <span>{{ el.create_time }}</span>
                  </div>
                </li>
              </ul>

              <div class="no-data" v-else>
                <a-empty
                  :image="require('@/assets/icon/empty.png')"
                  :description="$t('materialPage.noHistory')"
                />
              </div>
            </section>
          </main>
        </article>
      </a-modal>
    </div>

    <a-modal
      v-model="isPaibanErr"
      :title="$t('common.tip')"
      @ok="handleCancel"
      centered
      :maskClosable="false"
    >
      <div class="flex flex-cross--center">
        <span style="font-size: 30px; color: #eb8353" class="mr10"
          ><a-icon type="question-circle" theme="filled"
        /></span>
        <span class="fs16">{{ $t('common.timeOut') }}</span>
      </div>

      <template #footer>
        <div class="flex flex-cross--center flex-main--justify">
          <div></div>
          <div>
            <a-button
              key="back"
              @click="handleCancel"
              id="material_cancel_btn"
              >{{ $t('common.cancel') }}</a-button
            >
            <a-button
              key="submit"
              type="primary"
              @click="dealLayoutData(false)"
              id="material_repayload_btn"
              >{{ $t('arrangedPage.reArrange') }}</a-button
            >
          </div>
        </div>
      </template>
    </a-modal>

    <paibanSetting
      ref="paibanSettingRef"
      :pro-line-list="proLineList"
      :showProLineDialog="showProLineDialog"
      :filterPlankList="filterPlankList"
      :paibanMessage="paibanMessage"
      @onConfirm="dealLayoutData"
      @onCancel="handleCancel"
      @onSendToFactory="handleSendToFactory"
      @onConfirmSend="handleConfirmSend"
      @onFetchProLineList="setProLineList"
      @onSelectProLine="handleSelectProLine"
      @onSelectLine="getSelectLine"
      :isConfirmLoading="isConfirmLoading"
      @onSelectStandard="handleSelectStandard"
      @onShowPartDetail="handleShowPartDetail"
    ></paibanSetting>
    <g-task-loading v-if="isShowTask" :task-list="taskList"></g-task-loading>

    <plankFilter
      :filter-props="filterProps"
      :filter-visibel="filterVisibel"
      :filterValues="filterObj[filterProp] ?? filterProps"
      :searchVal="filterSearchVal"
      @onAfterClose="onAfterFilterClose"
      @onCancel="handleCancelFilter"
      @onConfirmFilter="handleConfirmFilter"
      @onRestFilter="handleResetFilter"
    >
    </plankFilter>

    <plankEditer
      :editer-visibel="editerVisibel"
      @onCancel="handleCancelEditer"
      @onConfirm="handleConfirmEditer"
    >
    </plankEditer>
    <!-- 试生产模态框 -->
    <MTryProductionDialog
      ref="trialRef"
      @onConfirm="handleTrialProductConfirm"
    />
    <customize-table-header
      :table-headers="currentHeadPropList"
      ref="tableHeaders"
      @onRestFilter="handleConfirmCustom"
    ></customize-table-header>
    <m-incorrect-format
      @confirm="handleclearMIncorrectFormatData"
      @cancel="handleclearMIncorrectFormatData"
      :necessaryTableIncorrectFormat="necessaryTableIncorrectFormat"
      v-if="showMIncorrectFormat"
    >
    </m-incorrect-format>
    <g-warning-modal
      :visible.sync="isShowWarningModal"
      :repeatNameList="repeatNameList"
      :repeatFileNameList="repeatFileNameList"
      :repeatFolderNameList="repeatFolderNameList"
    >
    </g-warning-modal>
    <a-modal
      v-model="isShowOrderMark"
      :title="$t('common.warning')"
      :okText="$t('common.export')"
      @ok="handleOrderMarkOk"
      @cancel="handleOrderMarkCancel"
      id="materialListOrderMark"
    >
      <div class="order-mark-body">
        <p style="margin-left: 80px" v-if="proucePopupState.is_produced_orders">
          {{ $t('materialPage.sameOrder') }}
        </p>
        <p
          class="flex"
          style="margin-left: 80px"
          v-if="proucePopupState.is_display_mark"
        >
          <span class="mr10">{{ $t('materialPage.markOrder') }}</span>
          <a-radio-group
            :options="orderMarkOptions"
            :default-value="proucePopupState.mark_action"
            v-model="proucePopupState.mark_action"
          />
        </p>
      </div>
    </a-modal>
    <!-- 待排版入库弹窗 -->
    <g-base-modal
      v-if="isShowAddAWaitStoreDialog"
      :okText="translateLang('common.confirm')"
      :cancelText="$t('common.cancel')"
      :visible="isShowAddAWaitStoreDialog"
      :title="$t('common.sysTip')"
      :isLoading="isAddAwaitStoreLoading"
      :contain="$t('materialPage.isConfirmPushToAwaitStore')"
      @ok="handleStorageToAwaitStore"
      @cancel="isShowAddAWaitStoreDialog = !isShowAddAWaitStoreDialog"
    >
      <template #title-icon>
        <i
          class="iconfont icon-warn mt1 mr6"
          style="color: #18a8c7; font-size: 22px"
        ></i>
      </template>
    </g-base-modal>
    <!-- 本地直接下载nc文件所使用的目录被删除提示的弹窗 -->
    <g-folder-choose-modal
      :isVisible.sync="isShowFolderTip"
      :cb="handleProlineIsElectronicSaw"
      ref="folderChooseModalRef"
    ></g-folder-choose-modal>
  </div>
</template>

<script>
import { getRawMaterialPlankInfo } from '@/apis/baseMaterial'
import { addPlankToStore } from '@/apis/bujianStore'
import { buryPoint } from '@/apis/common/buryPoint'
import { GetProductionNcSetting } from '@/apis/common/productionNcSetting'
import { saveHistory } from '@/apis/common/saveHistory'
import { getThirdData } from '@/apis/common/thirdData'
import { getProductionNcSetting } from '@/apis/equipment'
import { getSaeSetting } from '@/apis/equipment/index'
import {
  getPreferencesSetting,
  savePreferencesSetting,
} from '@/apis/materialList'
import {
  getAllKnifes,
  getProucePopupState,
  updateProduceStatus,
} from '@/apis/paiban'
import { getPrelayoutSetting } from '@/apis/preLayoutSetting'
import { httpRequest } from '@/apis/request'
import { surplusLock } from '@/apis/surplusManage/index.ts'
import BaseFrom from '@/baseui/from'
import loading from '@/components/common/loading'
import drawItem from '@/components/draw/drawItem.vue'
import GBaseModal from '@/components/g-base-modal.vue'
import GCopyLine from '@/components/g-copy-line'
import GFolderChooseModal from '@/components/g-folder-choose-modal.vue'
import GTableDialog from '@/components/g-table-dialog.vue'
import GTaskLoading from '@/components/g-task-loading.vue'
import GWarningModal from '@/components/g-warning-modal.vue'
import holesSlotsPopUp from '@/components/material/holesAndSlotsDetail.vue'
import virtualList from '@/components/materialTable'
import {
  allTableHeaderList,
  headPropList,
} from '@/components/materialTable/src/config/tableConfig'
import msgDialog from '@/components/msgDialog.vue'
import newContent from '@/components/newContent.vue'
import { plankMapState } from '@/constants'
import store from '@/store'
import {
  changeLayoutData,
  deepCopy,
  generatorSideHoleSlot,
  layoutStart,
  reversalPlank,
} from '@/util/LayoutFuncs.js'
import { rotatePart } from '@/util/LayoutTool'
import { sendTrialRecord } from '@/util/NcFileRelated'
import EventBus from '@/util/bus'
import {
  checkColorIsExist,
  checkMatCodeIsExist,
  fetchMaterialMatCodes,
  translate,
} from '@/util/commonFun'
import {
  buryPointApi,
  compare,
  genOrderAddress,
  generatePlankId,
  generateSimplePlankNum,
  getTryProductionRoomId,
  isSideMachineHole,
  isSideMachineSlot,
  modalMsg,
  splitTime,
  toDecimal,
  uniquePlankNum,
  verifyInputNumber,
} from '@/util/commonFuncs'
import { fileNameSetObj } from '@/util/configure'
import { dealGuiguiJson } from '@/util/dealGuiguiJson.js'
import {
  dealLayoutResultData,
  dealPaibanData,
  dealPaibanDataLabelID,
  dealPlankListLabelID,
  dealPreLayoutDataLabelID,
  genPaibanRequestParams,
  genSurplusParams,
  getPlateKnifeDiameter,
} from '@/util/dealPaibanData.js'
import { dealThinkerxMaterialData } from '@/util/dealThinkerxMaterialData'
import { DrawPartView } from '@/util/drawPlank'
import JsZip from '@/util/jszip'
import {
  GenerateHoleSlotPdf,
  arrDeduplication,
  basisSettingExpandPlank,
  clearObjAttr,
  dealEdgeInfoChange,
  dealExcelManifest,
  dealFormerEdgeChange,
  dealGuimenFGNo,
  dealMaterialList,
  dealMorePlateKnife,
  dealOrderName,
  downloadCsvFile,
  getSlotBasicInfo,
} from '@/util/plankCommonFuncs'
import { regReplaceNewMaterialListCheckInpput } from '@/util/regReplace'
import {
  _getKey,
  _translateFileNameFn,
  dealGroupFile,
  promiseToZip,
} from '@/util/saveFile'
import bjPlankMock from '@/util/sup_plankMock'
import { handleGenFileNameStr } from '@/util/tag'
import { getGraghEdge } from '@/util/tag'
import { jsPdfTool } from '@/util/tag/pdfFont'
import { getPlankToAwaitStoreData } from '@/views/newPaiban/component/m-paiban-store/utlis'
import MTryProductionDialog from '@/views/newPaiban/component/m-try-production-dialog'
import {
  downloadFile as downloadNCFile,
  mergeFolder,
} from '@/views/newPaiban/util/downLoadNC'
import { uploadFiles as ossUploadFiles } from '@/views/newPaiban/util/uploadFiles'
import { createTask } from '@/views/taskList/util/dealData'
import OSS from 'ali-oss'
import { message } from 'ant-design-vue'
import axios from 'axios'
import FileSaver from 'file-saver'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { mapMutations, mapState } from 'vuex'
import XLSX from 'xlsx'

import {
  calcBaseMaterial,
  intersection,
} from '../newPaiban/util/layoutDataDeal'
import MAwaitPaibanStore from './components/m-await-paiban-store.vue'
import MBujianTable from './components/m-bujian-table.vue'
import CustomizeTableHeader from './components/m-customize-table-header/index.vue'
import MIncorrectFormat from './components/m-incorrect-format.vue'
import MNotPaibanDetail from './components/m-not-paiban-detail.vue'
import plankEditer from './components/m-plank-editer.vue'
import plankFilter from './components/m-plank-filter.vue'
import paibanSetting from './components/paibanSetting.vue'
import { samPleConfig } from './config/formConfig'
import { rules } from './config/roules'
import { surPlusConfig } from './config/tableConfig'
import { dealPreLayoutSetting } from './util/preLayoutDeal'

const orderMarkOptions = [
  { label: '标记', value: 1 },
  { label: '不标记', value: 0 },
]

// 高光板下拉框配置项
const highPlankOptions = [
  { value: true, label: 'common.heighGlossPlank' },
  { value: false, label: 'common.notHeighGlossPlank' },
]
// 纹理配置项
const texDirOptions = [
  { value: 'notcare', label: '无纹理' },
  { value: 'reverse', label: '横纹' },
  { value: 'normal', label: '竖纹' },
]

const MCustomizeTableHeader = () =>
  import('./components/m-customize-table-header')

// 旧的未更改余料模板数据,用于判断余料模板数据是否被更改
// 样例表头
const sampleHead = ['项目地址', '客户资料', '工厂单号', '房间名称', '订单备注']

export default {
  components: {
    drawItem,
    holesSlotsPopUp,
    loading,
    BaseFrom,
    paibanSetting,
    GTaskLoading,
    virtualList,
    GCopyLine,
    newContent,
    MBujianTable,
    plankFilter,
    plankEditer,
    MTryProductionDialog,
    MCustomizeTableHeader,
    CustomizeTableHeader,
    GTableDialog,
    MIncorrectFormat,
    GBaseModal,
    GWarningModal,
    MAwaitPaibanStore,
    MNotPaibanDetail,
    GFolderChooseModal,
  },
  data() {
    return {
      isFirstLoadJson: true,
      OrderTypeMap: {
        singleOrder: '单订单',
        multiOrder: '多订单',
        noOrder: '无订单',
      },
      highPlankOptions,
      texDirOptions,
      // 材质数组
      matcodeList: [],
      orderMarkOptions: [
        { label: this.$t('common.mark'), value: 1 },
        { label: this.$t('common.noMark'), value: 0 },
      ],
      // 重名文件夹
      repeatFileNameList: [],
      // 重名文件名
      repeatFolderNameList: [],
      // 重名数组
      repeatNameList: [],
      isShowWarningModal: false,
      // 展示超长板件名称
      isShowAllText: false,
      // 存储检测出来的超长板件的数据
      extraLongPlates: [],
      // 当前展示板件超长数据
      currentExtraLongPlatesItem: {},
      // 当前展示的超长板件的序号（index默认为1）
      extraLongPlatesIndex: 1,
      // 当前展示的超长板件的板件名称
      extraLongPlatesName: '',
      // 板件的uniqueID
      extraLongPlatesUniqueID: '',
      // 是否展示板件详情
      isShowPlateDetails: false,
      //选中排版板件总共数量
      recordChoosePlankDataLength: 0,
      // 补件模拟数据
      bjPlankMock: bjPlankMock,
      // 记录余料数据
      surplusHeaders: [
        {
          label: 'surplusPage.surplusName',
          props: 'name',
          width: '7',
        },
        {
          label: 'materialPage.surplusHead.shape',
          props: 'shape',
          width: '4',
        },
        {
          label: 'materialPage.surplusHead.type',
          props: 'type',
          width: '7',
        },
        { label: 'common.color', props: 'color', width: '4' },
        { label: 'common.thick', props: 'thick', width: '6', unit: 'mm' },
        { label: 'common.long', props: 'long', width: '6', unit: 'mm' },
        {
          label: 'common.shortLong',
          props: 'min_long',
          width: '8',
          unit: 'mm',
        },
        { label: 'common.width', props: 'width', width: '6', unit: 'mm' },
        {
          label: 'common.shortWidth',
          props: 'min_width',
          width: '7',
          unit: 'mm',
        },
        {
          label: 'common.heighGlossPlank',
          props: 'is_high_gloss_plank',
          width: '7',
        },
        { label: 'surplusPage.createTime', props: 'create_time', width: '7' },
        { label: 'surplusPage.branchName', props: 'branch_name', width: '7' },
        {
          label: 'materialPage.surplusHead.remark',
          props: 'remark',
          width: '7',
        },
        {
          label: 'materialPage.surplusHead.area',
          props: 'area',
          width: '5',
          unit: 'm²',
        },
        { label: 'common.count', props: 'showAmount', width: '6' },
      ],
      // 记录选中的表格还是绘制
      toggleListTile: true,
      // 记录表头
      topHeaders: [
        { label: this.$t('common.plankNo'), props: 'plankID', width: '8' },
        { label: this.$t('common.plankName'), props: 'partName', width: '12' },
        { label: this.$t('common.matCode'), props: 'matCode', width: '7' },
        { label: this.$t('common.color'), props: 'texture', width: '7' },
        { label: this.$t('common.thick'), props: 'thick', width: '5' },
        {
          label: this.$t('common.finishedHeight'),
          props: 'specHeight',
          width: '8',
        },
        {
          label: this.$t('common.finishedWidth'),
          props: 'specWidth',
          width: '8',
        },
        { label: this.$t('common.count'), props: 'amount', width: '5' },
        { label: this.$t('common.texture'), props: 'texDir', width: '8' },
        {
          label: this.$t('common.holeSlotsStastic'),
          props: 'hsInfo',
          width: '8',
        },
        { label: this.$t('common.edge.front'), props: 'frontEdge', width: '4' },
        { label: this.$t('common.edge.back'), props: 'backEdge', width: '4' },
        { label: this.$t('common.edge.left'), props: 'leftEdge', width: '4' },
        { label: this.$t('common.edge.right'), props: 'rightEdge', width: '4' },
        {
          label: this.$t('common.isSpecial'),
          props: 'specialShape',
          width: '4',
        },
      ],
      // 是否选中所有板件
      isSelectedAllPlank: true,
      // 记录用于搜索板件的关键词
      plankKeyWord: '',
      // 记录所有的板件
      plankList: [],
      // 显示添加板件右侧抽屉
      showDrawer: false,
      // 记录新增的板件对象
      newPlankInfo: {
        plankID: '',
        partName: '',
        matCode: '',
        texture: '',
        thick: '',
        specHeight: '',
        specWidth: '',
        amount: 1,
        texDir: 'normal',
        hsInfo: '',
        leftEdge: '',
        rightEdge: '',
        backEdge: '',
        frontEdge: '',
        plank_remarks: '',
      },
      // 记录点击的行
      rowIndex: -1,
      // 记录点击的列的属性值
      columnProp: '',
      // 记录点击的表格
      tableIndex: -1,
      // 点击单元格时记录当前值
      nowInputWord: '',
      // 显示保存排版弹窗
      showSaveDialog: false,
      // 方案名称
      paibanWayName: `${this.$t(
        'materialPage.save.historyName'
      )} ${splitTime()}`,
      // 记录预处理数据
      layoutData: [],
      // 是否显示生产线弹窗
      showProLineDialog: false,
      // 记录输入是否合法
      errorInput: false,
      // 记录所有的生产线
      proLineList: [],
      // 记录选中的生产线
      activeProLineId: {},
      // 记录选中的板件
      selectionPlankList: [],
      // 记录是否来自老板良
      isFromLbl: false,
      // 是否显示加载
      isShowLoading: false,
      isConfirmLoading: false,
      // 是否显示消息确认框
      showMsgDialog: false,
      //是否显示导入出错消息框
      showTableDialog: false,
      //是否显示导入数据格式错误消息框
      showMIncorrectFormat: false,
      // 记录搜索后的板件数据
      searchList: [],
      // 记录选中的板件下标
      activePlankNum: 0,

      // 开料清单优化开始
      nowPlankDetail: null,
      HS_show: false,
      // 开料清单优化结束
      needBaseMaterials: [], //记录可使用的原片数据
      lackBaseMaterials: [], //记录需要添加的原片数据，若长度大于0，代表存在超大板，并且原片中没有满足该超大板的原片，需要去原片管理添加原片
      showAddBaseMModal: false,
      showSyncGuiguiBaseMModal: false, //显示是否同步柜柜原片数据
      BaseMIsSame: true, //原片管理数据是否有更新

      // 记录获取到的余料数据
      surplusList: [],
      // 记录匹配到的余料数量
      surplusNum: 0,
      // 记录是否展示余料
      // 记录是否全选余料
      isSelectedAllSurplus: true,
      // 记录余料页码
      surplusPage: 1,
      // 判断下拉加载更多板件时, 是否为选中状态
      isAllSurplusTrue: true,
      visible: false, //是否显示欢迎弹窗
      recentHistory: [], //最近历史
      // 余料自动裁剪
      surplusTailor: true,
      // 是否显示余料添加弹窗
      surplusVisible: false,
      // 分页样式/数据
      pagination: {
        total: 1,
        style: {
          marginTop: '5px',
        },
        small: true,
        background: true,
        pageSizes: [3, 6, 15, 30],
        layout: 'sizes, prev, pager, next',
      },
      // 余料模板分页
      page: {
        limit: 3,
        page: 1,
      },
      // table的是否显示加载
      tableLoading: false,
      // 余料表格配置
      surPlusConfig: Object.freeze(surPlusConfig),
      // 余料模板表格验证
      surplusRules: Object.freeze(rules),
      // 余料模板数据
      surplusData: [],
      // 已选中的模板
      surplusSelect: [],
      // 裁剪方式
      tailorWay: 'maxRect',
      // 记录开始排版弹窗里需要记住的字段
      state: {
        layoutMethod: '',
        paibanWay: '',
        process_line_id: '',
        surplusTailorWay: 'maxRect',
        surplusAutoTailor: true,
        // 记录是否少翻版
        isLessReverse: false,
        // 记录是否支持异形
        isSpecialShape: false,
        // 最大矩形裁剪   裁剪最小尺寸
        // 长边
        longSideRect: '300',
        // 短边
        shortSideRect: '250',
        // 是否开启自定义大板排版
        customPlankOrder: false,
        plankOrderList: [],
      },
      // 排版方案
      paibanWayList: [
        {
          title: this.$t('common.sawArrange'),
          interface: 'paiban',
          desc: this.$t('materialPage.paibanSetting.suitEquipmentSpecial'),
        },
        {
          title: this.$t('common.directArrange'),
          interface: 'paiban_v2',
          desc: this.$t('materialPage.paibanSetting.suitEquipmentNormal'),
        },
      ],
      // 记录选择的排版方案
      selectedPaibanWay: 'paiban_v2',
      // 记录是否生产线是否为玻璃切割机
      isGlassEquip: false,
      minSize: undefined,
      maxSize: undefined,
      sizeError: false,
      filterPlankList: [], // 显示过滤后的列表
      currentSelectPart: null,
      emptyShow: false,
      // 记录是否正在导出孔槽图
      isExportHoleSlotPdf: false,
      // 记录另一种是否正在导出孔槽图
      isExportHoleSlotPdfOther: false,
      samPleConfig: samPleConfig,
      // 样表表头数据
      samPleFormData: {},
      moreSampleFormData: {
        address: [],
        clientData: [],
        factoryOrderNum: [],
        orderRemark: [],
        roomName: [],
      },
      sendToFactoryDialog: false,
      // 保存NC文件的ossurl
      NCUrls: [],
      // 保存标签文件的ossUrl
      ossUrl: { data: '', type: '' },
      isShowTask: false,
      taskList: [],
      selectedProLine: '',
      // 是否为发送至车间
      isSendToFactory: false,
      // 料单配置信息
      isError: false,
      // 料单表格宽度
      virtualHeadRowWidth: 0,
      // 记录统一设置得门板属性
      plankDoorSelectVal: '',
      // 记录是否加载更多的表单
      isLoadMoreTable: true,
      // 是否默认收起表格数据
      isDefaultFoldData: false,
      // 偏好设置
      preferencesSetting: {
        // 补件推荐
        isBujianRecommend: false,
        // 待排版库推荐
        isPaibanStoreRecommend: false,
        // 余料推荐
        isSurplusRecommend: false,
      },
      // 已勾选的筛选条件
      filterObj: {},
      // 以搜索的筛选
      searchVal: new Map(),
      // 当前拆选的板件属性
      filterProp: '',
      // 上一次筛选的板件属性
      lastFilterProp: '',
      // 记录当前筛选的所有属性
      filterProps: [],
      // 上一次筛选的所有属性
      lastFilterProps: [],
      // 筛选弹窗的展示与否
      filterVisibel: false,
      // 批量编辑弹窗
      editerVisibel: false,
      // 是否使用尺寸范围筛选或板件搜索
      hasSearchOrSizeFilter: false,
      // 筛选框中的筛选
      filterSearchVal: '',
      // 排版错误
      isPaibanErr: false,
      // 使用急速排版
      isNewLayout: false,
      // 判断表格数据是否为空
      isTableEmpty: true,
      //判断是否同步柜柜原片数据模态框检查
      isCheckBigPlankSynchronous: false,
      //记录自定义表头保存的数据
      recordSaveTableHeader: [],
      //表头映射表
      tableHeaderMappingList: {
        [this.$t('common.plankNo')]: 'plankID',
        [this.$t('common.matCode')]: 'matCode',
        [this.$t('common.color')]: 'texture',
        [this.$t('common.plankName')]: 'partName',
        [this.$t('common.finishedHeight')]: 'specHeight',
        [this.$t('common.finishedWidth')]: 'specWidth',
        [this.$t('common.count')]: 'amount',
        [this.$t('common.area')]: 'area',
        [this.$t('common.edge.left')]: 'leftEdge',
        [this.$t('common.edge.right')]: 'rightEdge',
        [this.$t('common.edge.front')]: 'frontEdge',
        [this.$t('common.edge.back')]: 'backEdge',
        [this.$t('common.texture')]: 'texDir',
        [this.$t('common.thick')]: 'thick',
        [this.$t('common.orderAddress')]: 'address',
        [this.$t('common.plankNum')]: 'oriPlankNum',
        [this.$t('common.customer')]: 'customer_name',
        [this.$t('common.room')]: 'roomName',
        [this.$t('common.orderNo')]: 'orderNo',
        [this.$t('common.loc')]: 'loc',
        [this.$t('common.holeSlotsStastic')]: 'hsInfo',
        [this.$t('common.isSpecial')]: 'specialShape',
        [this.$t('common.isDoorPlank')]: 'type',
        [this.$t('common.plankRemark')]: 'plank_remarks',
        [this.$t('common.roomRemark')]: 'remark',
        // 新增导出csv高光板字段
        [this.$t('common.heighGlossPlank')]: 'is_high_gloss_plank',
      },
      //表头选择项数据
      choose: {
        prop: 'select',
        selectProp: 'isSelected',
        headSlotName: 'headSelect',
        label: this.$t('common.select'),
        widthType: 'width',
        width: '64px',
        minWidth: '64px',
        temp: this.$t('common.select'),
        sort: 0,
      },
      //判断所以导入表格不可缺少的表头f
      indispensableHeaderDataList: [
        this.$t('common.matCode'),
        this.$t('common.color'),
        this.$t('common.thick'),
        this.$t('common.finishedHeight'),
        this.$t('common.finishedWidth'),
        this.$t('common.count'),
        this.$t('common.texture'),
      ],
      //导入列表时，表头缺少必要表头
      lackTableString: '',
      //导入列表时，必要表头项缺失数据
      necessaryTableLackString: {},
      //导入列表时，必要表头项数据格式不正确
      necessaryTableIncorrectFormat: {},
      //缺少必要表头弹出消息框
      isShowLackNeedTableHeader: false,
      //必要表头缺少对应数据弹出消息框
      isShowLackNeedTableHeaderData: false,
      headerItemAddress: {},
      // 生产线名称
      selectProLine: '',
      // 需要清楚孔槽、异形的表头值
      deleteHolesSlotsDataList: [
        'texDir',
        'specHeight',
        'specWidth',
        'leftEdge',
        'rightEdge',
        'frontEdge',
        'backEdge',
        'thick',
      ],
      isShowPopover: false,
      texDirOptions: [
        { value: 'reverse', label: this.$t('common.reverse') },
        { value: 'normal', label: this.$t('common.normal') },
        { value: 'notcare', label: this.$t('common.notCare') },
      ],
      // 文件管理设置
      customizeFolderSetting: {},
      showSendTaskModal: false,
      sendTaskModal: null,
      isTaskLoading: false,
      plankErrVisible: false,
      isSavePaiBanLoading: false,
      newPlankInfoTexture: 'aaa',
      colorSearchKey: '',
      materialColorOptions: [],
      isShowOrderMark: false,
      isCancontinue: false,
      proucePopupState: {
        mark_action: 1,
        is_produced_orders: true,
        is_display_mark: true,
      },
      orderIds: [],
      // 排版时自动添加板材提醒
      autoAddSpecialPlate: false,
      showAddSpecialPlate: false,
      bigPlateList: [],
      showBigPlateDetail: false,
      bigPlateDetailList: [],
      bigPlateDetailColumns: [
        { label: this.$t('common.orderNo'), prop: 'orderNo', width: '180' },
        {
          label: this.$t('common.orderAddress'),
          prop: 'address',
          width: '180',
        },
        { label: this.$t('common.roomName'), prop: 'roomName', width: '180' },
        { label: this.$t('common.plankName'), prop: 'partName', width: '180' },
        {
          label: this.$t('common.plankMatCode'),
          prop: 'matCode',
          width: '180',
        },
        { label: this.$t('common.plankColor'), prop: 'texture', width: '180' },
        {
          label: `${this.$t('common.cutHeight')}mm`,
          prop: 'blankHeight',
          width: '120',
        },
        {
          label: `${this.$t('common.cutWidth')}mm`,
          prop: 'blankWidth',
          width: '120',
        },
        { label: `${this.$t('common.thick')}mm`, prop: 'thick', width: '180' },
        { label: this.$t('common.texture'), prop: 'texDir' },
        { label: this.$t('common.plankNum'), prop: 'plankNum', width: '180' },
        { label: this.$t('common.plankNo'), prop: 'plankID', width: '180' },
      ],
      isDelBigPlate: false,
      materialRes: '',
      texDirMap: {
        reverse: this.$t('common.reverse'),
        normal: this.$t('common.normal'),
        notcare: this.$t('common.notCare'),
      },
      isAddBigPlateLoading: false,
      currentSelectPlankList: [],
      currentSelectStandardPlank: {},
      currentHistoryPlankEdgeOff: 0,
      savedHightLightProp: false,
      isShowAddAWaitStoreDialog: false, // 是否显示待排版库添加弹窗
      isAddAwaitStoreLoading: false, // 是否正在添加
      // 未排小板清单
      showPartDetail: false,
      paibanMessage: {},
      isShowFolderTip: false,
      /** 记录原始NCsetting */
      oriNCSetting: null,
      oriPreSetting: null,
    }
  },

  computed: {
    ...mapState([
      'userInfo',
      'isHistoryStatus',
      'surplusParams',
      'dataFrom',
      'prePaibanData',
      'recodeIsTailor',
      'isLinkLogin',
      'isSinglePushPrePaibanData',
      'errorPathPlankID',
      'isShowSurplusList',
      'materialList',
      'isTrialProduct',
      'thirdPlankData',
      'hasSendToTask',
      'materialTask',
      'produceTime',
      'isBatch',
      'batchNo',
      'batchPaiban',
      'isLocalImport',
      'isLoadingColors',
      'materialColorList',
      'selectStandardPlank',
      ...plankMapState,
      'newMaterialColorList',
      'newMaterialMatCode',
      'trailProductState',
      'defaultSelectedPlank',
      'preLayoutSetting',
      'preLayoutData',
      'importCount',
      'selectSurplusList',
    ]),
    ossFilesParams() {
      let obj = {
        layoutData: this.preLayoutData,
        paibanData: [],
        recodeSelectPart: this.recodeSelectPart,
        recodeSelectSurplus: this.recodeSelectSurplus,
        thinkerxMaterialKeys: this.$store.state.thinkerxMaterialKeys,
        filterPlankList:
          this.beforeSearchMaterialList ??
          this.beforeSizeFilterList ??
          this.filterPlankList,
      }
      if (this.paibanData.length > 0 && !this.ncSetting.isPreLayout) {
        obj.process_setting_id = this.ncSetting.process_setting_id
        obj.process_setting_name = this.ncSetting.process_setting_name
        // obj.paibanData = this.paibanData
      }
      if (this.isShowSample) {
        obj.sampleFormData = this.sampleTableData
      }
      let time = new Date()
      let name =
        '/bluenMaterialData/' +
        time.getTime() +
        '_' +
        this.paibanWayName +
        '.json'
      return { name, obj }
    },
    // 记录当前生产线名称
    currentProLineName() {
      const { cuttingEquipment } = this.proLineList.find(
        (item) => this.state.process_line_id === item.id
      ).data
      return cuttingEquipment.name
    },
    activeProLineName() {
      const proLine = this.proLineList.find(
        (v) => v.id == this.state.process_line_id
      )
      let name = proLine ? proLine.name : this.$t('common.defaultProduction')
      return name
    },
    selectedSurplusNum() {
      if (this.isAllSurplusTrue) {
        let num = 0
        for (let i = 0; i < this.surplusList.length; ++i) {
          if (!this.surplusList[i].isSelected) {
            num++
          }
        }
        let finalNum = this.surplusNum - num
        return finalNum
      } else {
        let num = 0
        for (let i = 0; i < this.surplusList.length; ++i) {
          if (this.surplusList[i].isSelected) {
            num++
          }
        }
        return num
      }
    },
    caclTotal() {
      let result = 0
      this.filterPlankList.forEach((item) => {
        item.parts.forEach((part) => {
          result += part.amount
        })
      })
      return result
    },
    isMoreOrder() {
      const len = this.orderInfo?.order_address?.split(/,/).length > 1
      if (len)
        this.$message({
          type: 'warning',
          center: true,
          message: `<span id="material_orders_warning">${this.$t(
            'materialPage.editErrTip'
          )}</span>`,
          dangerouslyUseHTMLString: true,
        })
      return len
    },
    // 列表所需的数据格式
    materialBaseData() {
      const data = this.filterPlankList ?? []
      const resultParts = []
      data.forEach((plank, idx) => {
        // 添加字段标识大板数据
        // 每循环一个大板就填加一个隔断信息表示一种材质的板件
        const headData = Object.assign(plank, {
          isVirtualHead: true,
          materialBaseTableIndex: idx,
          // 添加一个和板件一样的唯一key
          partUniqueId: nanoid(),
        })
        resultParts.push(headData)
        if (plank.isUnfold) {
          plank.parts.forEach((part, partIdx) => {
            part.materialBaseTableIndex = idx
            part.materialBasePartIndex = partIdx
            // 如果小板上没有highPlank属性, 则通过小板的is_high_gloss_plank属性来判断
            resultParts.push(part)
          })
        }
      })
      return resultParts
    },
    // 已选中的板件
    alreadySelectParts() {
      const parts = this.filterPlankList
        .map((plank) => plank.parts)
        .flat(2)
        .filter((part) => part.isSelected)
      return parts
    },
    currentHeadPropList() {
      this.recordSaveTableHeader = this.$store.state.inPerferencesTableHeader
        ?.filter((item) => item.label !== this.$t('common.select'))
        .map((item) => item.label)
      this.dealRecord()
      return this.$store.state.inPerferencesTableHeader ?? headPropList
    },
  },
  watch: {
    plankKeyWord() {
      this.activePlankNum = 0
      this.searchList = []
    },
    proLineList: {
      handler(newVal) {
        this.proLineList = newVal
      },
      immediate: true,
    },
    // 更新vuex数据
    samPleFormData: {
      handler(n) {
        if (JSON.stringify(n) !== '{}') {
          Object.keys(n).forEach((key) => {
            if (n[key] && n[key].endsWith(',')) {
              n[key] = n[key].slice(0, -1)
            }
          })

          const order = {
            ...this.orderInfo,
            order_address: dealOrderName(n.address, n.factoryOrderNum),
          }
          if (
            order.order_address &&
            !this.showMIncorrectFormat &&
            !this.showTableDialog
          ) {
            this.setOrderInfo(order)
          }

          // 数据更新 同步更新订单信息
          this.setSampleTableData(n)
          // 项目地址 工厂单号等如符合多订单则不可再编辑
          if (this.isMoreOrder) {
            this.samPleConfig.formItems = this.samPleConfig.formItems.map(
              (item) => {
                item.otherOptions.disabled = true
                item.tooltip.disabled = false
                return item
              }
            )
            if (this.isLoadMoreTable) {
              this.samPleConfig.formItems.forEach((item) => {
                item.tooltip.content =
                  this.moreSampleFormData[item.field].join(',')
              })
            }
          } else {
            this.samPleConfig.formItems = this.samPleConfig.formItems.map(
              (item) => {
                item.otherOptions.disabled = false
                item.tooltip.disabled = true
                return item
              }
            )
          }
        }
      },
      deep: true,
    },
    // 开料单变化
    filterPlankList: {
      deep: true,
      handler(val) {
        this.isSelectedAllPlank = val
          .map((plank) => plank.parts)
          .flat(1)
          .every((part) => part.isSelected)
        this.filterPlankList.forEach((plank) => {
          plank.isAllSelect = plank.parts.every((part) => part.isSelected)
        })
      },
    },
    isShowPlateDetails: {
      handler(val) {
        if (val && !this.showAddBaseMModal) {
          this.extraLongPlatesUniqueID = ''
        }
      },
    },
    materialColorList: {
      handler(val) {
        this.materialColorOptions = val
      },
    },
    // 当前超尺小板
    currentExtraLongPlatesItem: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          // FIX: 解决手动添加的板件无高光属性问题,后续会在添加板件时增加高光属性
          if (val.is_high_gloss_plank === undefined) {
            val.is_high_gloss_plank = false
          }
          // 根据小板属性获取对应的大板
          const symbol = `${val.matCode}:${val.thick}:${val.texture}:${val.is_high_gloss_plank}`
          this.filterPlankList = this.filterPlankList.map((item) => {
            if (`${item.symbol}` === symbol) {
              item.isUnfold = true
            } else {
              item.isUnfold = false
            }
            return item
          })
        }
      },
    },
  },
  methods: {
    ...mapMutations([
      'setHistoryOrder',
      'setOrderInfo',
      'setPreLayoutParam',
      'setPreLayoutData',
      'setPaibanData',
      'setNcSetting',
      'setPaibanExtraInfo',
      'changeHistoryStatus',
      'changeDataFrom',
      'clearPaibanData',
      'setActivePaibanWay',
      'deleteTempStorage',
      'setActivePaibanWay',
      'setSpecialPlankParams',
      'recordGuimenDataKeys',
      'setFinalDrawData',
      'setRecodeSelectPart',
      'setRecodeSelectSurplus',
      'setSurplusCommonSize',
      'setSurplusTailorWay',
      'setPaibanInfo',
      'setSurplusAutoTailor',
      'setRecodeIsTailor',
      'setIsResetPaibanAllocation',
      'setHasPrintTag',
      'setPaibanKey',
      'setSampleTableData',
      'setIsShowSample',
      'setIsLinkLogin',
      'setLongSideRect',
      'setShortSideRect',
      'setIsSinglePushPrePaibanData',
      'setPrePaibanData',
      'setAllKnifes',
      'setAvailableBujianList',
      'onChangeIsShowSurplusList',
      'setShowSurplusList',
      'setFilterMaterialList',
      'setMaterialList',
      'setFilterObject',
      'setBeforeSearchMaterialList',
      'setBeforeSizeFilterList',
      'setIsTrialProduct',
      'setThinkerxMaterialKeys',
      'setHighglossDirection',
      'setHasRollPoly',
      'setHasSendToTask',
      'setMaterialTask',
      'setBatchPaiban',
      'setProduceTime',
      'setIsLocalImport',
      'setIsNewPaiban',
      'setHistorySecondTrimValue',
      'setHistoryPlankEdgeOff',
      'setNewMaterialColorList',
      'setNewMaterialMatCode',
      'setSelectStandardPlank',
      'setCustomPlankOrder',
      'setPlankOrderList',
      'setImportCount',
      'setSelectSurplusList',
    ]),
    //清空导入表头缺少数据
    handleClearMissingData() {
      this.showTableDialog = false
      this.isShowLackNeedTableHeaderData = false
      this.necessaryTableLackString = {}
      this.lackTableString = ''
    },
    //清空导入表头数据格式错误
    handleclearMIncorrectFormatData() {
      this.showMIncorrectFormat = false
      this.necessaryTableIncorrectFormat = {}
    },
    //判断是否为数字且大于0
    isNumericPositive(value) {
      const number = Number(value)
      return !isNaN(number) && number > 0
    },
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    handleSettingTableHeader() {
      this.$refs.tableHeaders._setupState.isShowDialog.value = true
      this.$refs.tableHeaders._setupState.showEditTableHeader()
    },
    /** 打开存入待排版库弹窗 */
    handleOpenPushStoreDialog() {
      if (!this.alreadySelectParts?.length)
        return message.warning(this.translateLang('common.pleaseSelectPlank'))
      this.isShowAddAWaitStoreDialog = true
    },
    /** 存储板件到待排版库 */
    async handleStorageToAwaitStore() {
      this.isAddAwaitStoreLoading = true
      const storeData = await getPlankToAwaitStoreData(this.alreadySelectParts)
      const result = await addPlankToStore(storeData)
      this.isAddAwaitStoreLoading = false
      this.isShowAddAWaitStoreDialog = false
      if (result.status !== 1)
        return message.error(
          result.msg ||
            this.translateLang('arrangedPage.awaitPlankStore.pushErr')
        )
      message.success(
        this.translateLang('arrangedPage.awaitPlankStore.pushSuccess')
      )
      // 入库完成后将原始数据中的板件删除
      this.deleteMaterialSelectPlank()
      // 完成入库重新请求新的待排版库数据
      await this.$store.dispatch('awaitPaibanStore/getServePlankData')
      // 重新匹配新的板件
      this.$refs['awaitStoreRef']?.getAwaitStoreList()
      buryPoint({
        function_module: 'await_store',
        function_point: 'batch_push_store',
      })
    },
    // 查看板件详情弹窗，上一个/下一个
    handleOperate(operate) {
      if (operate === 'last') {
        this.extraLongPlatesIndex--
        if (this.extraLongPlatesIndex === 0) {
          this.extraLongPlatesIndex = this.extraLongPlates.length
        }
      } else {
        this.extraLongPlatesIndex++
        if (this.extraLongPlatesIndex === this.extraLongPlates.length + 1) {
          this.extraLongPlatesIndex = 1
        }
      }
      this.currentExtraLongPlatesItem =
        this.extraLongPlates[this.extraLongPlatesIndex - 1]
      this.extraLongPlatesName = this.currentExtraLongPlatesItem.partName
      this.extraLongPlatesUniqueID =
        this.currentExtraLongPlatesItem.partUniqueId
    },
    // 板件尺寸不符合时，查看板件详情弹窗
    handleViewBordDetail() {
      this.showAddBaseMModal = false
      this.isShowPlateDetails = true
      let ele = document.querySelector('.new-material-list')
      ele.scrollIntoView({ block: 'end' })
    },
    // 查看板件详情的返回上一级
    handleGoBack() {
      this.showAddBaseMModal = true
      this.isShowPlateDetails = false
    },
    // 是否刀具不符合规则
    judgePlateKnife(plate_knife_map) {
      if (!plate_knife_map) return
      const selectPlanks = this.selectionPlankList
      const planksArr = []
      selectPlanks.forEach((item) => {
        planksArr.push(
          item.typeSymbol.split(':')[0] + ':' + item.typeSymbol.split(':')[1]
        )
      })
      let knifesArr = Object.keys(plate_knife_map)
      knifesArr = knifesArr.map((item) => {
        if (item.split('.')[1] == 0) {
          item = item.split('.')[0]
        }
        return item
      })
      if (!intersection(knifesArr, planksArr)) return
      for (const key in plate_knife_map) {
        if (plate_knife_map[key].error) {
          this.isError = true
          this.isShowLoading = false
          this.$confirm(plate_knife_map[key].error, this.$t('common.tip'), {
            confirmButtonText: this.$t('materialPage.goAdd'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning',
          })
            .then(() => {
              const setting_id = this.state.process_line_id,
                line = this.activeProLineName,
                equipType = 'engraving'
              this.$router.push({
                path: `/${equipType}`,
                query: {
                  setting_id,
                  line,
                  equipType,
                },
              })
            })
            .catch(() => {
              this.isError = false
            })
          return
        }
      }
    },
    numberCheck(val, obj, prop) {
      verifyInputNumber(val, obj, prop, '4')
    },
    // 开启余料自动裁剪再调用余料常用模板接口
    handleSwitchChange(checked) {
      checked && this.getSurplusTemp(this.page)
    },
    // 排版方案选择
    handleSelectPaibanWay(item) {
      let layoutMethodStr = this.paibanWayList.find(
        (e) => e.interface === this.selectedPaibanWay
      ).title
      const lessRollTrans = `_${this.$t('materialPage.paibanSetting.lessRoll')}`
      const isSpecialTrans = `_${this.$t(
        'materialPage.paibanSetting.computedSpecial'
      )}`
      if (['lessReserve', 'specialShape'].includes(item)) {
        this.state.paibanWay = this.selectedPaibanWay
        if (this.state.isLessReverse) {
          this.state.paibanWay = this.selectedPaibanWay.includes('v2')
            ? this.selectedPaibanWay.replace('_v2', '_roll_v2')
            : this.selectedPaibanWay + '_roll'
          layoutMethodStr += lessRollTrans
        } else {
          this.state.paibanWay = this.selectedPaibanWay
          layoutMethodStr.replace(lessRollTrans, '')
        }
        if (this.state.isSpecialShape) {
          layoutMethodStr += isSpecialTrans
        } else {
          layoutMethodStr.replace(isSpecialTrans, '')
        }
      } else {
        if (this.state.isSpecialShape) {
          layoutMethodStr += isSpecialTrans
        } else {
          layoutMethodStr.replace(isSpecialTrans, '')
        }
        if (this.state.isLessReverse) {
          this.state.paibanWay = this.selectedPaibanWay.includes('v2')
            ? this.selectedPaibanWay.replace('_v2', '_roll_v2')
            : this.selectedPaibanWay + '_roll'
          layoutMethodStr += lessRollTrans
        } else {
          layoutMethodStr.replace(lessRollTrans, '')
          this.state.paibanWay = this.selectedPaibanWay
        }
      }
      this.state.layoutMethod = layoutMethodStr
    },
    // 生产线选择
    handleSelectProLine(proLineValue) {
      this.selectProLine = proLineValue
    },
    // 标准大板选择
    handleSelectStandard(value) {
      this.currentSelectStandardPlank = value
      this.currentHistoryPlankEdgeOff = value.plankEdgeOff
    },
    filterAmount(item) {
      let result = 0
      item.parts.forEach((part) => {
        result += Number(part.amount)
      })
      return result
    },
    filterArea(item) {
      let result = 0

      item.parts.forEach((part) => {
        result += part.specHeight * part.specWidth * part.amount
      })

      return (result / 1000000).toFixed(2)
    },
    handleOk() {
      //关闭弹窗
      this.visible = false
    },
    // 前往料单前的统一处理
    goToBeforeMaterialDeal(data, item) {
      this.setIsShowSample(true)
      this.setPreLayoutData(dealPreLayoutDataLabelID(data.layoutData))
      let orderInfo = {
        order_address: `${translate('common.history')}-${item.name}`,
        order_ids: item.order_list ? item.order_list.join(',') : '',
        order_codes: item.plank_info.order_codes,
        needPaibanRoomIds: item.room_ids ? item.room_ids : [],
        room_ids: [],
      }
      this.setOrderInfo(orderInfo)
      const sampleData =
        data.sampleFormData ?? clearObjAttr(this.sampleTableData)
      this.samPleFormData = sampleData
      // 当是第三方直接进入，并且没有样表信息的不显示样表头
      if (this.isLinkLogin && !data.sampleFormData) {
        this.setIsShowSample(false)
      }
    },
    // 前往开料清单
    gotoMaterialList(item) {
      axios.get(item.plank_info.historyUrl).then((res) => {
        this.goToBeforeMaterialDeal(res.data, item)
        let pro = new Promise(async (resolve) => {
          if (res.data.paibanData.length > 0) {
            this.setPaibanData(dealPaibanDataLabelID(res.data.paibanData))
            let params = {
              setting_id: item.process_setting_id,
            }
            if (window.sessionStorage.getItem('thinkerx_material')) {
              params.production_from = 'guimen'
            }
            try {
              const result = await GetProductionNcSetting(params)
              res.data && this.judgePlateKnife(result.data.plate_knife_map)
              if (this.isError) return
              let flag = this.oldFiveSixXYTip(result)
              if (flag) return
              result.data.drawPlankWidth = result.data.xyReverse
                ? this.selectStandardPlank.plankHeight
                : this.selectStandardPlank.plankWidth
              result.data.drawPlankHeight = result.data.xyReverse
                ? this.selectStandardPlank.plankWidth
                : this.selectStandardPlank.plankHeight
              let obj = Object.assign(result.data, {
                process_setting_id: item.process_setting_id,
                process_setting_name: item.plank_info.process_setting_name,
              })
              this.setNcSetting(obj)
              this.changeHistoryStatus(true)
            } catch (error) {
              throw new Error('Server Exception')
            }
          } else {
            resolve()
          }
        })
        pro.then((result) => {
          window.sessionStorage.setItem('isHistory', 1)
          this.dealData(res.data.layoutData)
        })
      })
    },
    // 前往排版
    gotoPaiban(item) {
      // 先获取生产线设置, 再进行跳转
      axios.get(item.plank_info.historyUrl).then((res) => {
        let params = {
          setting_id: item.process_setting_id,
        }
        if (window.sessionStorage.getItem('thinkerx_material')) {
          params.production_from = 'guimen'
        }
        this.$getByToken('/get_production_nc_setting', params, (result) => {
          let flag = this.oldFiveSixXYTip(result)
          if (flag) return
          if (result.status == -1) {
            this.$message({
              message: this.$t('materialPage.noProductionLine'),
              type: 'error',
              duration: 1500,
              offset: 60,
            })
            this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
          } else {
            this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
            this.setPaibanInfo(res.data.paibanInfo ?? {})
            if (res.data.paibanData.length > 0) {
              this.setPaibanData(dealPaibanDataLabelID(res.data.paibanData))
              this.setFinalDrawData([])
              if (res.data.thinkerxMaterialKeys) {
                this.setThinkerxMaterialKeys(res.data.thinkerxMaterialKeys)
              }
              let orderInfo = {
                order_address: `${translate('common.history')}-${item.name}`,
                order_ids: item.order_list ? item.order_list.join(',') : '',
                order_codes: item.plank_info.order_codes,
                needPaibanRoomIds: item.room_ids ? item.room_ids : [],
                room_ids: [],
              }
              this.setOrderInfo(orderInfo)
            }
            result.data.drawPlankWidth = result.data.xyReverse
              ? this.selectStandardPlank.plankHeight
              : this.selectStandardPlank.plankWidth
            result.data.drawPlankHeight = result.data.xyReverse
              ? this.selectStandardPlank.plankWidth
              : this.selectStandardPlank.plankHeight
            let obj = Object.assign(result.data, {
              process_setting_id: item.process_setting_id,
              process_setting_name: item.plank_info.process_setting_name,
            })
            this.setNcSetting(obj)
            this.changeHistoryStatus(true)
            window.sessionStorage.setItem('isHistory', 1)
            this.$router.push('/newPaiban')
          }
        })
      })
      axios.get(item.plank_info.historyUrl).then((res) => {
        this.setPreLayoutData(dealPreLayoutDataLabelID(res.data.layoutData))
        this.setPaibanInfo(res.data.paibanInfo)
        if (res.data.paibanData.length > 0) {
          this.setPaibanData(dealPaibanDataLabelID(res.data.paibanData))
          let orderInfo = {
            order_address: `${translate('common.history')}-${item.name}`,
            order_ids: item.order_list ? item.order_list.join(',') : '',
            order_codes: item.plank_info.order_codes,
            needPaibanRoomIds: item.room_ids ? item.room_ids : [],
            room_ids: [],
            batch: item.plank_info.batch,
          }
          this.setOrderInfo(orderInfo)
          let params = {
            setting_id: item.process_setting_id,
          }
          if (window.sessionStorage.getItem('thinkerx_material')) {
            params.production_from = 'guimen'
          }
          this.$getByToken('/get_production_nc_setting', params, (result) => {
            res.data && this.judgePlateKnife(result.data.plate_knife_map)
            if (this.isError) return
            let flag = this.oldFiveSixXYTip(result)
            if (flag) return
            result.data.drawPlankWidth = result.data.xyReverse
              ? this.selectStandardPlank.plankHeight
              : this.selectStandardPlank.plankWidth
            result.data.drawPlankHeight = result.data.xyReverse
              ? this.selectStandardPlank.plankWidth
              : this.selectStandardPlank.plankHeight
            let obj = Object.assign(result.data, {
              process_setting_id: item.process_setting_id,
              process_setting_name: item.plank_info.process_setting_name,
            })
            this.setNcSetting(obj)
            this.changeHistoryStatus(true)
            window.sessionStorage.setItem('isHistory', 1)
            this.$router.push('/newPaiban')
          })
        }
      })
    },
    toDetail(el) {
      //点击近期保存历史
      this.setRecodeSelectPart({ selects: [], isHistory: true })
      this.setRecodeSelectSurplus({ selects: [], isHistory: true })
      this.setHistoryOrder(el)
      if (el.file_type === 'paiban') {
        //已经排过版,跳转到排版方案界面
        this.gotoPaiban(el)
      } else {
        //未排版，跳转到开料清单界面,即当前页面
        this.visible = false
        this.gotoMaterialList(el)
      }
    },
    // 开料清单优化开始
    hideHSPopUp() {
      this.HS_show = false
      this.nowPlankDetail = null
    },

    // 开料清单优化结束
    // 输入限制
    inputCheck(value, type) {
      switch (value) {
        case 'amount':
          this.newPlankInfo[value] = verifyInputNumber(
            this.newPlankInfo[value],
            this.newPlankInfo,
            [value],
            '5'
          )
          break
        case 'thick':
        case 'leftEdge':
        case 'rightEdge':
        case 'frontEdge':
        case 'backEdge':
          this.newPlankInfo[value] = verifyInputNumber(
            this.newPlankInfo[value],
            this.newPlankInfo,
            [value],
            '3-2'
          )
          break
        case 'specHeight':
        case 'specWidth':
          this.newPlankInfo[value] = verifyInputNumber(
            this.newPlankInfo[value],
            this.newPlankInfo,
            [value],
            '5-2'
          )
          break
      }
    },
    // 处理材质和颜色的首尾空格
    dealTrim(value) {
      this.newPlankInfo[value] = this.newPlankInfo[value].replace(
        /^\s+|\s+$/g,
        ''
      )
    },
    // 搜索板件
    searchPlank(isRefresh) {
      if (isRefresh) {
        this.plankKeyWord = ''
      }
      _hmt.push(['_trackEvent', '料单板件搜索', 'click', this.plankKeyWord])

      if (!this.toggleListTile) {
        // 如果显示的不是列表，就直接返回
        return
      }
      this.minSize = ''
      this.maxSize = ''
      if (this.plankKeyWord) {
        let plankList = this.beforeSearchMaterialList ?? this.filterPlankList
        const newList = []
        plankList.forEach((plank) => {
          const parts = plank.parts.filter((part) => {
            const f =
              part.partName.includes(this.plankKeyWord) ||
              part.plankID == this.plankKeyWord
            if (f) return true
            else {
              part.isSelected = true
            }
          })
          if (parts.length) newList.push({ ...plank, parts })
        })
        this.filterPlankList = newList
      } else {
        this.filterPlankList.forEach((plank) => {
          plank.parts.forEach((part) => (part.isSelected = true))
        })
        if (this.filterMaterialList && this.filterMaterialList.length) {
          this.filterPlankList = this.filterMaterialList
        }
        if (
          this.beforeSearchMaterialList &&
          this.beforeSearchMaterialList.length
        ) {
          this.filterPlankList = this.beforeSearchMaterialList
        }
      }
      this.filterPlankList = dealPlankListLabelID(this.filterPlankList)
    },
    // 选中板件
    setActivePlank(plank) {
      const currentList = this.filterPlankList
      let mark = plank.ggid + plank.oriPlankNum
      let tableIndex = -1
      let rowIndex = -1
      for (let i = 0; i < currentList.length; ++i) {
        for (let k = 0; k < currentList[i].parts.length; ++k) {
          let part = currentList[i].parts[k]
          part.isEditing = false
          if (mark === part.ggid + part.oriPlankNum) {
            tableIndex = i
            rowIndex = k
          }
        }
      }

      this.$nextTick(() => {
        if (tableIndex != -1 && rowIndex != -1) {
          if (this.$refs[`plankRow_${tableIndex}_${rowIndex}`]) {
            currentList[tableIndex].parts[rowIndex].isEditing = true
            this.currentSelectPart = currentList[tableIndex].parts[rowIndex]
            this.$refs[`plankRow_${tableIndex}_${rowIndex}`][0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            })
          }
        }
      })
    },
    viewPaiban() {
      buryPointApi('material', 'view_layout')
      this.$router.push('/newPaiban')
    },
    // 打开本地文件
    openLocalFile() {
      this.showAddBaseMModal = false
      this.isShowPlateDetails = false
      this.setShowSurplusList(true)
      this.isSelectedAllSurplus = true
      this.surplusPage = 1
      this.isAllSurplusTrue = true
      this.visible = false
      this.setRecodeSelectPart({ selects: [], isHistory: true })
      this.setRecodeSelectSurplus({ selects: [], isHistory: true })
      this.$nextTick(() => {
        this.$refs.fileUpload.dispatchEvent(new MouseEvent('click'))
      })
    },
    // 读取本地文件数据
    getFileData(e) {
      try {
        // 如果导入了新的数据，接任务状态更新失效
        this.setIsNewPaiban(false)
        // 文件导入时清空所有筛选相关
        this.setBeforeSearchMaterialList(null)
        this.setBeforeSizeFilterList(null)
        this.setFilterMaterialList(null)
        this.setFilterObject(null)
        this.lastFilterProp = ''
        this.filterObj = {}
        this.lastFilterProps = []
        this.filterProps = []
        this.filterProp = ''
        this.minSize = ''
        this.maxSize = ''
        this.plankKeyWord = ''
        if (e.target.files.length == 0) return
        let file = e.target.files[0]
        let fileType = file.name.split('.').pop()
        let files = { 0: file }
        // FileReader对象允许Web应用程序异步读取存储在用户计算机上的文件（或原始数据缓冲区）的内容，使用File或Blob对象指定要读取的文件或数据，
        // 其中File对象可以是来自用户一个<input>元素上选择文件后返回的FileList对象，也可以来自拖放操作生成的DataTransfer对象，还可以是来自一个在
        // HTMLCanvasElement上执行mozGetAsFile()方法后返回结果
        let fileReader = new FileReader()
        this.setHasPrintTag(false)
        this.changeHistoryStatus(false)
        this.setIsShowSample(true)
        if (this.isLinkLogin) {
          this.setIsShowSample(false)
        }
        if (fileType == 'xls' || fileType == 'xlsx') {
          this.setIsLocalImport(true)
          fileReader.readAsBinaryString(files[0])
          fileReader.onload = (e) => {
            try {
              let data = e.target.result
              let workbook = XLSX.read(data, { type: 'binary' })
              let wsname = workbook.SheetNames[0]
              let ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])
              ws = dealExcelManifest(ws, wsname, this)
              const keyMaps = {
                纹: '纹理',
                量: '数量',
                材: '材质',
              }
              const necessaryKeysList = Object.keys(keyMaps)
              const newWS = ws.map((item) => {
                for (const key in item) {
                  const cutIdx = necessaryKeysList.findIndex((it) =>
                    key.includes(it)
                  )
                  if (
                    cutIdx !== -1 &&
                    keyMaps[necessaryKeysList[cutIdx]] !== key
                  ) {
                    item = {
                      ...item,
                      [keyMaps[necessaryKeysList[cutIdx]]]: item[key],
                    }
                    Reflect.deleteProperty(item, key)
                  }
                }
                return item
              })
              this.dealExcelData(newWS, 'xls')
              // 导入数据时, 删除排版相关数据
              this.clearPaibanData()
              this.setIsTrialProduct(false)
              this.setHasSendToTask(false)
              if (this.isLoadMoreTable) {
                this.$store.commit('awaitPaibanStore/clearInMaterialPageIds')
              }
            } catch (err) {
              this.$message.error({
                message: '解析文件内容失败，请确认文件内容!',
                center: true,
              })
              return false
            }
          }
          this.$nextTick(() => {
            this.$refs.fileUpload.value = ''
          })
        } else if (fileType == 'csv') {
          // 导入csv文件
          this.setIsLocalImport(true)
          // readAsText 方法可以将Blob或者File对象根据特殊的编码格式转化为内容（字符串形式）
          // readAsText 方法是异步的，只有当执行完成后才能够查看到结果，如果直接查看是无结果的，并返回undefined，必须要挂载实例下的onload
          // 或onloadend的方法处理转化后的结果
          fileReader.readAsText(files[0], 'GBK')
          fileReader.onload = (e) => {
            try {
              if (e.target.result == '' || e.target.result == '\r\n') {
                this.$message({
                  type: 'warning',
                  message:
                    '<span id="material_nodata_warning">没有查询到数据!</span>',
                  dangerouslyUseHTMLString: true,
                })
                return
              }
              this.dealExcelData(e, 'csv')
              // 导入数据时, 删除排版相关数据
              this.clearPaibanData()
              this.setIsTrialProduct(false)
              this.setHasSendToTask(false)
              if (this.isLoadMoreTable) {
                this.$store.commit('awaitPaibanStore/clearInMaterialPageIds')
              }
            } catch (err) {
              this.$message.error({
                message: '解析文件内容失败，请确认文件内容!',
                center: true,
              })
              return false
            }
          }
          this.$nextTick(() => {
            this.$refs.fileUpload.value = ''
          })
        } else if (fileType == 'json') {
          this.setIsLocalImport(false)
          // return
          fileReader.readAsText(file)
          fileReader.onloadend = () => {
            let data = JSON.parse(fileReader.result)
            this.dealJsonData(data, this.importCount > 1)
          }
          this.setHasSendToTask(false)
          if (this.isLoadMoreTable) {
            this.$store.commit('awaitPaibanStore/clearInMaterialPageIds')
          }
        } else {
          this.setHasPrintTag(true)
          this.$message({
            type: 'error',
            message:
              '<span id="material_err_file_error">请选择正确的文件!</span>',
            dangerouslyUseHTMLString: true,
          })
        }
        sessionStorage.setItem('isImportOrder', true)
        this.isLoadMoreTable
          ? this.setImportCount(this.importCount ? this.importCount + 1 : 1)
          : this.setImportCount(1)
        buryPointApi('material', 'import_material')
      } finally {
        // 事件处理完成后需要置空选择文件，否则无法重复选择当前文件
        e.target.value = ''
      }
    },
    // 处理json数据
    async dealJsonData(data, isClearData = false) {
      // 导入json数据不显示样表头
      this.setIsShowSample(false)
      const currentLine = this.proLineList.find(
        (item) => data.process_setting_id === item.id
      )
      this.$store.commit(
        'setRecodrProductionLineChoose',
        currentLine ? currentLine.name : data.process_setting_name
      )
      if (data.hasOwnProperty('layoutResult')) {
        // 导入数据时, 删除排版相关数据
        this.clearPaibanData()
        this.setIsTrialProduct(false)
        dealGuiguiJson(JSON.parse(data.layoutResult)).then((res) => {
          this.changeDataFrom('ggpc')
          this.dealData(res, this.isLoadMoreTable)
        })
      } else if (data.hasOwnProperty('layoutData')) {
        const hasPreLayoutData = this.preLayoutData && this.preLayoutData.length
        data.layoutData = dealPreLayoutDataLabelID(
          data.layoutData.map((item) => {
            return {
              ...item,
              partUniqueId:
                hasPreLayoutData && this.isLoadMoreTable
                  ? nanoid()
                  : item.partUniqueId ?? nanoid(),
              srcTexDir: item.texDir,
            }
          })
        )
        // 导入数据时, 删除排版相关数据
        this.clearPaibanData()

        this.dealData(data.layoutData, this.isLoadMoreTable)
        this.setIsTrialProduct(false)
        if (data.paibanData.length) {
          // const a = dealPaibanDataLabelID(data.paibanData)
          this.setPaibanData(dealPaibanDataLabelID(data.paibanData))
          // return
          let params = {
            setting_id: data.process_setting_id,
          }
          let res
          if (data.process_setting_id == -2) {
            store.commit('setOriLayoutSetting', data.preLayoutSetting)
            const re = await getPrelayoutSetting()
            res = { ...re, data: dealPreLayoutSetting(re.data.setting_value) }
          } else {
            res = await getProductionNcSetting(params)
          }
          if (window.sessionStorage.getItem('thinkerx_material')) {
            params.production_from = 'guimen'
          }
          if (res.status !== 1) {
            if (data.paibanData.length) {
              this.$message.error({
                message: `${res.msg.replace(/'/g, '')},${this.$t(
                  'materialPage.noArrangeTip'
                )}`,
                center: true,
              })
              this.setPaibanData([])
            }
            return
          }
          res.data && this.judgePlateKnife(res.data.plate_knife_map)
          if (this.isError) return
          if (res.status == -1) return
          let flag = this.oldFiveSixXYTip(res)
          if (flag) return
          res.data.drawPlankWidth = res.data.xyReverse
            ? this.selectStandardPlank.plankHeight
            : this.selectStandardPlank.plankWidth
          res.data.drawPlankHeight = res.data.xyReverse
            ? this.selectStandardPlank.plankWidth
            : this.selectStandardPlank.plankHeight
          let obj = Object.assign(res.data, {
            process_setting_id: data.process_setting_id,
            process_setting_name: data.process_setting_name,
          })
          this.setNcSetting(obj)
        }
      }
      if (isClearData) {
        this.clearPaibanData()
      }

      if (this.orderInfo.hasOwnProperty('order_address')) {
        // 不明意思的订单数据处理，导致订单地址出错，注释置空语句
        // // 暂时处理云排版后，再次导入json,订单号为改变的问题
        // this.orderInfo.order_address = ''
        // 排版批次处理：如果以前的订单需要生成排版批次，导入了一个不需要生成排版批次的，则不需要生成
        // 以前的订单不需要生成排版批次的话，就都生成，即这里也不需要更新batch
        if (!this.orderInfo.batch) {
          // 第一次导入或者以前的订单 batch 为false
          // 初次加载
          if (this.isFirstLoadJson) {
            this.isFirstLoadJson = false
            this.setOrderInfo(
              Object.assign(this.orderInfo, {
                batch: data.paibanInfo.batch,
              })
            )
          } else {
            this.setOrderInfo(this.orderInfo)
          }
        } else {
          // 以前的订单batch 为 true， 或者是柜柜跳转过来的
          this.isFirstLoadJson = false
          this.setOrderInfo(
            Object.assign(this.orderInfo, {
              batch: data.paibanInfo.batch,
            })
          )
        }
      }
      this.$nextTick(() => {
        this.$refs.fileUpload.value = ''
      })
    },
    // 处理excel表格数据
    dealExcelData(data, type) {
      if (type == 'csv') {
        // 将纯文本数据通过回车换行符进行拆分

        let temp = []
        if (data.target.result.includes('\r\n')) {
          temp = data.target.result.split(/\r\n/)
        } else if (data.target.result.includes('\n')) {
          temp = data.target.result.split(/\n/)
        }
        let sData = clearObjAttr(this.samPleFormData)
        // 表头数据有额外数据需要处理
        let isSpecialHead = temp[0] && temp[0].split(',').filter((v) => v)
        isSpecialHead.color = '#E7E7E7'
        isSpecialHead = isSpecialHead.length
          ? isSpecialHead.every((v) => sampleHead.includes(v))
          : false
        let orderNo = ''
        let orderAddress = ''
        let orderRoomName = ''
        let customer = ''
        let remarks = ''
        let originalData = {
          address: '',
          clientData: '',
          factoryOrderNum: '',
          orderRemark: '',
          roomName: '',
        }
        if (isSpecialHead) {
          const sample = temp[1].split(',').map((it) => it.replace(/&/g, ','))
          const template = JSON.parse(
            JSON.stringify({ ...this.samPleFormData, ...originalData } ?? {})
          )
          Object.keys(template).forEach((key, idx) => {
            template[key] = sample[idx] ?? ''
          })
          sData = template
          temp = temp.slice(2)
          orderNo = sample[2]
          orderAddress = sample[0]
          orderRoomName = sample[3]
          customer = sample[1]
          remarks = sample[4]
        } else {
          // 导入表格没有表头两行时，不显示样表头
          this.setIsShowSample(false)
        }
        this.headerItemAddress = sData
        // 筛选分隔之后的空白数据
        let allData = temp
          .filter((v) => v)
          .map((data) => {
            // 去掉数据中的引号和制表符
            return data.replace(/"/g, '').replace(/\t/g, '')
          })
        let arr = []
        // 去除回车符号(直接用console打印单条数据没有这个回车符号, 但是打印整体就有, 没有找到原因, 但是为了保险, 就还是去掉了一下这个回车符号)
        // 获取csv表格的表头
        let keys = allData[0].split(',')
        // keys是开料清单的表格的表头
        keys.forEach((item, index) => {
          if (item === '名称') {
            keys[index] = '板件名称'
          } else if (item === '长度') {
            keys[index] = '成品长度'
            keys.push('厚度')
          } else if (item === '宽度') {
            keys[index] = '成品宽度'
          } else if (item === '封边信息') {
            keys.push('前封边')
            keys.push('后封边')
            keys.push('左封边')
            keys.push('右封边')
          }
        })
        let existTableHeaderList = keys.filter((item) => {
          // 导入表格不可缺少的表头
          return this.indispensableHeaderDataList.includes(item)
        })
        let lackTableHeaderList = this.getArrDifference(
          existTableHeaderList,
          this.indispensableHeaderDataList
        )
        if (lackTableHeaderList.length !== 0) {
          lackTableHeaderList.forEach((item, index) => {
            if (index !== lackTableHeaderList.length - 1) {
              this.lackTableString += `"${item}、"`
            } else {
              this.lackTableString += `"${item}"`
            }
          })
          this.showTableDialog = true
          this.isShowLackNeedTableHeader = true
          this.isShowLackNeedTableHeaderData = false
          return
        }

        let resList = []
        keys.forEach((item) => {
          let dataList = allTableHeaderList.filter((value) => {
            return value.label === item
          })

          resList = resList.concat(dataList)
        })
        resList.unshift(this.choose)

        // 删除表头
        allData.splice(0, 1)
        // 处理csv表格内容, 将表头和内容一一对应
        for (let i = 0; i < allData.length; i++) {
          let plankInfo = allData[i].split(',')
          let obj = { orderNo, orderAddress, orderRoomName, customer, remarks }
          for (let j = 0; j < plankInfo.length; j++) {
            obj[keys[j]] = plankInfo[j]
          }
          arr.push(obj)
        }
        data = arr
      }
      //检测导入数据每一行的必要表头的数据是否缺少
      data.forEach((item, index) => {
        let excessiveList = []
        let incorrectFormatList = []
        this.indispensableHeaderDataList.forEach((value) => {
          if (!item[value]) {
            this.showMIncorrectFormat = true
            incorrectFormatList.push(value)
          }
          if (item[value] && String(item[value]).trim() === '') {
            this.showTableDialog = true
            this.isShowLackNeedTableHeaderData = true
            this.isShowLackNeedTableHeader = false
            excessiveList.push(value)
          } else if (
            value.includes('厚') ||
            value.includes('长') ||
            value.includes('宽') ||
            value.includes('量')
          ) {
            if (!this.isNumericPositive(item[value]) && item[value]) {
              this.showMIncorrectFormat = true
              incorrectFormatList.push(value)
            }
          } else if (value.includes('纹') && item[value]) {
            if (
              !['横纹', '竖纹', '无纹理'].includes(String(item[value]).trim())
            ) {
              this.showMIncorrectFormat = true
              incorrectFormatList.push(value)
            }
          }
        })
        if (excessiveList.length !== 0) {
          this.necessaryTableLackString[index] = excessiveList
        }
        if (incorrectFormatList.length !== 0) {
          this.necessaryTableIncorrectFormat[index] = incorrectFormatList
        }
      })

      if (this.showTableDialog || this.showMIncorrectFormat) {
        return
      } else {
        this.samPleFormData = this.headerItemAddress
      }

      if (data.length == 0) {
        this.$message({
          type: 'warning',
          message: `<span id="material_nodata_worning">${this.$t(
            'materialPage.noCheckData'
          )}</span>`,
          dangerouslyUseHTMLString: true,
        })
        return
      }
      let plankArr = []
      for (let i = 0; i < data.length; ++i) {
        let plank = data[i]
        // 封边keys 封边 -> 左右为标准 -> 前：下 后：上 -> 结果取左下右上的顺序
        const edgeSort = new Map([
          ['左封边', 1],
          ['后封边', 2],
          ['下封边', 2],
          ['右封边', 3],
          ['前封边', 4],
          ['上封边', 4],
        ])
        let edgeKeys = Object.keys(plank)
          .filter((e) => e.includes('封边'))
          .sort((a, b) => edgeSort.get(a) - edgeSort.get(b))

        if (plank[this.$t('common.texture')] === this.$t('common.reverse')) {
          ;[plank[this.$t('common.long')], plank[this.$t('common.width')]] = [
            plank[this.$t('common.width')],
            plank[this.$t('common.long')],
          ]
        }
        let newPlank = {}
        let material =
          plank[this.$t('common.matCode')] ?? plank[this.$t('common.matCode2')]
        // let index = material.indexOf('_')
        // // 去除首个下划线
        // if (index !== -1) {
        //   material =
        //     material?.substring(0, index) + material?.substring(index + 1)
        // }
        const thick = material ? material.match(/^\d+(\.\d+)?/) ?? [] : []

        newPlank.thick = thick[0] ?? 0
        newPlank.matCode = material ? material.replace(newPlank.thick, '') : ''
        let edgeArr = []
        if (plank[this.$t('common.edge.info')]) {
          if (plank[this.$t('common.edge.info')].includes(':')) {
            edgeArr = plank[this.$t('common.edge.info')]
              .split(':')[1]
              .split(/前|后|左|右|上|下/)
              .filter((v) => v && v !== ' ')
          } else if (plank[this.$t('common.edge.info')].includes(': ')) {
            edgeArr = plank[this.$t('common.edge.info')]
              .split(': ')[1]
              .split(/前|后|左|右|上|下/)
              .filter((v) => v && v !== ' ')
          }
        } else if (edgeKeys.length) {
          edgeArr = edgeKeys.map((e) => plank[e])
        }
        if (edgeArr.length < 4) {
          let n = 4 - edgeArr.length
          edgeArr.push(...new Array(n).fill('0'))
        }
        edgeArr = edgeArr.map((item) => {
          if (!item) {
            item = '0'
          }
          return item
        })
        newPlank.edgeInfo =
          edgeArr.length > 0
            ? `←${edgeArr[0]}↓${edgeArr[1]}→${edgeArr[2]}↑${edgeArr[3]}`
            : ''

        newPlank.plankID = plank[this.$t('common.plankNo')]
        newPlank.partName = plank[this.$t('common.name')]
        newPlank.loc = plank[this.$t('common.loc')]
        newPlank.customer_name =
          plank[this.$t('common.customer')] ?? plank['customer']
        newPlank.frontEdge = plank[this.$t('common.edge.front')]
        newPlank.backEdge = plank[this.$t('common.edge.back')]
        newPlank.leftEdge = plank[this.$t('common.edge.left')]
        newPlank.rightEdge = plank[this.$t('common.edge.right')]
        newPlank.plank_remarks = plank[this.$t('common.plankRemark')]
        newPlank.remark = plank['房间备注']
        newPlank.roomName =
          plank[this.$t('common.room')] ?? plank['orderRoomName']
        newPlank.remarks = plank['remarks']
        newPlank.partName =
          plank[this.$t('common.plankName')] ?? plank['工件名称']
        newPlank.orderNo = plank[this.$t('common.orderNo')] ?? plank['orderNo']
        newPlank.plankNum = plank[this.$t('common.plankNum')]
        newPlank.type =
          plank[this.$t('common.isDoorPlank')] ===
            this.$t('common.notDoorPlank') ||
          !plank[this.$t('common.isDoorPlank')]
            ? 'Plank'
            : 'SingleDoor'
        newPlank.address =
          plank[this.$t('common.orderAddress')] ?? plank['orderAddress']
        newPlank.specialShape = plank[this.$t('common.isSpecial')]
        newPlank.specHeight =
          plank[this.$t('common.finishedHeight')] ??
          plank[this.$t('common.long')] ??
          plank['长']
        newPlank.specWidth =
          plank[this.$t('common.finishedWidth')] ??
          plank[this.$t('common.width')] ??
          plank['宽']
        newPlank.thick =
          plank[this.$t('common.thick')] ??
          plank['厚'] ??
          plank['开料厚'] ??
          thick[0] ??
          0
        newPlank.oRect = {
          x: 0,
          y: 0,
          width: newPlank.specWidth ? Number(newPlank.specWidth) : 0,
          height: newPlank.specHeight ? Number(newPlank.specHeight) : 0,
        }
        newPlank.fullSize = {}
        newPlank.rect = {
          x: 0,
          y: 0,
          width: toDecimal(
            Number(newPlank.oRect.width) -
              Number(edgeArr[0]) -
              Number(edgeArr[2]),
            2
          ),
          height: toDecimal(
            Number(newPlank.oRect.height) -
              Number(edgeArr[1]) -
              Number(edgeArr[3]),
            2
          ),
        }
        newPlank.realRect = JSON.parse(JSON.stringify(newPlank.rect))
        newPlank.amount = plank[this.$t('common.count')]
        // 高光板处理
        // newPlank.is_high_gloss_plank = plank['高光板'] === '高光板'?true:false
        // 需要兼容以前的数据中没有高光板属性
        if (plank[this.$t('common.heighGlossPlank')] === undefined) {
          // 就根据材质来做初始化
          newPlank.is_high_gloss_plank = newPlank.matCode.includes('高光_')
        } else {
          newPlank.is_high_gloss_plank =
            plank[this.$t('common.heighGlossPlank')] ===
            this.$t('common.heighGlossPlank')
              ? true
              : false
        }
        switch (String(plank[this.$t('common.texture')]).trim()) {
          case this.$t('common.normal'):
            newPlank.texDir = 'normal'
            newPlank.srcTexDir = 'normal'
            break
          case this.$t('common.reverse'):
            newPlank.texDir = 'reverse'
            newPlank.srcTexDir = 'reverse'
            break
          case this.$t('common.notCare'):
            newPlank.texDir = 'notcare'
            newPlank.srcTexDir = 'notcare'
            break
          default:
            newPlank.texDir = 'notcare'
            newPlank.srcTexDir = 'notcare'
            break
        }

        ;(newPlank.hsInfo = plank[this.$t('common.holeSlotsStastic')]),
          (newPlank.texture = plank[this.$t('common.color')])
        newPlank.holes = []
        newPlank.slots = []
        newPlank.sholes = []
        newPlank.sslots = []
        newPlank.realCurve = [
          { x: 0, y: 0 },
          { x: newPlank.oRect.width, y: 0 },
          { x: newPlank.oRect.width, y: newPlank.oRect.height },
          { x: 0, y: newPlank.oRect.height },
        ]
        newPlank.name = '导入的板件'
        newPlank.matRotatable = newPlank.texDir == 'notcare' ? true : false
        plankArr.push(newPlank)
      }
      this.dealData(plankArr, this.isLoadMoreTable)
    },
    downloadCsv(isNeedDeal = true) {
      buryPointApi('material', 'download_sample')
      const translateHeaders = this.recordSaveTableHeader.map((i) => this.$t(i))
      let data = [translateHeaders]
      const texDirMap = {
        reverse: this.$t('common.reverse'),
        normal: this.$t('common.normal'),
        notcare: this.$t('common.notCare'),
      }
      console.log(translateHeaders, this.tableHeaderMappingList)
      this.filterPlankList
        .map((plank) => plank.parts)
        .flat(1)
        .forEach((part) => {
          const d = []
          translateHeaders.forEach((item) => {
            const dataList = this.tableHeaderMappingList[item]
            if (item === this.$t('common.texture')) {
              d.push(texDirMap[part[dataList]])
            } else if (
              item === this.$t('common.finishedHeight') ||
              item === this.$t('common.finishedWidth')
            ) {
              d.push(part[dataList])
            } else if (item === this.$t('common.isSpecial')) {
              d.push(
                part[dataList]
                  ? this.$t('common.isSpecial')
                  : this.$t('common.notSpecial')
              )
            } else if (item === this.$t('common.isDoorPlank')) {
              d.push(
                part[dataList] === 'Plank'
                  ? this.$t('common.notDoorPlank')
                  : this.$t('common.isDoorPlank')
              )
            } else if (item === this.$t('common.heighGlossPlank')) {
              d.push(
                part[dataList]
                  ? this.$t('common.heighGlossPlank')
                  : this.$t('common.notHeighGlossPlank')
              )
            } else {
              d.push(part[dataList])
            }
          })

          data.push(d)
        })
      data = data.map((item) => {
        return item.map((itemData) => {
          // debugger
          if (typeof itemData === 'string') {
            itemData = itemData.replaceAll('\n', '')
          }
          // 判断大于十位的字符串数字是否需要加制表符，不加的话，在excel中会被转为科学计数
          if (
            typeof itemData === 'string' &&
            !isNaN(Number(itemData)) &&
            itemData.length >= 10 &&
            isNeedDeal
          ) {
            itemData = `\t${itemData}`
          }
          return itemData
        })
      })
      console.log(data, '===')
      downloadCsvFile(data, this.orderInfo.order_address)
    },
    // 保存排版
    savePaiban() {
      this.isSavePaiBanLoading = true
      const { obj, name } = this.ossFilesParams
      _hmt.push(['_trackEvent', '保存历史', 'click'])
      buryPointApi('material', 'save_history')
      ossUploadFiles(obj, name, (url) => {
        this.saveMaterialPaiban(url, 0)
        this.isSavePaiBanLoading = false
      })
    },
    // 调用保存
    async saveMaterialPaiban(url, auto_save) {
      let plankList = null
      let normalParmas,
        process_setting_id = this.selectProLine.id,
        file_type,
        order_list
      const isElec =
        this.proLineList.find((item) => this.state.process_line_id === item.id)
          ?.data?.cuttingEquipment === 'electronicSaw'
      if (isElec) {
        normalParmas = {
          used_rate: '',
          order_codes: '',
        }
        file_type = 'paiban'
        order_list = []
      } else {
        normalParmas = {
          used_rate: '',
          order_codes: this.orderInfo.order_codes
            ? this.orderInfo.order_codes
            : '',
        }
        file_type = 'material'
        order_list = this.orderInfo.order_ids
          ? this.orderInfo.order_ids.split(',').map(Number)
          : []
      }
      const obj = {
        name: this.paibanWayName,
        plank_info: {
          plankTypeList: plankList,
          historyUrl: url,
          ...normalParmas,
          filterObj: this.filterObj,
          process_setting_name: auto_save ? this.selectProLine.name : '',
        },
        process_setting_id: auto_save ? process_setting_id : -1,
        order_list,
        file_type,
        save_node: 1,
        auto_save,
      }
      if (
        this.orderInfo.needPaibanRoomIds &&
        this.orderInfo.needPaibanRoomIds.length > 0
      ) {
        obj.room_ids = this.orderInfo.needPaibanRoomIds
      }
      try {
        const res = await saveHistory(obj)
        if (res.status == 1) {
          this.hasChangePaiban = false
          if (!obj.auto_save) {
            this.$message({
              type: 'success',
              message: `<span id="material_save_success">${this.$t(
                'materialPage.save.successTip'
              )}</span>`,
              dangerouslyUseHTMLString: true,
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
      } finally {
        this.isConfirmLoading = false
        this.showSaveDialog = false
      }
    },
    // 显示保存方案的弹窗
    showSavePaiban() {
      if (this.plankList.length == 0) {
        this.$message({
          message: `<span id="material_save_error">${this.$t(
            'materialPage.save.errTip'
          )}</span>`,
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return
      }
      this.showSaveDialog = true
    },
    // 切换订单和绘制
    changeListTile(flag) {
      this.toggleListTile = flag
      if (!flag) {
        buryPointApi('material', 'change_material_type')
      }
    },
    // 选中当前表格下的所有板件
    selectTableAllPlank(table) {
      for (let i = 0; i < table.parts.length; ++i) {
        let plank = table.parts[i]
        if (table.isAllSelect) {
          this.$set(plank, 'isSelected', true)
        } else {
          this.$set(plank, 'isSelected', false)
        }
      }
      this.recodePlankSelect()
    },
    // 选中单个板件时
    selectPlank(table) {
      let flag = true
      for (let i = 0; i < table.parts.length; ++i) {
        let plank = table.parts[i]
        if (!plank.isSelected) {
          this.$set(table, 'isAllSelect', false)
          flag = false
          break
        }
      }
      if (flag) this.$set(table, 'isAllSelect', true)
      this.recodePlankSelect()
    },
    // 循环所有板件，记录已选中板件
    recodePlankSelect() {
      let select = []
      this.filterPlankList.forEach((plank) => {
        plank.parts.forEach((part) => {
          if (part.isSelected) {
            let key = part.ggid ? part.ggid : `${part.plankNum}${part.plankID}`
            select.push(key)
          }
        })
      })
      this.setRecodeSelectPart({ selects: select, isHistory: false })
    },
    // 点击单元格变成可编辑模式
    editBox(rowIndex, props, tableIndex, plank) {
      // 如果存在输入错误的单元格, 则不允许切换编辑框
      if (this.errorInput) {
        // 如果点击的就是当前出错的单元格, 则恢复边框样式并聚焦
        if (
          this.tableIndex == tableIndex &&
          this.rowIndex == rowIndex &&
          this.recordProp == props
        ) {
          this.$refs[
            `editInput_${this.recordProp}_${this.tableIndex}_${this.rowIndex}`
          ][0].$el.style.borderColor = '#18a8c7'
          this.$refs[
            `editInput_${this.recordProp}_${this.tableIndex}_${this.rowIndex}`
          ][0].focus()
        }
        return
      }
      // 纹理, 板件号, 孔槽信息, 异形不可编辑
      let ableEditFlag =
        props == 'texDir' ||
        props == 'plankID' ||
        props == 'hsInfo' ||
        props == 'specialShape'
      if (props == 'hsInfo') {
        this.HS_show = true
        this.nowPlankDetail = JSON.parse(JSON.stringify(plank))
      }
      if (ableEditFlag) return
      this.rowIndex = rowIndex
      this.recordProp = props
      this.tableIndex = tableIndex
      this.nowInputWord = plank[props]
      for (let i = 0; i < this.plankList.length; ++i) {
        for (let k = 0; k < this.plankList[i].parts.length; ++k) {
          if (this.plankList[i].parts[k].isEditing) {
            this.plankList[i].parts[k].isEditing = false
            break
          }
        }
      }
      plank.isEditing = true
      // 刷新页面渲染
      this.$forceUpdate()
      // 自动聚焦
      this.$nextTick(() => {
        this.$refs[`editInput_${props}_${tableIndex}_${rowIndex}`][0].focus()
      })
    },
    // 修改高光板， 同步板件数据
    changePlankHigh(value, plank) {
      this.dealEditPlankData(plank, true)
      this.updateLayoutData()
      this.dealData(this.filterPlankList.map((it) => it.parts).flat(2))
      // 兼容之前的一种情况，用户在删除板件后未做其他操作，保存至历史，再从历史记录到开料清单修改高光属性，会之前被删除的板件
      this.dealFilterData()
    },
    // 修改纹理时, 修改板件数据
    changePlankTexDir(value, plank, isSoloChange = false) {
      // 当切换到横纹时, 则将板件数据旋转90度
      if (value === 'reverse' && plank.beforeTexDir !== 'reverse') {
        this.rotatePlank(plank, 1)
      }
      // 如果是从横纹切换到竖纹和无纹理, 则旋转-90度
      if (value === 'normal' && plank.beforeTexDir !== 'normal') {
        this.rotatePlank(plank, -1)
      }
      if (value === 'notcare' && plank.beforeTexDir === 'reverse') {
        this.rotatePlank(plank, 1)
      }
      if (plank.beforeTexDir === 'notcare' && value === 'normal') {
        this.rotatePlank(plank, -1)
      }
      plank.srcTexDir = plank.texDir
      plank.beforeTexDir = value
      this.dealEditPlankData(plank, true)
      // 在所有数据处理完毕后, 再重新对preLayoutData进行赋值
      if (isSoloChange) this.updateLayoutData()
    },
    rotatePlank(part, direction) {
      let rect = part['rect']
      let oRect = part['oRect']
      let startX = part['startX']
      let startY = part['startY']
      let paths = part['path']
      let oPaths = part['oPath']
      let cutCurve = part['cutCurve']
      let cutCurveEx = part['cutCurveEx']
      let realCurve = part['realCurve']
      let realCurveEx = part['realCurveEx']
      let holes = part['holes']
      let slots = part['slots']
      let handleSlopes = part['handleSlopes']
      let sholes = part['sholes']
      let sslots = part['sslots']
      let realRect = part['realRect']
      let edgeInfo = part['edgeInfo']
      let sholeInfo = part['sholeInfo']
      let sholeInfoF = part['sholeInfoF']
      let sslotInfo = part['sslotInfo']
      let sslotInfoF = part['sslotInfoF']
      let curveHoles = part['curveHoles']
      let millInfo = part['millInfo']
      let oriCurveHoles = part['oriCurveHoles']

      let o = {
        x: rect.width / 2,
        y: rect.height / 2,
      }

      let oo = {
        x: oRect.width / 2,
        y: oRect.height / 2,
      }
      // rect
      rect = {
        x: 0,
        y: 0,
        width: rect.height,
        height: rect.width,
      }

      oRect = {
        x: 0,
        y: 0,
        width: oRect.height,
        height: oRect.width,
      }

      // realRect
      realRect = {
        x: 0,
        y: 0,
        width: realRect.height,
        height: realRect.width,
      }

      // paths
      for (var i in paths) {
        var path = paths[i]
        for (var j in path) {
          var p = path[j]
          var x = p['x'] - o.x
          var y = p['y'] - o.y

          p['x'] = -direction * y + o.y
          p['y'] = direction * x + o.x
        }
      }
      if (curveHoles) {
        for (var i in curveHoles) {
          let path = curveHoles[i]['path']
          for (let j in path) {
            var p = path[j]
            var x = p['x'] - o.x
            var y = p['y'] - o.y

            p['x'] = -direction * y + o.y
            p['y'] = direction * x + o.x
          }
        }
      }
      if (millInfo) {
        for (let i in millInfo) {
          let shape = millInfo[i]['shape']
          for (let j in shape) {
            const p = shape[j]
            const x = p['x'] - oo.x
            const y = p['y'] - oo.y

            p['x'] = -direction * y + oo.y
            p['y'] = direction * x + oo.x
          }
        }
      }

      if (oriCurveHoles) {
        for (var i in oriCurveHoles) {
          let path = oriCurveHoles[i]['path']
          for (let j in path) {
            var p = path[j]
            var x = p['x'] - o.x
            var y = p['y'] - o.y

            p['x'] = -direction * y + o.y
            p['y'] = direction * x + o.x
          }
        }
      }

      // oPath
      for (var i in oPaths) {
        var path = oPaths[i]
        for (var j in path) {
          var p = path[j]
          var x = p['x'] - oo.x
          var y = p['y'] - oo.y

          p['x'] = -direction * y + oo.y
          p['y'] = direction * x + oo.x
        }
      }

      for (var i in cutCurve) {
        var p = cutCurve[i]
        var x = p['x'] - o.x
        var y = p['y'] - o.y

        p['x'] = -direction * y + o.y
        p['y'] = direction * x + o.x
      }

      for (var i in cutCurveEx) {
        path = cutCurveEx[i]
        for (var j in path) {
          var p = path[j]
          var x = p['x'] - o.x
          var y = p['y'] - o.y
          p['x'] = -y + o.y
          p['y'] = x + o.x
        }
      }

      for (var i in realCurve) {
        var p = realCurve[i]

        var x = p['x'] - oo.x
        var y = p['y'] - oo.y

        p['x'] = -direction * y + oo.y
        p['y'] = direction * x + oo.x
      }

      for (var i in realCurveEx) {
        // eslint-disable-next-line no-redeclare
        var path = realCurveEx[i]
        for (var j in path) {
          var p = path[j]

          var x = p['x'] - oo.x
          var y = p['y'] - oo.y

          p['x'] = -direction * y + oo.y
          p['y'] = direction * x + oo.x
        }
      }

      // holes

      for (var h in holes) {
        var hole = holes[h]
        var center = hole['center']
        var ocenter = hole['ocenter']

        var x = center['x'] - o.x
        var y = center['y'] - o.y
        center['x'] = -direction * y + o.y
        center['y'] = direction * x + o.x

        x = ocenter['x'] - oo.x
        y = ocenter['y'] - oo.y
        ocenter['x'] = -direction * y + oo.y
        ocenter['y'] = direction * x + oo.x
      }
      // slots
      const slotAndSlopesList = [...slots, ...(handleSlopes ?? [])]
      for (var s in slotAndSlopesList) {
        var slot = slotAndSlopesList[s]
        var pt1 = slot['pt1']
        var pt2 = slot['pt2']
        var opt1 = slot['opt1']
        var opt2 = slot['opt2']

        var x1 = pt1['x'] - o.x
        var y1 = pt1['y'] - o.y
        var x2 = pt2['x'] - o.x
        var y2 = pt2['y'] - o.y
        pt1['x'] = -direction * y1 + o.y
        pt1['y'] = direction * x1 + o.x
        pt2['x'] = -direction * y2 + o.y
        pt2['y'] = direction * x2 + o.x

        x1 = opt1['x'] - oo.x
        y1 = opt1['y'] - oo.y
        x2 = opt2['x'] - oo.x
        y2 = opt2['y'] - oo.y
        opt1['x'] = -direction * y1 + oo.y
        opt1['y'] = direction * x1 + oo.x
        opt2['x'] = -direction * y2 + oo.y
        opt2['y'] = direction * x2 + oo.x
      }

      // sholes
      for (var k in sholes) {
        var shole = sholes[k]
        var side = shole['side']
        const ocenter = shole['ocenter']

        const x = ocenter['x'] - oo.x
        const y = ocenter['y'] - oo.y
        ocenter['x'] = -direction * y + oo.y
        ocenter['y'] = direction * x + oo.x

        side = parseInt(side) - direction
        if (side <= 0) {
          side = 4
        } else if (side >= 4) {
          side = 0
        }

        shole['side'] = side
      }

      // sslots
      for (var j in sslots) {
        var sslot = sslots[j]
        var side = sslot['side']
        const opt1 = sslot['opt1']
        const opt2 = sslot['opt2']

        const x1 = opt1['x'] - oo.x
        const y1 = opt1['y'] - oo.y
        const x2 = opt2['x'] - oo.x
        const y2 = opt2['y'] - oo.y

        opt1['x'] = -direction * y1 + oo.y
        opt1['y'] = direction * x1 + oo.x
        opt2['x'] = -direction * y2 + oo.y
        opt2['y'] = direction * x2 + oo.x

        side = parseInt(side) - direction
        if (side <= 0) {
          side = 4
        } else if (side >= 4) {
          side = 0
        }

        sslot['side'] = side
      }

      sholeInfo = generatorSideHoleSlot([
        ...sholes,
        ...holes.filter((hole) => isSideMachineHole(hole) && hole.side == 1),
      ])
      sholeInfoF = generatorSideHoleSlot(
        holes.filter((hole) => isSideMachineHole(hole) && hole.side == -1),
        'upDownFlip'
      )
      sslotInfo = generatorSideHoleSlot([
        ...sslots,
        ...slots.filter((slot) => isSideMachineSlot(slot) && slot.side == 1),
      ])
      sslotInfoF = generatorSideHoleSlot(
        slots.filter((slot) => isSideMachineSlot(slot) && slot.side == -1),
        'upDownFlip'
      )

      // edgeInfo
      var left = edgeInfo.substring(
        edgeInfo.indexOf('←') + 1,
        edgeInfo.indexOf('↓')
      )
      var bottom = edgeInfo.substring(
        edgeInfo.indexOf('↓') + 1,
        edgeInfo.indexOf('→')
      )
      var right = edgeInfo.substring(
        edgeInfo.indexOf('→') + 1,
        edgeInfo.indexOf('↑')
      )
      var top = edgeInfo.substring(edgeInfo.indexOf('↑') + 1)
      let dealType
      if (direction == 1) {
        edgeInfo = '←' + bottom + '↓' + right + '→' + top + '↑' + left
        dealType = 'rotate90'
      } else {
        edgeInfo = '←' + top + '↓' + left + '→' + bottom + '↑' + right
        dealType = 'rotate90F'
      }
      part.doorOpenSide = dealFormerEdgeChange(
        part.doorOpenSide,
        dealType,
        'doorOpenSide'
      )
      part.lightEdgeInfo = dealEdgeInfoChange(part.lightEdgeInfo, dealType)
      part.model_front_edge = dealFormerEdgeChange(
        part.model_front_edge,
        dealType,
        'former'
      )
      part['rect'] = rect
      part['oRect'] = oRect
      part['startX'] = startX
      part['startY'] = startY
      if (part.path) {
        part['path'] = paths
      }
      if (part.oPath) {
        part['oPath'] = oPaths
      }
      if (part.cutCurve) {
        part['cutCurve'] = cutCurve
      }
      if (part.cutCurveEx) {
        part['cutCurveEx'] = cutCurveEx
      }
      part['realCurve'] = realCurve
      part['realCurveEx'] = realCurveEx
      part['holes'] = holes
      part['slots'] = slots
      part['sholes'] = sholes
      part['sslots'] = sslots
      part['realRect'] = realRect
      part['edgeInfo'] = edgeInfo
      part['sholeInfo'] = sholeInfo
      part['sholeInfoF'] = sholeInfoF
      part['sslotInfoF'] = sslotInfoF
      part['sslotInfo'] = sslotInfo
      part.specWidth = oRect.width
      part.specHeight = oRect.height
    },
    // 修改是否为门板
    changePlankType(value, plank, isAll) {
      if (isAll) {
        this.plankList.forEach((plank) => {
          plank.parts.forEach((part) => {
            part.type = value
          })
        })
      } else {
        plank.type = value
        if (value != this.plankDoorSelectVal) {
          this.plankDoorSelectVal = ''
        }
      }
      this.dealEditPlankData(plank, true)
      this.updateLayoutData()
    },
    // 纹理下拉框聚焦时触发, 记录修改之前的纹理
    focusPlankTexDir(plank) {
      // 记录之前的纹理
      if (!plank.beforeTexDir) {
        plank.beforeTexDir = plank.texDir
      }
      this.materialCellClick(plank, 'texDir')
      const virtualListRef = this.$refs['virtualListRef']
      virtualListRef.itemClick({
        part: plank,
        field: 'texDir',
      })
    },
    showHSInfo(part) {
      this.HS_show = true
      this.nowPlankDetail = JSON.parse(JSON.stringify(part))
    },
    materialCellClick(part, field) {
      this.nowInputWord = part[field]
    },
    // 单选框改变
    changeSelect(tableData) {
      this.selectPlank(tableData)
    },
    changeSelectAll(tableData) {
      this.selectTableAllPlank(tableData)
    },
    // 获取料单表格表头宽度
    headRowChange(width) {
      this.virtualHeadRowWidth = width
    },
    // 触发之前的修改(不改动之前的逻辑,太麻烦了)
    materialInputBlur(data) {
      const { plank, tableIndex, part, plankIndex, key } = data ?? {}
      this.inputBlur(plank, tableIndex, part, plankIndex, key)
    },
    // 输入框失焦时触发
    inputBlur(table, tableIndex, plank, plankIndex, prop) {
      // TODO
      // 如果修改的是板件的长宽或者封边, 则处理其板件数据
      if (
        prop == 'specWidth' ||
        prop == 'specHeight' ||
        prop.includes('Edge')
      ) {
        // 如果修改的类型没有做修改, 则不执行后面的操作
        if (plank[prop] == this.nowInputWord) {
          this.nowInputWord = ''
          return
        }
        // 如果是已有的板件, 则将其处理成空板
        if (!plank.isManual) {
          let newPlank = this.plankSpecialDeal(plank)
          // this.plankList[tableIndex].parts[plankIndex] = newPlank
          // this.$set(this.plankList[tableIndex].parts, plankIndex, newPlank)
          this.dealEditPlankData(newPlank)
        } else {
          const isReverse = plank.texDir == 'reverse'
          let width = isReverse ? +plank.specHeight : +plank.specWidth
          let height = isReverse ? +plank.specWidth : +plank.specHeight
          const hEdge = Number(plank.leftEdge) + Number(plank.rightEdge)
          const vEdge = Number(plank.frontEdge) + Number(plank.backEdge)
          plank.oRect = {
            x: 0,
            y: 0,
            width: width,
            height: height,
          }
          plank.rect = {
            x: 0,
            y: 0,
            width: toDecimal(width - (isReverse ? vEdge : hEdge), 2),
            height: toDecimal(height - (isReverse ? hEdge : vEdge), 2),
          }
          plank.realRect = { ...plank.rect }
          plank.realCurve = [
            { x: 0, y: 0 },
            { x: plank.oRect.width, y: 0 },
            { x: plank.oRect.width, y: plank.oRect.height },
            { x: 0, y: plank.oRect.height },
          ]
          plank.edgeInfo = `←${plank.leftEdge}↓${plank.backEdge}→${plank.rightEdge}↑${plank.frontEdge}`
        }
      }

      // Fix: 不再根据是否具有高光这两个字来判断是否是高光板
      // if (plank.matCode && !plank.matCode.includes('高光')) {
      //   plank.is_high_gloss_plank = false
      // }
      plank.amount = Number(plank.amount)
      this.dealEditPlankData(plank, true)
      this.plankList = dealMaterialList(this.plankList)

      this.$forceUpdate()
      this.fixPlankDataType(plank, prop)

      this.calcAllAreaAmount(this.plankList)
      this.updateLayoutData()
      this.dealFilterData()
    },
    // 板件修改的特殊处理
    plankSpecialDeal(plank) {
      let width = Number(plank.specWidth)
      let height = Number(plank.specHeight)
      let newPlank = {
        ...plank,
        oRect: {
          x: 0,
          y: 0,
          width: width,
          height: height,
        },
        rect: {
          x: 0,
          y: 0,
          width: toDecimal(
            width - Number(plank.leftEdge) - Number(plank.rightEdge),
            2
          ),
          height: toDecimal(
            height - Number(plank.frontEdge) - Number(plank.backEdge),
            2
          ),
        },
        realCurve: [
          { x: 0, y: 0 },
          { x: plank.oRect.width, y: 0 },
          { x: plank.oRect.width, y: plank.oRect.height },
          { x: 0, y: plank.oRect.height },
        ],
        realRect: {
          x: 0,
          y: 0,
          width: toDecimal(
            width - Number(plank.leftEdge) - Number(plank.rightEdge),
            2
          ),
          height: toDecimal(
            height - Number(plank.frontEdge) - Number(plank.backEdge),
            2
          ),
        },
        specialShape: false,
        hsInfo: this.$t('common.hsInfo', { hole: 0, slot: 0 }),
        edgeInfo: `←${plank.leftEdge}↓${plank.backEdge}→${plank.rightEdge}↑${plank.frontEdge}`,
        typeSymbol: `${plank.matCode}:${plank.thick}:${plank.texture}`,
        holes: [],
        sholes: [],
        slots: [],
        handleSlopes: [],
        sslots: [],
        fullSize: {},
      }
      // 需要删除部分需要的值
      delete newPlank.path
      delete newPlank.oPath
      delete newPlank.curveHoles
      delete newPlank.millInfo
      delete newPlank.edge_length_info
      return newPlank
    },
    // 处理输入编辑板件数据
    dealEditPlankData(newPart, isNeedPath = false) {
      this.assignPart(this.plankList, newPart, isNeedPath)
      this.assignPart(this.filterPlankList, newPart, isNeedPath)
      if (this.beforeSearchMaterialList) {
        this.assignPart(this.beforeSearchMaterialList, newPart, isNeedPath)
      }
      if (this.beforeSizeFilterList) {
        this.assignPart(this.beforeSizeFilterList, newPart, isNeedPath)
      }
    },
    assignPart(plankList, newPart, isNeedPath = false) {
      const hasEditPlankUniID = newPart.partUniqueId
      let part = null
      plankList.find((plank) => {
        part = plank.parts.find(
          (part) => part.partUniqueId === hasEditPlankUniID
        )
        if (part) return true
      })
      if (part) {
        Object.assign(part, newPart)
        part.specHeight = Number(part.specHeight)
        part.specWidth = Number(part.specWidth)
        part.srcTexDir = JSON.parse(JSON.stringify(newPart)).srcTexDir
        if (!isNeedPath) {
          delete part.oPath
          delete part.path
          delete part.curveHoles
          delete part.millInfo
          delete part.edge_length_info
        }
      }
    },
    // 按下回车键时, 改为触发失焦事件, 防止调用两次失焦事件
    updateBlur(e) {
      e.target.blur()
    },
    // 添加板件
    openDrawer() {
      buryPointApi('material', 'add_plate')
      this.visible = false
      if (this.layoutData.length == 0) {
        this.newPlankInfo.plankID = 1
        this.newPlankInfo.matCode = this.newMaterialMatCode
        this.newPlankInfo.texture =
          this.newMaterialColorList[this.newMaterialColorList.length - 1] ?? ''
        this.newPlankInfoTexture = this.newMaterialColorList
        this.showDrawer = true
        return
      }
      let arr = JSON.parse(JSON.stringify(this.layoutData))
      arr.sort(compare('plankID', 'up'))

      let lastPlank = arr.pop()
      this.newPlankInfo.plankID = Number(lastPlank.plankID) + 1
      // 材质颜色数据进行回显
      this.newPlankInfo.matCode = this.newMaterialMatCode
      this.newPlankInfo.texture =
        this.newMaterialColorList[this.newMaterialColorList.length - 1] ?? ''
      this.newPlankInfoTexture = this.newMaterialColorList
      this.showDrawer = true
    },
    // 显示删除确认框
    changeShowMsgDialog() {
      this.selectionPlankList = this.filterPlankList
        .map((plank) => plank.parts)
        .flat(1)
        .filter((v) => v.isSelected)
      if (this.selectionPlankList.length == 0) {
        this.$message({
          message: `<span id="material_delete_info">${this.$t(
            'materialPage.delete.errTip'
          )}</span>`,
          type: 'info',
          dangerouslyUseHTMLString: true,
        })
        return
      } else {
        this.showMsgDialog = true
      }
    },
    // 孔槽图导出
    async exportHoleSlot(typeTow) {
      if (!this.preLayoutData.length)
        return this.$message({
          type: 'error',
          message: `<span id="material_export_error">${this.$t(
            'materialPage.exportErr'
          )}</span>`,
          center: true,
          dangerouslyUseHTMLString: true,
        })
      if (typeTow) {
        this.isExportHoleSlotPdfOther = true
      } else {
        this.isExportHoleSlotPdf = true
      }
      // 加载字体文件
      await jsPdfTool.loadFont('msyh')
      const drawInstance = new GenerateHoleSlotPdf('landscape')
      try {
        this.filterPlankList
          .map((plank) => plank.parts)
          .flat(1)
          .forEach(async (part, idx) => {
            drawInstance.generate(part, typeTow)
            drawInstance.pdf.addPage()
            // 在此处执行的原因(不想用切片(懒))
            if (
              idx ===
              this.filterPlankList.map((plank) => plank.parts).flat(1).length -
                1
            ) {
              // 删除多余页数
              drawInstance.pdf.deletePage(drawInstance.pdf.getNumberOfPages())
              drawInstance.save(
                this.orderInfo.order_address
                  ? this.orderInfo.order_address
                  : new Date().toLocaleString()
              )
              this.isExportHoleSlotPdf = false
              this.isExportHoleSlotPdfOther = false
            }
          })
      } catch (error) {
        this.isExportHoleSlotPdf = false
        this.isExportHoleSlotPdfOther = false
        this.$message({
          type: 'error',
          message: `<span id="material_export_pic_error">${this.$t(
            'materialPage.exportPicErr'
          )}</span>`,
          center: true,
          dangerouslyUseHTMLString: true,
        })
      }
    },
    // 删除板件
    deleteRow() {
      buryPointApi('material', 'delete_plate')
      this.deleteMaterialSelectPlank()
      this.setBeforeSearchMaterialList(this.filterPlankList)
      this.setBeforeSizeFilterList(this.filterPlankList)
      const plankListJson = JSON.stringify(this.filterPlankList)
      this.$message({
        message: `<span id="material_delete_success">${this.$t(
          'common.deleteSuccess'
        )}!</span><div id="material_plankList_span" style="width:0px;height:0px;overflow:hidden">${plankListJson}</div>`,
        type: 'success',
        dangerouslyUseHTMLString: true,
        duration: 1500,
      })
      this.showMsgDialog = false
    },
    deleteMaterialSelectPlank() {
      const deletePlankID = this.filterPlankList
        .map((plank) => plank.parts)
        .flat(1)
        .filter((part) => part.isSelected)
        .map((part) => part.partUniqueId)
      for (let i = this.filterPlankList.length - 1; i >= 0; --i) {
        for (let k = this.filterPlankList[i].parts.length - 1; k >= 0; --k) {
          let plank = this.filterPlankList[i].parts[k]
          if (plank.isSelected) {
            this.filterPlankList[i].parts.splice(k, 1)
          }
        }
        if (this.filterPlankList[i].parts.length == 0) {
          this.filterPlankList.splice(i, 1)
        }
      }
      for (let i = this.plankList.length - 1; i >= 0; --i) {
        for (let k = this.plankList[i].parts.length - 1; k >= 0; --k) {
          let plank = this.plankList[i].parts[k]
          if (deletePlankID.includes(plank.partUniqueId)) {
            this.plankList[i].parts.splice(k, 1)
          }
        }
        if (this.plankList[i].parts.length == 0) {
          this.plankList.splice(i, 1)
        }
      }

      this.setBeforeSearchMaterialList(this.filterPlankList)
      this.setBeforeSizeFilterList(this.filterPlankList)
      const plankListJson = JSON.stringify(this.filterPlankList)
      this.$message({
        message: `<span id="material_delete_success">删除成功!</span><div id="material_plankList_span" style="width:0px;height:0px;overflow:hidden">${plankListJson}</div>`,
        type: 'success',
        dangerouslyUseHTMLString: true,
        duration: 1500,
      })
      this.showMsgDialog = false
      this.updateLayoutData()
    },
    // 关闭右侧抽屉
    closeDrawer() {
      this.showDrawer = false
    },
    // 清空添加板件中所有的输入框
    clearNewPlankInfo() {
      this.newPlankInfoTexture = ''
      for (let key in this.newPlankInfo) {
        if (key === 'amount') {
          this.newPlankInfo[key] = 1
        } else if (key == 'texDir') {
          this.newPlankInfo[key] = 'normal'
        } else {
          this.newPlankInfo[key] = ''
        }
      }
    },
    // 添加板件后清空输入框继续添加下一块板件
    addNewRowContinue() {
      let ableAdd = this.checkNewRowInput()
      if (!ableAdd) return

      this.addNewRow(true)
      this.clearNewPlankInfo()
      let arr = JSON.parse(JSON.stringify(this.layoutData))
      arr.sort(compare('plankID', 'up'))
      let lastPlank = arr.pop()
      this.newPlankInfo.plankID = Number(lastPlank.plankID) + 1
    },
    // 添加板件并关闭抽屉
    addNewRow(needCloseDrawer) {
      let ableAdd = this.checkNewRowInput()
      if (!ableAdd) return

      if (!needCloseDrawer) {
        this.showDrawer = false
      }

      let newPlank = JSON.parse(JSON.stringify(this.newPlankInfo))
      const numberPropList = [
        'thick',
        'specHeight',
        'specWidth',
        'frontEdge',
        'backEdge',
        'leftEdge',
        'rightEdge',
      ]
      numberPropList.forEach((prop) => {
        newPlank[prop] = Number(newPlank[prop])
      })
      newPlank.isManual = true
      newPlank.oRect = {
        x: 0,
        y: 0,
        width: Number(newPlank.specWidth),
        height: Number(newPlank.specHeight),
      }
      newPlank.fullSize = {}
      newPlank.rect = {
        x: 0,
        y: 0,
        width: toDecimal(
          Number(newPlank.specWidth) -
            Number(newPlank.leftEdge) -
            Number(newPlank.rightEdge),
          2
        ),
        height: toDecimal(
          Number(newPlank.specHeight) -
            Number(newPlank.frontEdge) -
            Number(newPlank.backEdge),
          2
        ),
      }
      newPlank.realRect = {
        x: 0,
        y: 0,
        width: toDecimal(
          Number(newPlank.specWidth) -
            Number(newPlank.leftEdge) -
            Number(newPlank.rightEdge),
          2
        ),
        height: toDecimal(
          Number(newPlank.specHeight) -
            Number(newPlank.frontEdge) -
            Number(newPlank.backEdge),
          2
        ),
      }
      newPlank.realCurve = [
        { x: 0, y: 0 },
        { x: Number(newPlank.oRect.width), y: 0 },
        { x: Number(newPlank.oRect.width), y: Number(newPlank.oRect.height) },
        { x: 0, y: Number(newPlank.oRect.height) },
      ]
      newPlank.typeSymbol = `${newPlank.matCode}:${newPlank.thick}:${newPlank.texture}`
      newPlank.isSelected = true
      newPlank.hsInfo = '0孔0槽'
      newPlank.specialShape = false
      newPlank.holes = []
      newPlank.sholes = []
      newPlank.slots = []
      newPlank.sslots = []
      newPlank.leftEdge = newPlank.leftEdge == '' ? 0 : newPlank.leftEdge
      newPlank.rightEdge = newPlank.rightEdge == '' ? 0 : newPlank.rightEdge
      newPlank.frontEdge = newPlank.frontEdge == '' ? 0 : newPlank.frontEdge
      newPlank.backEdge = newPlank.backEdge == '' ? 0 : newPlank.backEdge
      newPlank.edgeInfo = `←${newPlank.leftEdge}↓${newPlank.backEdge}→${newPlank.rightEdge}↑${newPlank.frontEdge}`
      newPlank.realCurveEx = []
      newPlank.matRotatable = newPlank.texDir == 'notcare' ? true : false
      newPlank.address = '手动添加的板件'
      newPlank.name = '手动添加的板件'
      const plankNum = uniquePlankNum.createUniquePlankNum()
      newPlank.plankNum = plankNum
      newPlank.oriPlankNum = plankNum
      newPlank.partUniqueId = nanoid()
      newPlank.type = 'Plank'
      generateSimplePlankNum(newPlank)
      // 添加至表格
      let flag = true

      for (let i = 0; i < this.plankList.length; ++i) {
        if (this.plankList[i].symbol == newPlank.typeSymbol) {
          this.plankList[i].parts.push(newPlank)
          flag = false
          break
        }
      }
      this.setBeforeSearchMaterialList(this.plankList)
      if (flag) {
        this.plankList.push({
          matCode: newPlank.matCode,
          thick: newPlank.thick,
          texture: newPlank.texture,
          symbol: newPlank.typeSymbol,
          parts: [newPlank],
          isUnfold: true,
          isAllSelect: true,
          totalAmount: Number(newPlank.amount),
          totalArea: toDecimal(
            (Number(newPlank.amount) *
              Number(newPlank.specWidth) *
              Number(newPlank.specHeight)) /
              1000000,
            2
          ),
        })
      }
      this.dealFilterData()
      this.fixPlankDataType(newPlank, this.lastFilterProp)
      this.$message({
        message: `<span id="material_save_success">${this.$t(
          'common.addSuccess'
        )}!</span>`,
        type: 'success',
        dangerouslyUseHTMLString: true,
      })
      this.updateLayoutData()
      // 清空收集的plankNum
      uniquePlankNum.plankNumCollect.clear()
    },
    // 添加板件时判断是否有必填项没有填
    checkNewRowInput() {
      if (this.newPlankInfo.matCode == '') {
        this.$message({
          message: '<span id="material_check_error">材质不能为空!</span>',
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return false
      }
      if (this.newPlankInfo.thick == '') {
        this.$message({
          message: '<span id="material_check_error">厚度不能为空!</span>',
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return false
      }
      if (
        this.newPlankInfo.texture == '' ||
        this.newPlankInfo.texture.includes('_catalog')
      ) {
        this.$message({
          message: '<span id="material_check_error">颜色不能为空!</span>',
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return false
      }
      if (this.newPlankInfo.specHeight == '') {
        this.$message({
          message: '<span id="material_check_error">成品长度不能为空!</span>',
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return false
      }
      if (this.newPlankInfo.specWidth == '') {
        this.$message({
          message: '<span id="material_check_error">成品宽度不能为空!</span>',
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return false
      }
      if (this.newPlankInfo.amount == '') {
        this.$message({
          message: '<span id="material_check_error">数量至少为1!</span>',
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return false
      }
      if (
        !this.newPlankInfo.is_high_gloss_plank &&
        this.newPlankInfo.is_high_gloss_plank !== false
      ) {
        this.$message({
          message: '<span id="material_check_error">请选择高光板属性</span>',
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return false
      }
      return true
    },
    // 同步预处理数据
    updateLayoutData() {
      this.layoutData = this.plankList
        .map((item) => {
          return item.parts
        })
        .flat(1)
      this.setPreLayoutData(this.layoutData)
    },
    // 选中所有表格的所有板件
    selectAllTable() {
      for (let i = 0; i < this.plankList.length; ++i) {
        this.$set(
          this.plankList[i],
          'isAllSelect',
          this.isSelectedAllPlank ? true : false
        )
        for (let k = 0; k < this.plankList[i].parts.length; ++k) {
          let plank = this.plankList[i].parts[k]
          this.$set(plank, 'isSelected', this.isSelectedAllPlank ? true : false)
        }
      }
      for (let i = 0; i < this.filterPlankList.length; ++i) {
        this.$set(
          this.filterPlankList[i],
          'isAllSelect',
          this.isSelectedAllPlank ? true : false
        )
        for (let k = 0; k < this.filterPlankList[i].parts.length; ++k) {
          let plank = this.filterPlankList[i].parts[k]
          this.$set(plank, 'isSelected', this.isSelectedAllPlank ? true : false)
        }
      }
    },
    handleTrailState(orderInfo) {
      this.$getByToken(
        '/production/trial_room',
        { is_get_layout_data: 0 },
        (res) => {
          if (res.status === 1) {
            if (
              res.data.state === 1 &&
              orderInfo.room_ids.includes(res.data.trial_room_id)
            ) {
              this.$antdConfirm({
                title: '温馨提示',
                content:
                  '当前试生产订单已调整为使用当前账号的工艺设置数据，请注意您的工艺设置！',
                okText: '确定',
                class: 'trail-tech-modal',
                centered: true,
              })
            }
            if (orderInfo.room_ids.includes(res.data.trial_room_id)) {
              this.setIsTrialProduct(true)
            }
          }
        }
      )
    },
    // 处理柜柜跳转进来的参数
    dealDataFromGuigui() {
      if (!window.localStorage.getItem('debugger')) {
        window.history.replaceState('', '', '#' + this.$route.path)
      }
      let id =
        this.$route.query.data_id ||
        JSON.parse(window.sessionStorage.getItem('guiguiJumpEquipmentParams'))
          ?.data_id
      let order_ids = []
      let order_codes = []
      let room_ids = []
      let needPaibanRoomIds = []
      new Promise((resove) => {
        this.$getByToken(
          '/get_material_trans_data',
          {
            id: id,
          },
          (res) => {
            if (res.status == 1) {
              if (Object.keys(res.data).length > 0) {
                let list = JSON.parse(res.data)
                let data = list.filter((item) => {
                  return !item.hasOwnProperty('exportType')
                })
                // 补件数据
                let bujianList = list.filter((item) => {
                  return item.hasOwnProperty('exportType')
                })
                // 记录订单的信息
                // let order_name = []
                for (let i = 0; i < data.length; ++i) {
                  order_ids = [...order_ids, data[i].projectInfo.id]
                  order_codes = [...order_codes, data[i].projectInfo.order_code]
                  let roomIds = data[i].projectInfo.room_ids
                    .split(',')
                    .map(Number)
                  room_ids = [...room_ids, ...roomIds]
                  if (data[i].hasOwnProperty('roomIds')) {
                    needPaibanRoomIds = [
                      ...needPaibanRoomIds,
                      ...data[i].roomIds,
                    ]
                  }
                }

                let orderInfo = {
                  order_ids: order_ids.join(','),
                  order_codes: order_codes.join(','),
                  needPaibanRoomIds: needPaibanRoomIds,
                  room_ids: room_ids,
                }
                if (bujianList.length) {
                  orderInfo.bujianPlankId = {}
                  let bjPlankMock = bujianList[0].complementOrders
                  // 记录需要补件的房间id
                  orderInfo.needBujianRoomId = []
                  // 获取预处理数据需要传新的render_json
                  orderInfo.addition_info = []
                  bjPlankMock.forEach((item) => {
                    // orderInfo.room_ids = orderInfo.room_ids.concat(item.room_ids.split(','))
                    orderInfo.order_ids += ',' + item.oid
                    orderInfo.order_codes += ',' + item.order_code
                    // orderInfo.bujianOrderNo.push(item.order_code)
                    item.plates.forEach((e) => {
                      if (orderInfo.needBujianRoomId.indexOf(e.rid) == -1) {
                        orderInfo.needBujianRoomId.push(e.rid)
                      }
                      let addition_info = {}
                      addition_info.room_id = e.rid
                      addition_info.render_url = e.render_url
                      orderInfo.addition_info.push(addition_info)
                      let palnkId_ggid = {
                        plankId: e.plankId,
                        ggid: e.ggid,
                      }
                      if (orderInfo.bujianPlankId[e.rid]) {
                        orderInfo.bujianPlankId[e.rid].push(palnkId_ggid)
                      } else {
                        orderInfo.bujianPlankId[e.rid] = []
                        orderInfo.bujianPlankId[e.rid].push(palnkId_ggid)
                      }
                    })
                  })
                }
                this.handleTrailState(orderInfo)
                resove(orderInfo)
              }
            } else {
              this.$message({
                type: 'error',
                message: `<span id="material_plank_error">${this.$t(
                  'materialPage.plankNotFound'
                )}</span>`,
                dangerouslyUseHTMLString: true,
              })
            }
          }
        )
      }).then((orderInfo) => {
        this.setOrderInfo(orderInfo)
        this.getLayoutData()
      })
      if (window.webToQt) {
        buryPointApi('guigui', 'enter_bluen_embed')
      }
      buryPointApi('guigui', 'enter_bluen_web')
    },
    handleggid(ggid) {
      let finalGgid = ggid.split('/').pop()
      if (String(finalGgid).startsWith('÷')) finalGgid = finalGgid.substr(1)

      if (String(finalGgid).length > 8) {
        finalGgid = String(finalGgid).slice(0, -2)
      }
      return finalGgid
    },
    // 通过柜柜跳转过来获取订单信息后, 通过订单信息获取预处理之后的板件数据
    getLayoutData() {
      this.emptyShow = true
      this.setIsShowSample(false)
      this.setIsLinkLogin(true)
      this.$token(
        '/get_layout_data',
        {
          room_ids:
            this.orderInfo.needPaibanRoomIds.length == 0
              ? this.orderInfo.room_ids
              : this.orderInfo.needPaibanRoomIds,
          addition_info: this.orderInfo.addition_info,
        },
        (res) => {
          this.isTableEmpty = false
          if (res.status == 1) {
            let arr = []
            let bujanArr = res.data.addition_data
            for (let i = 0; i < res.data.result_data.length; i++) {
              arr = [
                ...arr,
                ...res.data.result_data[i].map((part) => ({
                  ...part,
                  srcTexDir: part.texDir,
                })),
              ]
            }
            // 找出需要补件的小板
            if (bujanArr && bujanArr.length) {
              arr = arr.concat(this.handleBujianPlanks(bujanArr))
            }
            let addr = []
            for (let i = 0; i < arr.length; ++i) {
              let code_addr =
                window.sessionStorage.getItem('thinkerx_material') ||
                arr[i].groupNo
                  ? `${arr[i].groupNo}`
                  : `${arr[i].orderNo}-${arr[i].address}`
              if (addr.length == 0) {
                addr.push(code_addr)
              } else {
                if (!addr.includes(code_addr)) {
                  addr.push(code_addr)
                }
              }
            }
            let newOrderInfo = Object.assign(this.orderInfo, {
              order_address: addr.join(','),
              batch: res.data.batch,
            })
            this.setOrderInfo(newOrderInfo)
            // 记录当前开料清单中所有订单的订单id和订单号, 以便排版接口和保存接口调用时使用
            this.setPreLayoutParam({
              type: 'roomId',
              data:
                this.orderInfo.needPaibanRoomIds.length == 0
                  ? this.orderInfo.room_ids
                  : this.orderInfo.needPaibanRoomIds,
              orderInfo: this.orderInfo,
            })
            this.dealData(arr)
            const ggOrderIds = JSON.parse(
              JSON.stringify(this.preLayoutData)
            ).map((item) => item.orderId)
            localStorage.setItem('ggOrderIds', JSON.stringify(ggOrderIds))
            this.emptyShow = false
          } else {
            this.emptyShow = false
            modalMsg(
              res.msg,
              'warning',
              this.$t('common.tip'),
              null,
              {},
              'getLayoutData'
            )
          }
        }
      )
    },
    // 筛选出补件的板件
    handleBujianPlanks(bujanArr) {
      // 记录板件数量，因为有相同ggid重复补件
      let rec = {}
      let arr = []
      for (let i = 0; i < bujanArr.length; i++) {
        for (let j in this.orderInfo.bujianPlankId) {
          if (bujanArr[i][0].roomID == j) {
            let plankarr = bujanArr[i].filter((e) => {
              return this.orderInfo.bujianPlankId[j].filter((item) => {
                if (
                  e.plankID == item.plankId &&
                  this.handleggid(e.ggid) == this.handleggid(item.ggid)
                ) {
                  if (rec.hasOwnProperty(item.ggid)) {
                    rec[item.ggid] += 1
                  } else {
                    rec[item.ggid] = 1
                  }
                  return true
                }
              }).length
            })
            let newPlankarr = plankarr.map((item) => {
              // 增加补件标注
              return {
                ...item,
                _isBujian: true,
              }
            })
            arr = arr.concat(newPlankarr)
          }
        }
      }
      arr = arr.map((item) => {
        let obj = {
          amount: rec[this.handleggid(item.ggid)],
          ...item,
        }
        return obj
      })
      return arr
    },
    // 处理老板良跳转进来的参数
    dealDataFromLbl() {
      this.emptyShow = true
      let oids = this.$route.query.oids
      let order_ids = oids.split(',').map(Number)
      let codes = this.$route.query.codes
      let order_codes = codes

      let orderInfo = {
        order_ids: oids,
        order_codes: order_codes,
        needPaibanRoomIds: [],
        room_ids: [],
      }
      this.isFromLbl = true
      buryPointApi('lbl', 'optimize_layout')
      this.$token(
        '/get_layout_data',
        {
          order_ids: order_ids,
        },
        (res) => {
          this.isTableEmpty = false
          if (res.status == 1) {
            let arr = []
            for (let i = 0; i < res.data.result_data.length; i++) {
              arr = [
                ...arr,
                ...res.data.result_data[i].map((part) => ({
                  ...part,
                  srcTexDir: part.texDir,
                })),
              ]
            }
            let addr = []
            for (let i = 0; i < arr.length; ++i) {
              let code_addr =
                window.sessionStorage.getItem('thinkerx_material') ||
                arr[i].groupNo
                  ? `${arr[i].groupNo}`
                  : `${arr[i].orderNo}-${arr[i].address}`
              if (addr.length == 0) {
                addr.push(code_addr)
              } else {
                if (!addr.includes(code_addr)) {
                  addr.push(code_addr)
                }
              }
            }
            let newOrderInfo = Object.assign(orderInfo, {
              order_address: addr.join(','),
              batch: res.data.batch,
            })
            this.setOrderInfo(newOrderInfo)
            this.setPreLayoutParam({
              type: 'orderId',
              data: order_ids,
              orderInfo: newOrderInfo,
            })

            this.dealData(arr)
            this.emptyShow = false
          } else {
            this.emptyShow = false
            this.$message({
              type: 'error',
              message: `<span id="material_plank_error">${this.$t(
                'materialPage.plankNotFound'
              )}</span>`,
              dangerouslyUseHTMLString: true,
            })
          }
        }
      )
    },
    // 拆分封边 ←A↓B→C↑D => left:A bottom:B right:C top:D
    splitEdgeInfo(edgeInfo) {
      let left = Number(
        edgeInfo.substring(edgeInfo.indexOf('←') + 1, edgeInfo.indexOf('↓'))
      )
      let bottom = Number(
        edgeInfo.substring(edgeInfo.indexOf('↓') + 1, edgeInfo.indexOf('→'))
      )
      let right = Number(
        edgeInfo.substring(edgeInfo.indexOf('→') + 1, edgeInfo.indexOf('↑'))
      )
      let top = Number(edgeInfo.substring(edgeInfo.indexOf('↑') + 1))
      return {
        leftEdge: left,
        rightEdge: right,
        frontEdge: top,
        backEdge: bottom,
      }
    },
    // 处理接口获取的预处理数据, 根据板材, 颜色, 厚度聚类
    dealData(data, isLoadMoreTable) {
      const plankMap = new Map()
      let arr = []
      let findResultPlank = null
      let orderNo_address = []
      let tempSamPleData = {
        address: '',
        customer: '',
        orderNo: '',
        roomName: '',
        remarks: '',
      }
      // const afterData = isLoadMoreTable
      //   ? this.plankList.map((it) => it.parts).flat(2)
      //   : []
      // data = [...data, ...afterData]
      if (isLoadMoreTable) {
        this.plankList.forEach((item) => {
          item.parts.forEach((part) => {
            data.push(part)
          })
        })
      }
      for (let i = 0; i < data.length; ++i) {
        let plank = data[i]
        // 记录板件数量, 默认为1
        plank.amount = plank.amount ? plank.amount : 1
        // 记录板件成品宽度和成品长度
        plank.specWidth = toDecimal(plank.oRect.width, 2)
        plank.specHeight = toDecimal(plank.oRect.height, 2)

        // 将is_high_gloss_plank转为boolean
        // 存在老数据中没有is_high_gloss_plank的情况，就通过使用matCode来判断是否是高光
        plank.is_high_gloss_plank = !!plank.is_high_gloss_plank
        // 计算所有的孔槽数量和侧孔数量之和
        let holeNum = plank.holes.length + plank.sholes.length
        // 计算所有的拉槽数量和侧槽数量之和
        let slotNum = plank.slots.length + plank.sslots.length
        if (plank.curveHoles)
          slotNum += plank.curveHoles.filter(
            (hole) => hole.disType == 'CCCurveSlot'
          ).length
        // 记录板件的孔槽信息
        plank.hsInfo = plank.hsInfo ? plank.hsInfo : `${holeNum}孔${slotNum}槽`
        if (this.isLocalImport) {
          plank.hsInfo = '0孔0槽'
        }
        // 处理板件的封边信息
        if (!plank.hasOwnProperty('frontEdge') && plank.edgeInfo) {
          plank = { ...plank, ...this.splitEdgeInfo(plank.edgeInfo) }
        }
        // 记录板件是否为异形板件
        plank.specialShape = plank.path ? true : false
        // 板件默认选中
        plank.isSelected = true
        // 去掉材质中的 高光_
        plank.matCode = plank.matCode.replace(/高光_/g, '')
        // 板件唯一标识
        if (!plank.partUniqueId) {
          plank.partUniqueId = nanoid()
        }
        // 大板高光板标识
        const high_plank = plank.is_high_gloss_plank
        // 记录该板件的材质厚度颜色标识, 以便后续的判断
        plank.typeSymbol = `${plank.matCode}:${plank.thick}:${plank.texture}`
        // 板件默认不是编辑状态
        plank.isEditing = false
        // 将板件厚度转为数字类型
        plank.thick = Number(plank.thick)
        // 每个板件添加特殊标识
        plank.checklistID = i + 1
        generateSimplePlankNum(plank)
        // 生产唯一的plankNum
        const plankNum = uniquePlankNum.createUniquePlankNum(plank)
        plank.plankNum = plankNum
        plank.oriPlankNum = plankNum
        plank.customer_name = plank.customer_name ?? plank.customer
        // 为每一个孔槽添加唯一ID
        plank?.holes.forEach((it) => (it._conflict_uniqueId = nanoid()))
        plank?.slots.forEach((it) => (it._conflict_uniqueId = nanoid()))
        plank.handleSlopes &&
          plank.handleSlopes.forEach(
            (it) => ((it._conflict_uniqueId = nanoid()), (it.width = 10))
          )
        // curveHoles设置默认值
        plank.curveHoles = plank.curveHoles ?? []
        // 存在长圆孔且不存在异形，生成异形路径
        if (plank?.curveHoles?.length && !plank.specialShape) {
          plank.oPath = [
            [
              { x: 0, y: 0 },
              { x: plank.oRect.width, y: 0 },
              { x: plank.oRect.width, y: plank.oRect.height },
              { x: 0, y: plank.oRect.height },
            ],
          ]
          plank.path = [
            [
              { x: 0, y: 0 },
              { x: plank.rect.width, y: 0 },
              { x: plank.rect.width, y: plank.rect.height },
              { x: 0, y: plank.rect.height },
            ],
          ]
        }

        if (!plank.type) {
          plank.type = 'Plank'
        }
        const symbol = `${plank.matCode}:${plank.thick}:${plank.texture}`
        const symbolWithHighPlank = `${plank.matCode}:${plank.thick}:${plank.texture}:${high_plank}`
        // 将小板进行分类
        if (plankMap.has(symbolWithHighPlank)) {
          plankMap.get(symbolWithHighPlank).parts.push(plank)
        } else {
          const plankObj = {
            matCode: plank.matCode,
            thick: plank.thick,
            texture: plank.texture,
            symbol: symbol,
            parts: [plank],
            isUnfold: true, // 是否在页面默认展开此分类, 显示出所有板件
            isAllSelect: true, // 是否选中此分类下的所有板件
            high_plank,
          }
          plankMap.set(symbolWithHighPlank, plankObj)
        }
        let order_adr = ''
        if (plank.orderNo) {
          // 第三方跳转时可能address为空
          if (plank.address) {
            order_adr = `${plank.orderNo}-${plank.address}`
          } else {
            order_adr = plank.orderNo
          }
        } else {
          // 没有订单号的时候展示项目地址
          order_adr = plank.address ?? ''
        }
        if (plank.groupNo) {
          order_adr = plank.groupNo
        }
        if (!orderNo_address.includes(order_adr) && order_adr) {
          orderNo_address.push(order_adr)
          Object.keys(tempSamPleData).forEach((key) => {
            tempSamPleData[key] += `,${plank[key]}`
          })
        }
      }
      arr = Array.from(plankMap.values())
      let newOrderInfo = Object.assign(this.orderInfo, {
        order_address: orderNo_address.join(','),
      })
      if (this.isShowSample) {
        newOrderInfo.order_address = newOrderInfo.order_address
          ? newOrderInfo.order_address
          : ''
        Object.keys(this.samPleFormData).forEach((key) => {
          this.samPleFormData[key] = this.samPleFormData[key]
            ? this.samPleFormData[key]
            : tempSamPleData[key]?.slice(1)
        })

        if (this.isLoadMoreTable) {
          Object.keys(this.samPleFormData).forEach((key) => {
            this.moreSampleFormData[key].push(
              this.samPleFormData[key]
                ? this.samPleFormData[key]
                : tempSamPleData[key]?.slice(1)
            )
            this.samPleFormData[key] = this.moreSampleFormData[key].join(',')
          })
        }
      }
      if (window.sessionStorage.getItem('thinkerx_material')) {
        newOrderInfo.order_address = dealGuimenFGNo(data).join(',')
      }
      this.setOrderInfo(newOrderInfo)
      this.calcAllAreaAmount(arr)
      this.plankList = dealMaterialList(arr)
      this.resetSelect()
      this.updateLayoutData()
      // 处理完成之后, 获取匹配的余料数据
      this.getSurplusData()
      // 清空收集的plankNum
      uniquePlankNum.plankNumCollect.clear()

      // 初始化过滤
      if (this.filterMaterialList || this.filterObject) {
        if (this.beforeSearchMaterialList) {
          // 板件搜索前的筛选列表

          this.filterPlankList = this.beforeSearchMaterialList
        } else if (this.beforeSizeFilterList) {
          // 板件尺寸筛选前的筛选列表
          this.filterPlankList = this.beforeSizeFilterList
        } else {
          // 筛选列表
          this.filterPlankList = this.filterMaterialList ?? this.plankList
        }
        // 筛选条件保存
        this.filterObj = this.filterObject ?? {}
      } else {
        this.filterPlankList = this.plankList
      }
      this.filterPlankList = dealPlankListLabelID(
        dealMaterialList(this.filterPlankList)
      )
      this.setBeforeSearchMaterialList(this.filterPlankList)
      this.setBeforeSizeFilterList(this.filterPlankList)
      this.handleDefaultFoldData(this.isDefaultFoldData)
      this.$nextTick(() => {
        setTimeout(() => {
          this.isTaskLoading = false
        }, 500)
      })
    },
    // 检查是否是异形
    checkIsSpecial(part) {
      if (part.specialShape) {
        if (
          part.curveHoles &&
          part.curveHoles.length &&
          part.path.length < 2 &&
          part.path[0].length < 5
        ) {
          return part.curveHoles.every((hole) => hole.disType == 'CCCurveSlot')
            ? ''
            : this.$t('common.isSpecial')
        } else {
          return this.$t('common.isSpecial')
        }
      } else {
        return ''
      }
    },
    resetSelect() {
      // 已选中有值或者是历史记录跳转
      if (
        this.recodeSelectPart.selects.length ||
        !this.recodeSelectPart.isHistory
      ) {
        // // 已选中没有值并且是第三方跳转进入则直接返回
        if (this.recodeSelectPart.login) {
          return
        }
        this.plankList.forEach((plank) => {
          plank.parts.forEach((part) => {
            let key = part.ggid ? part.ggid : `${part.plankNum}${part.plankID}`
            if (this.recodeSelectPart.selects.includes(key)) {
              part.isSelected = true
            } else {
              part.isSelected = false
              plank.isAllSelect = false
            }
          })
        })
      }
    },
    // 计算所有分类的板件总面积和总数量
    calcAllAreaAmount(data) {
      for (let i = 0; i < data.length; ++i) {
        let totalArea = 0
        let totalAmount = 0
        for (let k = 0; k < data[i].parts.length; ++k) {
          let plank = data[i].parts[k]
          let oRect = plank.oRect
          // 除以100w是为了从mm换算成米
          totalArea +=
            (Number(oRect.width) *
              Number(oRect.height) *
              Number(plank.amount)) /
            1000000
          totalAmount += Number(plank.amount)
        }
        data[i].totalArea = toDecimal(totalArea, 2)
        data[i].totalAmount = totalAmount
      }
    },
    // 获取可使用的余料数据, num存在时是获取所有余料
    getSurplusData(num) {
      return new Promise((resolve) => {
        let match_list = []
        // 将处理完的数据, 获取其分类进行余料的匹配
        for (let i = 0; i < this.plankList.length; ++i) {
          match_list.push({
            type: this.plankList[i].high_plank
              ? `高光_${this.plankList[i].matCode}`
              : this.plankList[i].matCode,
            thick: this.plankList[i].thick,
            color: this.plankList[i].texture,
          })
        }
        let params = {
          match_list,
          page: this.surplusPage,
          limit: 9999,
          // page: num ? 1 : this.surplusPage,
          // limit: num ? num : 10,
        }
        this.$token('/search_surplus_depot', params, (res) => {
          if (res.status == 1) {
            // 获取所有的余料
            if (num) {
              for (let k = 0; k < res.data.data.length; ++k) {
                res.data.data[k].isSelected = this.isAllSurplusTrue
                res.data.data[k].showAmount = res.data.data[k].amount
              }
              for (let i = 0; i < this.surplusList.length; ++i) {
                let oldSurplus = this.surplusList[i]
                for (let k = 0; k < res.data.data.length; ++k) {
                  let newSurplus = res.data.data[k]
                  // 将目前处理的余料和之前的余料做比较, 如果有相同的值, 则把选中状态和数量赋给新的值
                  if (
                    oldSurplus.id == newSurplus.id &&
                    oldSurplus.ggid == newSurplus.ggid
                  ) {
                    res.data.data[k].amount = oldSurplus.amount
                    res.data.data[k].isSelected = oldSurplus.isSelected
                    res.data.data[k].showAmount = oldSurplus.showAmount
                    break
                  }
                }
              }
              resolve(res.data.data)
              return
            }
            if (res.data.data.length == 0 && this.page > 1) {
              this.surplusPage--
            }
            for (let i = 0; i < res.data.data.length; ++i) {
              res.data.data[i].isSelected = this.isAllSurplusTrue
              res.data.data[i].showAmount = res.data.data[i].amount
            }
            // 不知道为什么这么做
            this.surplusList = [...this.surplusList, ...res.data.data]
            // 怕影响到以前的功能只能根据ggid对余料去个重
            // 当余料数量有多个，使用时只使用了一部分也就是锁定了一部分，这个时候锁定的余料后端会拆出来变成单条数据，ggid是一致的，所以不能用ggid去重，用id
            this.surplusList = Array.from(
              new Map(this.surplusList.map((obj) => [obj.id, obj])).values()
            )
            this.surplusList = this.surplusList.map((item) => ({
              ...item,
              create_time: item.create_time.split(' ')[0],
              branch_name: item.branch_name || '-',
              is_high_gloss_plank: item.type?.includes('高光_') ? true : false,
            }))
            // 非本订单锁定余料不展示在开料清单
            const orderAddress = this.orderInfo.order_address.replace(
              /\(.*?\)|历史记录/g,
              ''
            )
            this.surplusList = this.surplusList
              .filter(
                (item) =>
                  item.lock_status == 0 ||
                  (item.lock_status && item.lock_order == orderAddress)
              )
              .map((item) => ({
                ...item,
                lock_order: orderAddress,
              }))
            // 存在只有历史记录 没有括号()时，去掉历史记录并更新
            if (
              !this.orderInfo.order_address.includes('(') &&
              this.orderInfo.order_address.includes('历史记录')
            ) {
              this.setOrderInfo({
                ...this.orderInfo,
                order_address: this.orderInfo.order_address.replace(
                  '历史记录',
                  ''
                ),
              })
            }
            this.resetSelectSurplus()
            this.surplusNum = this.surplusList.length
            if (this.surplusList.length == 0) {
              this.setShowSurplusList(false)
            }
          }
        })
      })
    },
    resetSelectSurplus() {
      // 开启余料锁定时 否则还是原有逻辑
      if (this.preferencesSetting.surplus_lock) {
        // 获取排版页使用的余料id
        const useSurplusId = this.paibanData
          .map(
            (item) => !item.surplusInfo?.isNotSurplus && item.surplusInfo?.id
          )
          .filter((id) => id)
        // 勾选排版方案使用的余料
        this.isSelectedAllSurplus =
          useSurplusId.length == this.surplusList.length
        this.surplusList.forEach((item) => {
          item.isSelected = useSurplusId.includes(item.id)
        })
      } else if (
        this.recodeSelectSurplus.selects.length ||
        !this.recodeSelectSurplus.isHistory
      ) {
        if (this.recodeSelectSurplus.login) {
          return
        }
        this.surplusList.forEach((item) => {
          if (this.recodeSelectSurplus.selects.includes(item.id)) {
            item.isSelected = true
          } else {
            item.isSelected = false
            this.isSelectedAllSurplus = false
          }
        })
      }
    },
    checkSurplusNum(index, surplus) {
      this.surplusList[index].showAmount = this.$refs.surplusInput[index].value
        ? this.$refs.surplusInput[index].value
        : surplus.amount
    },
    // 限制余料数量
    surplusAmountLimit(value, surplus) {
      if (value > Number(surplus.amount)) {
        return Number(surplus.amount)
      }
      return `${value}`.replace(/^(0+)|[^\d]+/g, '')
    },
    // 全选所有余料
    selectAllSurplus() {
      for (let i = 0; i < this.surplusList.length; ++i) {
        this.surplusList[i].isSelected = this.isSelectedAllSurplus
      }
      // 如果取消了全部选中, 则新获取到的数据为false, 反之为true
      if (!this.isSelectedAllSurplus) {
        this.isAllSurplusTrue = false
      } else {
        this.isAllSurplusTrue = true
      }
      this.$forceUpdate()
      this.recodeSurplus()
    },
    // 选中单个余料时, 判断是否全选
    judgeIsSelectAll() {
      if (this.selectedSurplusNum == this.surplusNum) {
        this.isSelectedAllSurplus = true
      } else {
        this.isSelectedAllSurplus = false
      }
      this.recodeSurplus()
    },
    // 记录选中余料
    recodeSurplus() {
      let select = []
      this.surplusList.forEach((item) => {
        if (item.isSelected) {
          item.showAmount = item.showAmount ? item.showAmount : 1
          select.push(item.id)
        }
      })
      this.setRecodeSelectSurplus({ selects: select, isHistory: false })
    },
    addNewSurplus() {
      this.$router.push({
        path: '/supplusStorage',
        query: {
          isNeedNewSurplus: 1,
        },
      })
    },
    // 下拉加载更多余料
    loadMoreSurplus(e) {
      this.$nextTick(() => {
        let scrollHeight = this.$refs.surplusList.scrollHeight
        let scrollTop = this.$refs.surplusList.scrollTop
        let clientHeight = this.$refs.surplusList.clientHeight
        // if (scrollHeight - scrollTop == clientHeight) {
        //   this.surplusPage++
        //   this.getSurplusData()
        // }
      })
    },

    // 显示生产线弹窗
    showProLineBox() {
      // 关闭弹窗提示
      this.isShowPlateDetails = false
      this.showAddBaseMModal = false
      this.isDelBigPlate = false
      this.showAddSpecialPlate = false
      this.showBigPlateDetail = false

      // 如果存在有误的输入, 则不允许进行排版
      const errPart = this.detactionPlank()
      this.recodeSurplus()
      if (errPart.length) {
        let msg = errPart.map((item) =>
          item.partName ? item.partName : this.$t('materialPage.noPlankName')
        )
        msg = this.$t('materialPage.plankDataErr') + JSON.stringify(msg)
        return this.$message.error(msg)
      }
      if (this.errorInput) {
        this.$message({
          message: `<span id="material_plank_error">${this.$t(
            'materialPage.plankDataErr'
          )}</span>`,
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return
      }
      // 同步预处理数据
      this.updateLayoutData()
      const partNums = this.filterPlankList
        .map((plank) => plank.parts)
        .flat(1)
        .filter((v) => v.isSelected)
        .map((part) => part.partUniqueId)
      this.selectionPlankList = this.filterPlankList
        .map((plank) => plank.parts)
        .flat(1)
        .filter((part) => partNums.includes(part.partUniqueId))
      if (this.selectionPlankList.length == 0) {
        this.$message({
          message: `<span id="material_plank_error">${this.$t(
            'materialPage.delete.errTip1'
          )}<span>`,
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        return
      }
      this.showProLineDialog = true
      this.paibanMessage = {}
    },
    detactionPlank() {
      const planks = this.filterPlankList
        .map((plank) => plank.parts)
        .flat(1)
        .filter((v) => v.isSelected)
      // 重置要显示的排版小板数
      const awaitPaibanCount = this.$refs['awaitStoreRef']?.data?.length ?? 0
      const bujianStoreCount = this.$refs['bujianTableRef']?.data?.length ?? 0
      this.recordChoosePlankDataLength = awaitPaibanCount + bujianStoreCount
      planks.forEach((item) => {
        this.recordChoosePlankDataLength += Number(item.amount)
      })
      const parts = planks.filter((part) => {
        const { edgeInfo, oRect } = part
        const [left, bottom, right, top] = edgeInfo
          .split(/←|↓|→|↑/)
          .filter((it) => it)
          .map((e) => (e ? Number(e) : 0))
        const w = left + right
        const h = bottom + top
        const ow = +oRect.width
        const oh = +oRect.height
        return ow - w <= 0 || oh - h <= 0
      })
      return parts
    },
    syncGuiguiBaseM(isSync) {
      //保存不再提示是否同步柜柜原片设计的弹窗
      this.$token(
        '/save_preferences_setting',
        {
          data: { isCheckBigPlankSynchronous: this.isCheckBigPlankSynchronous },
        },
        (res) => {
          if (res.status != 1) {
            this.$message.error(res.msg)
          }
        }
      )
      if (isSync) {
        // 同步柜柜数据，同时跳转排版
        this.$token('/sync_guigui_data', { uid: this.userInfo.id }, (res) => {
          if (res.status == 1) {
            this.$message({
              type: 'success',
              message: `<span id="material_sync_success">${this.$t(
                'basePlankManager.syncSuccess'
              )}</span>`,
              dangerouslyUseHTMLString: true,
            })
            this.BaseMIsSame = true
            this.dealLayoutData(this.state)
          } else {
            this.$message({
              type: 'error',
              message: `<span id="material_sync_error">${this.$t(
                'basePlankManager.syncErr'
              )}</span>`,
              dangerouslyUseHTMLString: true,
            })
            this.resetNCSetting()
            return
          }
        })
      } else {
        this.BaseMIsSame = true
        this.dealLayoutData(this.state)
      }
      this.showSyncGuiguiBaseMModal = false
    },
    // 旧版xy提示
    oldFiveSixXYTip(res) {
      if (res.status == 499) {
        const h = this.$createElement
        this.$msgbox({
          title: this.$t('common.warning'),
          message: h('p', null, [
            h('i', { style: 'color: red' }, this.$t('materialPage.tooOld')),
            h('span', null, '，' + this.$t('materialPage.contactUs')),
          ]),
          confirmButtonText: this.$t('common.confirm'),
        }).catch((e) => {
          console.error(e)
        })
        this.isShowLoading = false
        this.showProLineDialog = false
        this.showSyncGuiguiBaseMModal = false
        this.showAddBaseMModal = false
        this.showMsgDialog = false
        return true
      } else {
        return false
      }
    },
    /** 排版前重置一些必要的参数 */
    resetPaibanData() {
      // 清空store中保存的特殊大板信息
      this.setSpecialPlankParams([])
      this.$store.commit('setLockedPlank', [])
      this.setPaibanKey('')
    },
    // 重置生产线设置
    resetNCSetting() {
      // TODO wc 生产线备份
      // store.commit('setPreLayoutSetting', this.oriPreSetting)
      // store.commit('setNcSetting', this.oriNCSetting)
    },
    // 将预处理数据处理成排版对应参数
    async dealLayoutData(state, isSendToFactory = false) {
      this.isConfirmLoading = true
      this.oriNCSetting = this.ncSetting ? deepCopy(this.ncSetting) : null
      this.oriPreSetting = this.preLayoutSetting
        ? deepCopy(this.preLayoutSetting)
        : null
      if (state && state.isPreLayout) {
        const res = await getPrelayoutSetting()
        if (res.status) {
          const { setting_value: setting } = res.data
          const ncSetting = dealPreLayoutSetting(setting)
          store.commit('setPreLayoutSetting', res.data)
          store.commit('setOriLayoutSetting', res.data) // 保存排版用预排版设置
          store.commit('setNcSetting', ncSetting)
        }
      }
      this.resetPaibanData()
      this.setPrePaibanData([])
      if (
        this.currentSelectStandardPlank &&
        Object.keys(this.currentSelectStandardPlank).length
      ) {
        this.setSelectStandardPlank(this.currentSelectStandardPlank)
        this.setHistoryPlankEdgeOff(this.currentHistoryPlankEdgeOff)
      } else {
        this.setSelectStandardPlank(this.defaultSelectedPlank)
        this.setHistoryPlankEdgeOff(this.defaultSelectedPlank.plankEdgeOff)
      }

      this.isConfirmLoading = true
      buryPointApi('material', 'set_layout')
      this.isPaibanErr = false
      if (state) {
        this.state = state
        this.setCustomPlankOrder(state.customPlankOrder)
        this.setPlankOrderList(state.plankOrderList)
        if (state.customPlankOrder) {
          buryPointApi('material', 'customPaiban')
          const standardPlank = state.plankOrderList.find(
            (item) => item.plankType == '标准大板'
          )
          if (
            state.plankOrderList.length == 0 ||
            state.plankOrderList.every((item) => item.amount == 0)
          ) {
            this.$message.error('请至少保留一种大板参与排版!')
            this.showProLineDialog = false
            this.resetNCSetting()
            return
          } else if (!standardPlank || standardPlank.amount == 0) {
            const filterPlankList = this.filterPlankList?.filter((item) =>
              item.parts.some((part) => part.isSelected)
            )
            const plateList = state.plankOrderList.filter(
              (item) => item.plankType != '标准大板'
            )
            let flag = false
            filterPlankList.forEach((item) => {
              const filterPlateList = plateList.filter(
                (plateItem) =>
                  item.matCode == plateItem.matCode &&
                  item.texture == plateItem.texture &&
                  item.thick == plateItem.thick
              )
              if (
                !filterPlateList.length ||
                filterPlateList.every((plateItem) => plateItem.amount == 0)
              ) {
                flag = true
              }
            })
            if (flag) {
              this.$message.error(this.$t('arrangedPage.plankErrTip'))
              this.showProLineDialog = false
              this.resetNCSetting()
              return
            }
          }
        }
      }
      const currentLine = this.proLineList.find(
        (item) => this.state.process_line_id === item.id
      )
      this.$store.commit(
        'setRecodrProductionLineChoose',
        state && state.isPreLayout
          ? this.$t('preLayoutSetting.preWayEmpty')
          : currentLine.name
      )
      this.selectedProLine = currentLine
      const { cuttingEquipment } = currentLine
        ? currentLine.data
        : { cuttingEquipment: '' }
      if (cuttingEquipment === 'electronicSaw') {
        await this.handleShowOrderMark()
        this.showProLineDialog = false
        this.isSendToFactory = isSendToFactory
        if (!this.isShowOrderMark) {
          this.handleProlineIsElectronicSaw(isSendToFactory)
          this.$token('/save_preferences_setting', { data: state }, (res) => {
            if (res.status != 1) {
              this.isConfirmLoading = false
              this.$message.error(res.msg)
            } else {
              this.setActivePaibanWay([this.state.paibanWay])
            }
          })
        }
        this.resetNCSetting()
        return
      }
      if (
        this.$refs['surplusTableRef'] &&
        !this.$refs['surplusTableRef'].isCancelActive
      ) {
        this.isConfirmLoading = false
        this.resetNCSetting()
        return
      }

      if (
        !this.BaseMIsSame &&
        !sessionStorage.getItem('thinkerx_material') &&
        !this.$route.query.thinkerx_material &&
        !this.isCheckBigPlankSynchronous
      ) {
        // 和柜柜数据不一致时弹出提示
        this.showProLineDialog = false
        this.showSyncGuiguiBaseMModal = true
        this.isConfirmLoading = false
        return
      }
      // this.isShowLoading = true
      this.setSurplusAutoTailor(this.state.surplusAutoTailor)
      if (this.state.surplusAutoTailor) {
        this.setSurplusTailorWay(this.state.surplusTailorWay)
        this.setRecodeIsTailor(this.state.surplusAutoTailor)
        this.setLongSideRect(this.state.longSideRect)
        this.setShortSideRect(this.state.shortSideRect)
      }
      this.setIsResetPaibanAllocation(true)
      // 获取所有刀
      if (state && state.isPreLayout) {
        this.setAllKnifes([this.preLayoutSetting.knife])
      } else {
        const knifes = await getAllKnifes({
          setting_id: this.state.process_line_id,
        })
        this.setAllKnifes(knifes.data.data)
      }
      // 已选中板件
      const selectPlanks = this.filterPlankList
        .map((plank) => plank.parts)
        .flat(2)
        .filter((part) => part.isSelected)
      // 补件仓库数据
      const bujianData = await this.$refs[
        'bujianTableRef'
      ]?.analyzeLayoutToPaiban()
      // 待排版仓库数据
      const awaitStoreData =
        (await this.$refs['awaitStoreRef']?.getAwaitStorePlank()) ?? []
      // 记录参加了排版的待排版库板件，用于待排版库隐藏对应的板件
      const awaitStoreHiddenIds = awaitStoreData.map((it) => it.partUniqueId)
      // 已经参与排版的待排版库的id
      const preLayoutAwaitHiddenIds = selectPlanks
        .filter((it) => it._isAwaitStorePlank)
        .map((it) => it.partUniqueId)
      this.$store.commit(
        'awaitPaibanStore/setParticipationPaibanPlank',
        awaitStoreHiddenIds.concat(preLayoutAwaitHiddenIds)
      )

      const bujianList = bujianData?.plank_data ?? []
      this.currentSelectPlankList = selectPlanks
      const dealRes = await calcBaseMaterial(
        [...selectPlanks, ...bujianList, ...awaitStoreData],
        this.selectStandardPlank,
        this.state.isPreLayout ? -2 : this.state.process_line_id,
        (errType) => {
          this.isShowLoading = false
          if (errType === 'plank') {
            this.dealPlateErr()
          }
        }
      )
      if (dealRes) {
        this.extraLongPlates = dealRes.setNotMatchPartList
        this.updateMaterialData(dealRes)
      }

      if (this.isError) {
        this.showProLineDialog = false
        this.resetNCSetting()
        return
      }
      // 检测原片是否可容纳所有板件
      const isFlag = this.extraLongPlates && this.extraLongPlates.length
      let extraLongPlatesSymbolObj = {}
      this.bigPlateDetailList = this.extraLongPlates?.map((item) => ({
        ...item,
        texDir: this.texDirMap[item.texDir],
      }))
      this.extraLongPlates?.forEach((item) => {
        if (!extraLongPlatesSymbolObj.hasOwnProperty(item.symbol)) {
          extraLongPlatesSymbolObj[item.symbol] = [item]
        } else {
          extraLongPlatesSymbolObj[item.symbol].push(item)
        }
      })

      Object.keys(extraLongPlatesSymbolObj).forEach((key) => {
        const keyArr = key.split(':')
        let height
        let width
        if (
          extraLongPlatesSymbolObj[key].every(
            (part) => part.originHeight <= 2440 && part.originWidth <= 1220
          )
        ) {
          height = 2440
          width = 1220
        } else if (
          extraLongPlatesSymbolObj[key].some(
            (part) => part.originWidth <= 1220
          ) &&
          Math.max(
            ...extraLongPlatesSymbolObj[key].map((part) => part.originHeight)
          ) > 2440 &&
          Math.max(
            ...extraLongPlatesSymbolObj[key].map((part) => part.originHeight)
          ) <= 2750
        ) {
          height = 2750
          width = 1220
        } else if (
          extraLongPlatesSymbolObj[key].some(
            (part) => part.originHeight > 2750 || part.originWidth > 1220
          )
        ) {
          height = ''
          width = ''
        }
        if (
          extraLongPlatesSymbolObj[key].some(
            (part) => part.texDir === 'reverse'
          )
        ) {
          height = ''
          width = ''
        }
        this.bigPlateList.push({
          matCode: keyArr[0],
          thick: keyArr[1],
          color: keyArr[2],
          height,
          width,
          isPicked: false,
          is_high_gloss_plank: keyArr[3] === 'true',
        })
      })
      if (!this.state?.customPlankOrder) {
        if (isFlag && !this.autoAddSpecialPlate) {
          // 默认展示第一个数据
          this.currentExtraLongPlatesItem = this.extraLongPlates[0]
          // 名称
          this.extraLongPlatesName = this.currentExtraLongPlatesItem.partName
          // 序号
          this.extraLongPlatesIndex = 1
          // partUniqueId
          this.extraLongPlatesUniqueID =
            this.currentExtraLongPlatesItem.partUniqueId
          // 关闭同步柜柜数据弹窗
          this.showSyncGuiguiBaseMModal = false
          this.showProLineDialog = false
          this.isShowLoading = false
          this.showAddBaseMModal = true
          this.isConfirmLoading = false
          return
        } else if (isFlag && this.autoAddSpecialPlate) {
          this.showSyncGuiguiBaseMModal = false
          this.showProLineDialog = false
          this.isShowLoading = false
          this.showAddSpecialPlate = true
          this.isConfirmLoading = false
          return
        }
      }
      this.isConfirmLoading = false
      this.isShowLoading = true
      this.showProLineDialog = false
      // 获取选中的板件
      this.updateLayoutData()
      let params = {
        setting_id: this.state.process_line_id,
      }
      if (window.sessionStorage.getItem('thinkerx_material')) {
        params.production_from = 'guimen'
      }
      const res =
        state && state.isPreLayout
          ? { data: this.ncSetting }
          : await getProductionNcSetting(params)
      const newNCSetting = res.data
      res.data && this.judgePlateKnife(res.data.plate_knife_map)
      if (this.isError) {
        this.resetNCSetting()
        return
      }
      let flag = this.oldFiveSixXYTip(res)
      if (flag) {
        this.resetNCSetting()
        return
      }
      // if (res.status != 1) return
      res.data.drawPlankWidth =
        res.data.xyReverse ?? res.data.countertopSetting.xyReverse
          ? this.selectStandardPlank.plankHeight
          : this.selectStandardPlank.plankWidth
      res.data.drawPlankHeight =
        res.data.xyReverse ?? res.data.countertopSetting.xyReverse
          ? this.selectStandardPlank.plankWidth
          : this.selectStandardPlank.plankHeight
      // 保存选择的生产线设置
      const currentCuttingEquipment = this.proLineList.filter(
        (item) => item.id === this.state.process_line_id
      )[0]
      let ncSetting = Object.assign(res.data, {
        process_setting_id: newNCSetting.isPreLayout
          ? null
          : this.state.process_line_id,
        process_setting_name: newNCSetting.isPreLayout
          ? this.$t('preLayoutSetting.preWayEmpty')
          : this.activeProLineName,
        cuttingEquipment: newNCSetting.isPreLayout
          ? 'engraving'
          : currentCuttingEquipment.data.cuttingEquipment,
      })
      // 防止新账号没有顺逆时针的设置
      if (!ncSetting.cutDirection) {
        ncSetting.cutDirection = '逆时针'
      }
      this.setNcSetting(ncSetting)
      this.setHighglossDirection(this.ncSetting.highgloss_direction)
      // 处理数据，准备排版
      this.dealReadPaibanData(this.selectionPlankList, ncSetting)
      this.$token('/save_preferences_setting', { data: this.state }, (res) => {
        if (res.status != 1) {
          this.$message.error(res.msg)
        } else {
          this.setPaibanInfo(this.state)
          this.setActivePaibanWay([this.state.paibanWay])
        }
      })
      this.setHasRollPoly(false)
    },
    // 处理准备排版数据，进行扩版一系列操作
    async dealReadPaibanData(selectionPlankList, ncSetting) {
      // 补件仓库数据
      const bujianData = await this.$refs[
        'bujianTableRef'
      ]?.analyzeLayoutToPaiban()
      // 待排版仓库数据
      const awaitStoreData =
        (await this.$refs['awaitStoreRef']?.getAwaitStorePlank()) ?? []
      // 排版前清空本地缓存的待排版库数据
      this.$store.commit('awaitPaibanStore/clear')
      const bujianList = bujianData?.plank_data ?? []
      // 保存本次排版所使用的补件
      this.setAvailableBujianList(bujianList)
      // 补件一同参与排版
      const finalSelectPlankList = [
        ...selectionPlankList,
        ...bujianList,
        ...awaitStoreData,
      ]
      // 多材质不同下料刀
      const moreMatCodePaibanData = dealMorePlateKnife(
        finalSelectPlankList,
        ncSetting
      )
      const fnialList = []
      // prePaibanData进行push操作
      this.setIsSinglePushPrePaibanData(true)
      // 清空perPaibanData
      this.setPrePaibanData([])
      // 不同开料刀生成不同的请求数据
      moreMatCodePaibanData.forEach((paibanData) => {
        const {
          preLayoutData,
          setting: { diameter },
        } = paibanData
        const nc = JSON.parse(JSON.stringify(ncSetting))
        nc.knife.diameter = diameter
        changeLayoutData(preLayoutData)
        // 进行排版前的板件处理
        const val = layoutStart(nc)
        fnialList.push(val)
      })
      this.setIsSinglePushPrePaibanData(false)
      if (this.errorPathPlankID.length) {
        this.isShowLoading = false
        this.showSyncGuiguiBaseMModal = false
        this.$message({
          type: 'error',
          message: this.$t('materialPage.pathErr', {
            errorPathPlankID: this.errorPathPlankID,
          }),
        })
        return
      }
      if (fnialList.some((it) => !it) || !fnialList.length) {
        this.isShowLoading = false
        this.showSyncGuiguiBaseMModal = false
        this.$message({
          type: 'error',
          message: `<span id="material_knife_error">${this.$t(
            'materialPage.noKnife'
          )}</span>`,
          dangerouslyUseHTMLString: true,
        })
        return
      }
      const resultArr = []
      let hasErr = false
      for (const val of fnialList) {
        const data = dealLayoutResultData(
          val,
          this.needBaseMaterials,
          this.state,
          ncSetting,
          this.$store,
          this.isNewLayout,
          this.materialRes.only_layout_too_big
        )
        if (!data) {
          hasErr = true
          break
        }
        resultArr.push(data)
      }
      if (hasErr) {
        this.isShowLoading = false
        this.plankErrVisible = true
        return
      }
      // 获取余料数据
      let allSurplusList = []
      if (this.preferencesSetting.isSurplusRecommend) {
        allSurplusList = await this.getAllSurplusList(
          this.surplusList,
          this.surplusNum,
          () => {
            this.showSyncGuiguiBaseMModal = false
          }
        )
      }
      let selectSurplusList = allSurplusList.filter((item) => item.isSelected)
      let oldSelectSurplusList = this.selectSurplusList.map((item) => item.id)
      // 开启余料锁定 且选了余料
      if (this.preferencesSetting.surplus_lock && selectSurplusList.length) {
        this.setSelectSurplusList(selectSurplusList)
        // 排版之前锁定勾选的余料
        const lockParams = {
          surplus: selectSurplusList.map((item) => ({
            ...item,
            lock_num: item.amount,
            lock_status: 1,
          })),
          release_all: false,
        }
        const lockRes = await surplusLock(lockParams)
        // 存在选中的余料大板被其他排版使用中，请稍后再试
        if (lockRes.status == 0) {
          this.$message.error(lockRes.msg)
          this.isShowLoading = false
          return
        } else if (lockRes.data.length) {
          // 更新柜柜添加余料的 ggid
          allSurplusList.forEach((item) => {
            lockRes.data.forEach((resItem) => {
              if (item.id == resItem.origin_data.id) {
                item.ggid = resItem.origin_data.ggid
              }
            })
          })
          // 获取锁定后最新的余料 并更新id（柜柜添加的余料，进行锁定后，id会变化，因为是存在不同的表里的）
          const lastSurplusList = await this.getSurplusData(
            allSurplusList.length
          )
          lastSurplusList.forEach((item) => {
            allSurplusList.forEach((allItem) => {
              if (
                item.ggid == allItem.ggid &&
                item.lock_order == allItem.lock_order
              ) {
                allItem.id = item.id
              }
            })
          })
          selectSurplusList = allSurplusList.filter((item) => item.isSelected)
          this.setSelectSurplusList(selectSurplusList)
        }

        // 解锁未勾选的余料 存在第一次勾选使用了 第二次切到开料清单未勾选排版 还被锁定的情况
        const unlockOldSurplusList = allSurplusList.filter(
          (item) => oldSelectSurplusList.includes(item.id) && !item.isSelected
        )
        if (unlockOldSurplusList.length) {
          const unLockParams = {
            surplus: unlockOldSurplusList.map((item) => ({
              ...item,
              lock_num: item.amount,
              lock_status: 0,
            })),
            release_all: false,
          }
          await surplusLock(unLockParams)
        }
      }
      // 处理余料数据 准备排版数据
      this.dealSurplusParams(allSurplusList, ncSetting, resultArr, this.$store)
    },

    // 获取所有的余料数据 todo
    async getAllSurplusList(surplusList, total, cb) {
      return new Promise((resolve) => {
        if (surplusList.length < total) {
          this.getSurplusData(this.surplusNum).then((res) => {
            surplusList = res
            resolve(surplusList)
          })
        } else {
          cb && cb()
          resolve(surplusList)
        }
      })
    },
    async handleProlineIsElectronicSaw(isSendToFactory = false) {
      // 检查本地所使用的目录是否存在
      if (!(await this.$refs.folderChooseModalRef?.verify())) {
        return
      }
      const isShowTrialDialog =
        await this.$refs.trialRef.checkUserSettingStatus(
          this.selectedProLine.id
        )
      // 如要弹出模态框则返回，走模态框逻辑
      if (isShowTrialDialog) {
        this.showProLineDialog = false
        return
      }
      this.showProLineDialog = false

      this.isShowLoading =
        (this.isShowOrderMark && this.isCancontinue) || !this.isShowOrderMark

      // 获取选中的补件
      const bujianData = await this.$refs[
        'bujianTableRef'
      ]?.analyzeLayoutToPaiban()
      // 待排版仓库数据
      const awaitStoreData =
        (await this.$refs['awaitStoreRef']?.getAwaitStorePlank()) ?? []
      const bujianList = bujianData?.plank_data ?? []
      const parts = [...bujianList, ...awaitStoreData]
      this.filterPlankList.forEach((plank) => {
        plank.parts.forEach((part) => {
          if (part.isSelected) {
            parts.push({
              ...part,
              texDir: part.texDir == 'reverse' ? 'normal' : part.texDir,
            })
          }
        })
      })
      for (const plank of parts) {
        if (plank.slots.length !== 0 || plank.holes.length !== 0) {
          const holeSide = plank.holes.every((item) => item.side === -1)
          const slotSide = plank.slots.every((item) => item.side === -1)
          if (holeSide && slotSide) {
            reversalPlank(plank)
          }
        }
        // 根据小板的高光属性对小板的matCode做处理
        if (plank.is_high_gloss_plank) {
          plank.matCode = `高光_${plank.matCode}`
        }
      }
      this.$getByToken(
        '/load_customize_folder_setting',
        {
          setting_id: this.state.process_line_id,
        },
        (res) => {
          if (res.status === 1) {
            this.customizeFolderSetting = res.data
          }
        }
      )
      this.$token(
        '/electronic_saw',
        {
          uid: this.userInfo.id,
          process_id: this.state.process_line_id,
          layoutData: parts,
        },
        (res) => {
          if (res.status === 1) {
            // 发送试生产记录到后端
            if (this.isTrialProduct) {
              sendTrialRecord(res.data, {
                process_setting_id: this.selectedProLine.id,
                process_setting_name: this.selectedProLine.name,
              })
            }
            if (isSendToFactory) {
              this.NCUrls = res.data
              const files = [...this.NCUrls]
              let file_url = ''
              let plank_url = ''
              ossUploadFiles({ files }, 'factoryFiles', (res) => {
                file_url = res
                ossUploadFiles(
                  {
                    plank: parts.map((part) => ({ ...part, amount: 1 })),
                  },
                  'plankFiles',
                  (res) => {
                    plank_url = res
                    this.$token(
                      '/workshop/files',
                      {
                        address: Array.from(
                          new Set(
                            this.preLayoutData.map((data) =>
                              data.address ? data.address : data.groupNo
                            )
                          ).values()
                        ).join(','),
                        order_id: Array.from(
                          new Set(
                            this.preLayoutData.map((data) => data.orderNo)
                          ).values()
                        ).join(','),
                        product_line: this.selectedProLine.name,
                        file_url,
                        plank_url,
                        process_line_id:
                          this.state.process_line_id ??
                          this.state.process_setting_id,
                      },
                      (res) => {
                        this.isShowLoading = false
                        this.isSendToFactory = false
                        if (res.status == 1) {
                          this.$message.success(
                            this.$t('materialPage.sendFactorySuccess')
                          )
                          const { obj, name: ossFileName } = this.ossFilesParams
                          ossUploadFiles(obj, ossFileName, (url) => {
                            this.saveMaterialPaiban(url, 1)
                          })
                        } else {
                          this.$confirm(this.$t('materialPage.sendFactoryErr'))
                        }
                        return
                      }
                    )
                  }
                )
              })
            } else {
              this.downloadFile(res.data)
            }
          }
        }
      )
    },
    handleTrialProductConfirm() {
      this.handleProlineIsElectronicSaw()
    },
    dealSlotData(parts) {
      return parts.map((part) => {
        const slots = [...part.slots, ...(part.handleSlopes ?? [])]
        slots.forEach((slot) => {
          slot.pt1.y = part.rect.height - slot.pt1.y
          slot.pt2.y = part.rect.height - slot.pt2.y
          slot.opt1.y = part.oRect.height - slot.opt1.y
          slot.opt2.y = part.oRect.height - slot.opt2.y
        })
        return part
      })
    },
    getFile(url) {
      return new Promise((resolve) => {
        axios({
          method: 'get',
          url,
          responseType: 'arraybuffer',
        })
          .then((data) => {
            resolve(data.data)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    // 下载NC文件 电子锯
    async downloadFile(arr) {
      let params = {
        setting_id: this.state.process_line_id,
      }
      // 获取ncSetting设置
      let ncSetting
      await httpRequest.get('get_production_nc_setting', params).then((res) => {
        ncSetting = res.data
      })
      let len = arr.length
      for (let i = 0; i < len; ++i) {
        if (arr[i].error_info != '') {
          this.$confirm(
            `<span id="material_nc_error">${arr[i].error_info}</span>`,
            this.$t('common.warning'),
            {
              type: 'error',
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.$t('common.confirm'),
            }
          )
          this.isShowLoading = false
          return
        }
      }
      let imgArr = this.getLabelPaibanImg()
      if (typeof window.webToQt !== 'undefined') {
        this.dealWebToQtData(arr, imgArr, ncSetting)
        return
      }
      let arrs = []
      for (let i = 0; i < this.plankList.length; ++i) {
        if (this.plankList[i].parts.length == 0) continue
        let address = this.plankList[i].parts[0].address
        if (arrs.length == 0) {
          arrs.push(address)
        } else {
          if (!arrs.includes(address)) {
            arrs.push(address)
          }
        }
      }
      let name = handleGenFileNameStr(
        this.plankList,
        this.customizeFolderSetting
      )
      // 清空存储的文件内容数据
      this.$store.commit('setFolderContents', {})
      this.$store.commit('setRepeatFileName', [])
      this.$store.commit('setTempFileName', [])
      const result = await downloadNCFile(
        arr,
        name,
        imgArr,
        false,
        '',
        mergeFolder,
        ncSetting,
        () => {
          this.isDownloadingNc = false
        },
        ''
      )
      if (result) {
        const {
          zip,
          nameStr,
          promises,
          qtRepeat,
          repeatNameList,
          repeatZipFileNameList,
          repeatZipFolderNameList,
        } = result
        // TODO 重名文件判断
        // 获取merge_folder_display_name
        if (qtRepeat) {
          this.repeatNameList = repeatNameList
          this.repeatFileNameList = repeatZipFileNameList
          this.repeatFolderNameList = repeatZipFolderNameList
          return
        }
        let repeatZipFolderNameList2 = []
        let repeatZipFileNameList2 = JSON.parse(
          JSON.stringify(this.$store.state.repeatFileName)
        )

        Object.keys(this.$store.state?.folderContents)?.forEach((item) => {
          if (
            !ncSetting?.customizeFolder?.merge_folder_list.includes(
              fileNameSetObj[item] + '文件'
            ) &&
            ncSetting?.customizeFolder?.merge_folder_list.length > 1
          ) {
            if (
              ncSetting.customizeFolder.merge_folder_display_name.trim() ===
              this.$store.state.folderContents[item].trim()
            ) {
              repeatZipFolderNameList2.push(fileNameSetObj[item])
            }
          }
        })
        this.repeatFileNameList = repeatZipFileNameList2
        this.repeatFolderNameList = repeatZipFolderNameList2
        this.repeatNameList = []
          .concat(repeatZipFolderNameList2)
          .concat(repeatZipFileNameList2)
        if (this.repeatNameList.length) {
          this.isShowWarningModal = true
          this.isShowLoading = false
          this.isConfirmLoading = false
          return
        }
        await promiseToZip(
          zip,
          promises,
          nameStr,
          (url) => [url],
          () => {
            this.isShowLoading = false
          },
          {},
          ncSetting
        )
      }
      // 同意试生产后也需要自动保存历史
      const { obj, name: ossFileName } = this.ossFilesParams
      ossUploadFiles(obj, ossFileName, (url) => {
        this.saveMaterialPaiban(url, 1)
      })
      this.$message.success(this.$t('common.downloadDoneByType'))
    },
    // 处理web和客户端通信时的数据 电子
    async dealWebToQtData(arr, imgArr, ncSetting) {
      this.$store.commit('setFolderContents', {})
      this.$store.commit('setRepeatFileName', [])
      this.$store.commit('setTempFileName', [])
      try {
        let qtData = []
        // 处理排版图
        for (let key in imgArr) {
          imgArr[key].forEach((item) => {
            let url = item.url.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ''
            )
            let obj = {
              originFile: _translateFileNameFn(
                `排版图${item.index}`,
                ncSetting.customizeFolder,
                `paiban${item.index}`
              ),
              fileName: item.name,
              url: url,
              fileType: 'base64',
            }
            qtData.push(obj)
          })
        }
        let sawSetting
        const saw_setting_res = await getSaeSetting({
          setting_id: this.state.process_line_id,
          setting_type: 'electronicSaw',
        })
        if (saw_setting_res.data.data) sawSetting = saw_setting_res.data.data
        const { orderType, orderCounts } = this.dealElectronicSawData(arr)
        // 处理下料文件
        let num = 1
        for (let i = 0; i < arr.length; ++i) {
          let url =
            'https://eggrj.oss-cn-hangzhou.aliyuncs.com/' + arr[i].filename
          // 如果是贴标机文件
          let prefixName = ''
          let originFile = ''
          let fileName = ''
          let nextFileName = ''
          if (
            this.ncSetting.folderCategory &&
            this.ncSetting.folderCategory.useCategoryFolder
          ) {
            prefixName =
              arr[i].stock_key
                .substring(0, arr[i].stock_key.lastIndexOf(':'))
                .replace(/:/g, '_') + '/'
          }
          let obj = {}
          if (arr[i].filetype === 'label') {
            let setName = _translateFileNameFn(
              '贴标机',
              ncSetting.customizeFolder,
              'labeling_folder'
            )
            originFile = prefixName + setName
            fileName = arr[i].file_display_name
          } else if (arr[i].filetype === 'nc') {
            let setName = _translateFileNameFn(
              '下料文件',
              ncSetting.customizeFolder,
              'engraving_folder'
            )
            originFile = prefixName + setName
            if (arr[i].file_display_name == '') {
              fileName =
                ('00' + num).substr(-3, 3) +
                '_' +
                (arr[i].direction == 'front' ? '正.' : '反.') +
                arr[i].filetype
              num++
            } else {
              fileName = arr[i].file_display_name
            }
          } else if (arr[i].file_type === 'saw') {
            originFile = _translateFileNameFn(
              'saw',
              ncSetting.customizeFolder,
              'electronic_saw_folder'
            )
            originFile = originFile ? `${originFile}/` : ''

            if (sawSetting.rooms_by_category) {
              const delimiter = sawSetting?.delimiter || ''
              let lv2Folder = ''
              let lv3Folder = ''

              if (sawSetting.useCategoryFolder) {
                switch (orderType) {
                  case this.OrderTypeMap['noOrder']:
                  case this.OrderTypeMap['singleOrder']:
                    if (arr[i].room_name) {
                      const newRemark = arr[i].room_remark
                        ? arr[i].room_remark.replace(/[/:?<>\|."*]/g, '')
                        : ''
                      nextFileName = `${arr[i].room_name}${
                        newRemark ? delimiter + newRemark : ''
                      }`
                    } else if (arr[i].partHasRoom) {
                      nextFileName = '其他房间'
                    }
                    break
                  case this.OrderTypeMap['multiOrder']:
                    if (arr[i].order_no) {
                      const newCustomer = arr[i].customer_name
                        ? arr[i].customer_name.replace(/[/:?<>\|."*]/g, '')
                        : ''
                      lv2Folder = `${arr[i].order_no}${
                        newCustomer ? delimiter + newCustomer : ''
                      }`
                    } else {
                      lv2Folder = '其他订单'
                    }
                    if (arr[i].room_name) {
                      const newRemark = arr[i].room_remark
                        ? arr[i].room_remark.replace(/[/:?<>\|."*]/g, '')
                        : ''
                      lv3Folder = `${arr[i].room_name}${
                        newRemark ? delimiter + newRemark : ''
                      }`
                    } else if (
                      orderCounts[arr[i].order_no]?.length !== 1 &&
                      orderCounts[arr[i].order_no].some(
                        (item) => item.room_name
                      )
                    ) {
                      lv3Folder = arr[i].partHasRoom ? '其他房间' : ''
                    }
                    nextFileName = `${lv2Folder}${
                      lv3Folder ? '/' + lv3Folder : ''
                    }`
                    break
                  default:
                    break
                }
              } else {
                switch (orderType) {
                  case this.OrderTypeMap['noOrder']:
                    break
                  case this.OrderTypeMap['singleOrder']:
                    break
                  case this.OrderTypeMap['multiOrder']:
                    if (arr[i]?.partHasOrder) {
                      nextFileName = '其他订单'
                    } else {
                      const newCustomer = arr[i].customer_name
                        ? arr[i].customer_name.replace(/[/:?<>\|."*]/g, '')
                        : ''
                      nextFileName = `${arr[i].order_no}${
                        newCustomer ? delimiter + newCustomer : ''
                      }`
                    }
                    break
                  default:
                    break
                }
              }

              originFile += nextFileName ? `${nextFileName}/` : ''
            }

            if (arr[i].file_display_name) {
              fileName = arr[i].file_display_name
            } else {
              fileName = arr[i].fileName.split('/')[1]
            }
            obj.file_type = 'saw'
          } else {
            let setName = _translateFileNameFn(
              arr[i].filetype,
              ncSetting.customizeFolder,
              arr[i].filetype
            )
            originFile = setName
            if (arr[i].file_display_name) {
              fileName = arr[i].file_display_name
            } else {
              fileName = arr[i].filename
            }
          }
          obj.url = url
          obj.fileName = fileName
          obj.originFile = originFile
          obj.fileType = 'url'
          qtData.push(obj)
        }
        this.isShowLoading = false
        if (!this.checkFolderName(ncSetting, qtData)) return
        const { cuttingEquipment } = this.proLineList.find(
          (item) => this.state.process_line_id === item.id
        ).data
        const isElec = cuttingEquipment === 'electronicSaw'
        let paibanDataObj = {
          layoutData: this.preLayoutData,
          paibanData: this.oriArr,
          process_setting_id: isElec
            ? this.state.process_line_id
            : this.ncSetting.process_setting_id,
          process_setting_name: isElec
            ? this.currentProLineName
            : this.ncSetting.process_setting_name,
          recodeSelectPart: this.$store.state.recodeSelectPart,
          recodeSelectSurplus: this.$store.state.recodeSelectSurplus,
          thinkerxMaterialKeys: this.$store.state.thinkerxMaterialKeys,
        }
        let ncData = {
          layoutData: paibanDataObj,
          ncData: qtData,
          orderCodeAsDirectionName: isElec
            ? undefined
            : this.ncSetting.folderCategory.orderCodeAsDirectionName,
        }
        this.isDownloadingNc = false
        window.webToQt.ncDataToQtV2
          ? window.webToQt.ncDataToQtV2(ncData)
          : window.webToQt.ncDataToQt(qtData)
        return
      } catch (err) {
        this.$message({
          message: `<span id="material_nc_error">${err}</span>`,
          type: 'error',
          dangerouslyUseHTMLString: true,
        })
        throw err
      }
    },
    // 电子锯数据处理
    dealElectronicSawData(arr) {
      let orderType = this.OrderTypeMap['singleOrder']
      const hasOrderNo = arr.some((item) => item.order_no)
      const hasNoOrderNo = arr.some((item) => !item.order_no)
      const hasRoom = arr.some((item) => item.room_name)
      const hasNoRoom = arr.some((item) => item.room_name)
      const getOrderNos = (data) => {
        return data
          .filter((item) => {
            if (item.file_type === 'saw') {
              return item
            }
          })
          .filter((item) => item.order_no)
          .map((item) => item.order_no)
      }
      // 获取所有order_no
      const allOrderNos = getOrderNos(arr)
      const uniqueOrderNos = new Set(allOrderNos) // 检查是否有数据缺少order_no
      // 根据订单数量设置orderType
      const hasMissingOrderNo = arr
        .filter((item) => {
          if (item.file_type === 'saw') return item
        })
        .some((item) => !item.order_no)
      if (!hasOrderNo) {
        orderType = this.OrderTypeMap['noOrder']
      } else if (hasMissingOrderNo || uniqueOrderNos.size > 1) {
        orderType = this.OrderTypeMap['multiOrder']
      } else if (uniqueOrderNos.size === 1) {
        orderType = this.OrderTypeMap['singleOrder']
      }
      if (hasOrderNo && hasNoOrderNo) {
        arr.forEach((item) => {
          if (!item.order_no) {
            item.partHasOrder = true
          }
        })
      }
      if (hasRoom && hasNoRoom) {
        arr.forEach((item) => {
          if (!item.room_name) {
            item.partHasRoom = true
          }
        })
      }
      const orderCounts = arr.reduce((accumulator, current) => {
        const key = current.order_no
        if (!accumulator[key]) {
          accumulator[key] = []
        }
        accumulator[key].push(current)
        return accumulator
      }, {})
      return {
        orderType,
        orderCounts,
        hasOrderNo,
        hasNoOrderNo,
        hasRoom,
        hasNoRoom,
      }
    },
    getLabelPaibanImg() {
      let imgArr = {}
      const oriArr = this.paibanData
      if (this.ncSetting.labelingMachine) {
        let imgExportArr = []
        for (
          let i = 0;
          i < this.ncSetting.layoutImageSetting.layoutImage.length;
          ++i
        ) {
          let imgSetting = this.ncSetting.layoutImageSetting.layoutImage[i]
          let obj = {
            type: imgSetting.imageFormat,
            prefix: imgSetting.imageNamePrefix,
            direction: imgSetting.imageDirection == 'horiz' ? true : false, // 判断是横向图片还是竖向图片
            way: imgSetting.way,
            enable: imgSetting.enable,
          }
          if (obj.direction) {
            obj.width = imgSetting.imageSize[1]
            obj.height = imgSetting.imageSize[0]
          } else {
            obj.width = imgSetting.imageSize[0]
            obj.height = imgSetting.imageSize[1]
          }
          obj.index = i + 1
          imgExportArr.push(obj)
        }
        let plankWidth = this.ncSetting.drawPlankWidth
        let plankHeight = this.ncSetting.drawPlankHeight
        // 循环输出两种排版图
        for (let j = 0; j < imgExportArr.length; ++j) {
          if (!imgExportArr[j].enable) continue
          imgArr[j + 1] = []
          let direction = imgExportArr[j].direction
          // 创建canvas标签
          let oCanvas = document.createElement('canvas')

          // 设置canvas长宽
          oCanvas.width = Number(imgExportArr[j].width)
          oCanvas.height = Number(imgExportArr[j].height)
          let ctx = oCanvas.getContext('2d')
          let fontSize = 14
          // 如果是横向, 则需要切换旋转canvas, 切换canvas的长宽
          if (direction) {
            var temp = oCanvas.width
            oCanvas.width = oCanvas.height
            oCanvas.height = temp
          }
          // 循环绘制每一块大板
          for (let i = 0; i < oriArr.length; ++i) {
            ctx.clearRect(0, 0, oCanvas.width, oCanvas.height)
            let center = {
              x: 0,
              y: 0,
            }
            ctx.fillStyle = '#fff'
            ctx.fillRect(0, 0, oCanvas.width, oCanvas.height)

            // 如果是横向, 则以中心点位置旋转, 并重新计算原点坐标
            if (direction) {
              center = {
                x: Math.round(oCanvas.width / 2),
                y: Math.round(oCanvas.height / 2),
              }
              ctx.translate(center.x, center.y)
              ctx.rotate(Math.PI / 2)
              ctx.translate(-center.y, -center.x)
            }

            // 设置绘制的属性
            ctx.fillStyle = '#fff'
            let finalScale = 0
            let startX = 0
            let startY = 0

            // 根据余料或者正常大板, 计算绘制起始点(居中绘制)
            if (
              oriArr[i].surplusInfo &&
              Object.keys(oriArr[i].surplusInfo).length > 0
            ) {
              let surplusInfo = oriArr[i].surplusInfo
              // 减少一个文字大小的高度和宽度
              let scaleX =
                Number(surplusInfo.width) / Number(imgExportArr[j].width - 64)
              let scaleY =
                Number(surplusInfo.height) / Number(imgExportArr[j].height - 64)
              finalScale = scaleX > scaleY ? scaleX : scaleY
              startX =
                Math.abs(
                  surplusInfo.width / finalScale - imgExportArr[j].width
                ) / 2
              startY =
                Math.abs(
                  surplusInfo.height / finalScale - imgExportArr[j].height
                ) / 2
              if (surplusInfo.shape && surplusInfo.shape == 'lshape') {
                let x3 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.y5)
                  : Number(surplusInfo.x3)
                let y3 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.x5)
                  : Number(surplusInfo.y3)
                let x4 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.y4)
                  : Number(surplusInfo.x4)
                let y4 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.x4)
                  : Number(surplusInfo.y4)
                let x5 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.y3)
                  : Number(surplusInfo.x5)
                let y5 = this.ncSetting.xyReverse
                  ? Number(surplusInfo.x3)
                  : Number(surplusInfo.y5)
                let newWidth = surplusInfo.width
                let newHeight = surplusInfo.height
                y3 = newHeight - y3
                y4 = newHeight - y4
                y5 = newHeight - y5

                ctx.beginPath()
                ctx.moveTo(startX, startY)

                ctx.lineTo(startX + x3 / finalScale, startY + y3 / finalScale)
                ctx.lineTo(startX + x4 / finalScale, startY + y4 / finalScale)
                ctx.lineTo(startX + x5 / finalScale, startY + y5 / finalScale)
                ctx.lineTo(
                  startX + newWidth / finalScale,
                  startY + newHeight / finalScale
                )
                ctx.lineTo(startX, startY + newHeight / finalScale)

                ctx.closePath()
                ctx.stroke()
              } else {
                ctx.strokeRect(
                  startX,
                  startY,
                  surplusInfo.width / finalScale,
                  surplusInfo.height / finalScale
                )
              }
            } else {
              let scaleX =
                Number(plankWidth) / Number(imgExportArr[j].width - 64)
              let scaleY =
                Number(plankHeight) / Number(imgExportArr[j].height - 64)
              finalScale = scaleX > scaleY ? scaleX : scaleY
              startX =
                Math.abs(plankWidth / finalScale - imgExportArr[j].width) / 2
              startY =
                Math.abs(plankHeight / finalScale - imgExportArr[j].height) / 2
              ctx.strokeRect(
                startX,
                startY,
                plankWidth / finalScale,
                plankHeight / finalScale
              )
              ctx.fillRect(
                startX,
                startY,
                plankWidth / finalScale,
                plankHeight / finalScale
              )
            }

            // 将图片进行居中
            for (let k = 0; k < oriArr[i].parts.length; ++k) {
              let part = oriArr[i].parts[k]
              this.drawParts(
                ctx,
                part,
                startX,
                startY,
                finalScale,
                imgExportArr[j].way,
                direction,
                center
              )
            }

            if (direction) {
              ctx.translate(center.y, center.x)
              ctx.rotate(-Math.PI / 2)
              ctx.translate(-center.x, -center.y)
            }

            // 获取的方法getGraghEdge
            let { size } = getGraghEdge(oriArr[i], this.ncSetting)
            ctx.fillStyle = '#333'
            ctx.font = `${fontSize}px 'PingFangSC-Regular, PingFang SC'`
            let str = `${oriArr[i].thick}${oriArr[i].matCode}${oriArr[i].texture}`
            let usedRate = oriArr[i].usedRate
              ? oriArr[i].usedRate >= 1
                ? `${(100).toFixed(2)}%`
                : `${(oriArr[i].usedRate * 100).toFixed(2)}%`
              : '0%'
            let textWidth = this.calcTextSize(str, fontSize)
            let textStartX = (oCanvas.width - textWidth) / 2
            let textStartY = direction ? startX : startY
            //排版图3单独处理
            if (imgExportArr[j].way == 3) {
              str = `第${i + 1}/${oriArr.length}张大板   利用率：${usedRate}`
              let strSecond = `材质:${oriArr[i].matCode}   颜色:${oriArr[i].texture}   规格：${size}`
              textWidth = this.calcTextSize(strSecond, fontSize)
              textStartX = (oCanvas.width - textWidth) / 2
              ctx.fillText(str, textStartX, textStartY - 20)
              ctx.fillText(strSecond, textStartX, textStartY - 4)
              for (let k = 0; k < oriArr[i].parts.length; ++k) {
                let part = oriArr[i].parts[k]
                this.drawText(ctx, part, startX, startY, finalScale)
              }
            } else {
              ctx.fillText(str, textStartX, textStartY - 4)
            }

            let imgUrl = oCanvas.toDataURL()
            imgArr[j + 1].push({
              url: imgUrl,
              name:
                imgExportArr[j].prefix + (i + 1) + '.' + imgExportArr[j].type,
              index: imgExportArr[j].index,
            })
          }
          if (document.body.contains(oCanvas)) {
            document.body.removeChild(oCanvas)
          }
        }
      }
      return imgArr
    },
    drawParts(ctx, part, startX, startY, scale, way) {
      ctx.strokeStyle = '#0008'
      ctx.lineWidth = 1
      let rect = {
        x: startX + part.startX / scale,
        y: startY + part.startY / scale,
        width: part.rect.width / scale,
        height: part.rect.height / scale,
      }
      if (part.path) {
        for (let i = 0; i < part.path.length; ++i) {
          ctx.beginPath()
          for (let k = 0; k < part.path[i].length; ++k) {
            let x = rect.x + part.path[i][k].x / scale
            let y = rect.y + part.path[i][k].y / scale
            if (k == 0) {
              ctx.moveTo(x, y)
            } else {
              ctx.lineTo(x, y)
            }
          }
          ctx.closePath()
          ctx.stroke()
        }
      } else {
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height)
      }
      // 绘制孔、槽、牛角拉手、异型孔(只有排版图3才画)
      if (way == 3) {
        if (part.holes?.length > 0) {
          part.holes.forEach((hole) => {
            ctx.beginPath()
            ctx.arc(
              startX + (part.startX + hole.center.x) / scale,
              startY + (part.startY + hole.center.y) / scale,
              hole.diameter / scale,
              0,
              Math.PI * 2
            )
            ctx.closePath()
            ctx.stroke()
          })
        }
        if (part.slots?.length > 0) {
          part.slots.forEach((slot) => {
            const { x, y, width, height } = getSlotBasicInfo(slot, 'pt')
            ctx.beginPath()
            ctx.strokeRect(
              startX + (part.startX + x) / scale,
              startY + (part.startY + y) / scale,
              width / scale,
              height / scale
            )
            ctx.closePath()
          })
        }
        if (part.curveHoles?.length > 0) {
          part.curveHoles.forEach((curveHole) => {
            ctx.beginPath()
            for (let index = 0; index < curveHole.path.length; index++) {
              const curve = curveHole.path[index]
              let x, y
              x = startX + (part.startX + curve.x) / scale
              y = startY + (part.startY + curve.y) / scale
              if (index == 0) {
                ctx.moveTo(x, y)
              } else {
                ctx.lineTo(x, y)
              }
            }
            ctx.stroke()
            ctx.closePath()
          })
        }
        if (part.path?.length > 1) {
          part.path.forEach((path) => {
            ctx.beginPath()
            for (let index = 0; index < path.length; index++) {
              const curPath = path[index]
              let x, y
              x = startX + (part.startX + curPath.x) / scale
              y = startY + (part.startY + curPath.y) / scale
              if (index == 0) {
                ctx.moveTo(x, y)
              } else {
                ctx.lineTo(x, y)
              }
            }
            ctx.stroke()
            ctx.closePath()
          })
        }
        if (part.millInfo?.length > 0) {
          part.millInfo.forEach((mill) => {
            ctx.beginPath()
            for (let index = 0; index < mill.shape.length; index++) {
              const curMill = mill.shape[index]
              let x, y
              x = startX + (part.startX + curMill.x) / scale
              y = startY + (part.startY + curMill.y) / scale
              if (index == 0) {
                ctx.moveTo(x, y)
              } else {
                ctx.lineTo(x, y)
              }
            }
            ctx.stroke()
            ctx.closePath()
          })
        }
      }
    },
    drawText(ctx, part, startX, startY, scale) {
      let fontSize = 13
      ctx.fillStyle = '#333'
      ctx.font = `${fontSize}px 'PingFangSC-Regular, PingFang SC'`
      let lineHeight = 13
      let rect = {
        x: startX + part.startX / scale,
        y: startY + part.startY / scale,
        width: part.rect.width / scale,
        height: part.rect.height / scale,
      }
      let partInfo = `${part.realRect.height.toFixed(
        2
      )}*${part.realRect.width.toFixed(2)}*${part.thick}`

      let { y: partNameHeight, isFull } = this.wrapText(
        ctx,
        part.partName,
        rect.y + lineHeight,
        lineHeight,
        rect
      )
      if (!isFull) {
        this.wrapText(
          ctx,
          partInfo,
          lineHeight + partNameHeight,
          lineHeight,
          rect,
          'partInfo'
        )
      }
    },
    wrapText(ctx, text, y, lineHeight, rect, partInfo) {
      let { x, y: rectY, width: maxWidth, height: maxHeight } = rect
      let words = text.split('')
      let line = ''
      let isFull = false
      let textStartY = y
      let partNameHeight = partInfo ? y - rectY - lineHeight : 0
      for (let n = 0; n < words.length; n++) {
        let testLine = line + words[n]
        let metrics = ctx.measureText(testLine)
        let testWidth = metrics.width
        if (testWidth > maxWidth && n > 0) {
          if (y - textStartY + lineHeight + partNameHeight < maxHeight) {
            ctx.fillText(line, x, y)
            line = words[n] + ' '
            y += lineHeight
          } else {
            isFull = true
            line = ''
            break
          }
        } else {
          line = testLine
        }
      }
      ctx.fillText(line, x, y)
      return { y, isFull }
    },
    calcTextSize(text, fontSize) {
      let span = document.createElement('span')
      let result = {}
      result.width = span.offsetWidth
      result.height = span.offsetHeight
      span.style.visibility = 'hidden'
      span.style.fontSize = `${fontSize}px`
      span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
      span.style.display = 'inline-block'
      document.body.appendChild(span)
      if (typeof span.textContent != 'undefined') {
        span.textContent = text
      } else {
        span.innerText = text
      }
      result.width =
        parseFloat(window.getComputedStyle(span).width) - result.width
      document.body.removeChild(span)
      let width = result.width
      return width
    },
    // 处理余料参数和排版参数
    dealSurplusParams(allSurplusList, ncSetting, resultArr, store) {
      // 生成余料参数
      const surplusObj = genSurplusParams(allSurplusList)
      const paramObjArr = []
      resultArr.forEach(({ result }) => {
        const paramObj = genPaibanRequestParams(store, surplusObj, result)
        paramObjArr.push(paramObj)
      })
      this.getPaibanData(paramObjArr, ncSetting)
    },
    // 获取排版接口的数据, 并处理成绘制的数据
    getPaibanData(data, ncSetting) {
      this.beforePaibanDataDeal()
      this.setFinalDrawData([])
      let paibanWay = this.state.paibanWay
      if (ncSetting.glass_setting) {
        paibanWay = 'paiban'
      }
      // 清空收集的多排版方案
      this.$store.commit('paibanStore/setPaibanDataCollect', [])
      this.$store.commit('paibanStore/clearAlreadyDealDrawData')
      this.setFilterMaterialList(this.filterPlankList)
      dealPaibanData(
        data,
        this.prePaibanData,
        paibanWay,
        true,
        true,
        false,
        this.isNewLayout
      ).then(async (re) => {
        this.setHistoryPlankEdgeOff(this.selectStandardPlank.plankEdgeOff)
        this.setHistorySecondTrimValue(this.ncSetting.secondTrimValue)
        this.deleteTempStorage('clear')
        if (!re) {
          this.isShowLoading = false
          return
        }
        // this.setIsNewPaiban(true)

        this.setActivePaibanWay([paibanWay])
        _hmt.push(['_trackEvent', '开始排版', 'click'])
        if (re.isPaibanErr) {
          this.isPaibanErr = re.isPaibanErr
          this.isShowLoading = false
          this.isNewLayout = true
          return
        }
        if (re?.isErr) {
          this.$message.error(translate('arrangedPage.arrangedErrTip'))
          this.isShowLoading = false
          this.showProLineDialog = true
          this.paibanMessage = re.message
          return
        }

        this.showLoading = false
        if (this.isFromLbl) {
          this.$router.push({
            path: '/newPaiban',
            query: {
              from: 'lbl',
            },
          })
        } else if (this.state.isSpecialShape) {
          this.$router.push({
            path: '/newPaiban',
            query: {
              isSpecialShape: this.state.isSpecialShape,
            },
          })
        } else {
          this.$router.push({
            path: '/newPaiban',
            query: {
              setting_id: this.state.process_line_id,
              from: 'material_paiban',
            },
          })
        }
      })
    },
    // 排版前的综合数据处理，如数据初始化，已经某些数据清空
    beforePaibanDataDeal() {
      // 新的排版重置待排版库未入库提示
      this.$store.commit('awaitPaibanStore/setIsNoPromptPushStore', false)
    },
    // 检测柜柜数据是否更新
    detectGuiguiBaseMIsUpdate() {
      this.$token('/check_guigui_plate_data', {}, (res) => {
        if (res.status == 1) {
          this.BaseMIsSame = res.data.is_same
        }
      })
    },

    checkPlankIsAvailable(texDir, partInfo, plankInfo) {
      // notcare无纹理
      if (texDir !== 'notcare') {
        return (
          partInfo.width <= plankInfo.width &&
          partInfo.height <= plankInfo.height
        )
      } else {
        const [partMin, partMax] = [partInfo.width, partInfo.height].sort(
          (a, b) => a - b
        )
        const [plankMin, plankMax] = [plankInfo.width, plankInfo.height].sort(
          (a, b) => a - b
        )
        return partMin <= plankMin && partMax <= plankMax
      }
    },
    hideAddBaseMModal() {
      this.showAddBaseMModal = false
      this.lackBaseMaterials = []
    },
    toBaseMaterial() {
      this.$router.push({
        path: '/baseMaterial',
      })
    },
    handleCloseBigPlate() {
      this.showAddSpecialPlate = false
      this.bigPlateList = []
      this.isDelBigPlate = false
    },
    // 超尺板件input校验
    handleBigPlateInput(val, row, prop, $index) {
      const inputRefs = `${prop}${$index}`
      let input = this.$refs[inputRefs].$refs.input
      val += ''
      val = val
        .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
        .replace(/\.{2,}/g, '.') // 去除多余小数点
        .replace('.', '@_@') // 将小数点暂存为其他字符
        .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
        .replace('@_@', '.') // 将暂存的小数点替换回来
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      // 限制输入0
      if (val == 0) {
        val = ''
      }
      row[prop] = val
      if (row.height < 2440) {
        row.showHInputIcon = true
      } else {
        row.showHInputIcon = false
      }
    },
    handleBigPlateWInput(val, row, prop, $index) {
      const inputRefs = `${prop}${$index}`
      let input = this.$refs[inputRefs].$refs.input
      val += ''
      val = val
        .replace(/[^\d.]/g, '') // 去除数字和小数点以外的数字
        .replace(/\.{2,}/g, '.') // 去除多余小数点
        .replace('.', '@_@') // 将小数点暂存为其他字符
        .replace(/\./g, '') // 替换多余的小数点, 防止输入时的闪烁
        .replace('@_@', '.') // 将暂存的小数点替换回来
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      // 限制输入0
      if (val == 0) {
        val = ''
      }
      row[prop] = val
      if (row.width < 1220) {
        row.showWInputIcon = true
      } else {
        row.showWInputIcon = false
      }
    },
    handleDelBigPlate($index) {
      this.bigPlateList.splice($index, 1)
      this.isDelBigPlate = true
    },
    handleGoDetail() {
      this.showBigPlateDetail = true
      this.showAddSpecialPlate = false
    },
    handleCloseDetail() {
      this.showBigPlateDetail = false
      this.showAddSpecialPlate = true
    },
    handleSavePlankInfo() {
      const copyBigPlateList = JSON.parse(JSON.stringify(this.bigPlateList))
      copyBigPlateList.forEach((item) => {
        this.materialRes.specialPlankInfo.forEach((speItem) => {
          if (!this.materialRes.isAutoSelected) {
            if (
              item.matCode == speItem.matCode &&
              item.color == speItem.color &&
              item.thick == speItem.thick
            ) {
              speItem.isPicked = false
            }
          }
        })
        if (!this.materialRes.isAutoSelected) {
          item.isPicked = true
        }
        item.height = Number(item.height)
        item.width = Number(item.width)
        item.trim_edge =
          this.currentSelectStandardPlank &&
          Object.keys(this.currentSelectStandardPlank)
            ? this.currentSelectStandardPlank.plankEdgeOff
            : this.defaultSelectedPlank.plankEdgeOff
        item.matCode = item.is_high_gloss_plank
          ? `高光_${item.matCode}`
          : item.matCode
        item.is_high_gloss_plank = item.is_high_gloss_plank ? 1 : 0
      })
      let payLoad = {
        uid: this.userInfo.id,
        data: {
          specialPlankInfo: [
            ...this.materialRes.specialPlankInfo,
            ...copyBigPlateList,
          ],
        },
      }

      this.$token('/save_plank_manage_setting_info', payLoad, async (res) => {
        if (res.status == 1) {
          const dealRes = await calcBaseMaterial(
            this.currentSelectPlankList,
            this.selectStandardPlank,
            this.state.process_line_id,
            (errType, errText = '') => {
              this.isShowLoading = false
              if (errType === 'plank') {
                this.dealPlateErr(errText)
                this.resetNCSetting()
              }
            }
          )
          const extraLongPlates = dealRes.setNotMatchPartList
          if (dealRes) {
            this.updateMaterialData(dealRes)
          }
          if (this.isDelBigPlate) {
            this.$message({
              type: 'error',
              message: this.$t('materialPage.overSize.error'),
            })
            this.showAddSpecialPlate = false
            this.showBigPlateDetail = false
            this.bigPlateList = []
            this.isDelBigPlate = false
            this.isAddBigPlateLoading = false
          } else {
            this.$message({
              type: 'success',
              message: this.$t('materialPage.overSize.successTip'),
            })
            this.showAddSpecialPlate = false
            this.showBigPlateDetail = false
            this.bigPlateList = []
            this.isDelBigPlate = false
            this.isAddBigPlateLoading = false
            if (extraLongPlates && extraLongPlates.length) {
              setTimeout(() => {
                this.$message({
                  type: 'error',
                  message: this.$t('materialPage.overSize.error'),
                })
              }, 1000)
              return
            }
            this.dealLayoutData(this.state)
            setTimeout(() => {
              this.isShowLoading = true
            }, 500)
          }
        }
      })
    },
    // 超尺板件一键添加
    handleAddBigPlate() {
      this.isAddBigPlateLoading = true
      if (this.bigPlateList.some((item) => !item.height || !item.width)) {
        this.$message({
          type: 'warning',
          message: this.$t('materialPage.overSize.warningTip'),
        })
        this.isAddBigPlateLoading = false
      } else {
        this.handleSavePlankInfo()
      }
    },

    isEmpty(val) {
      return val === '' || val === undefined
    },
    handleChangeSize() {
      this.plankKeyWord = ''

      // 两个之中有一个为空
      if (this.isEmpty(this.maxSize) || this.isEmpty(this.minSize)) {
        // 显示全部
        this.filterPlankList = this.beforeSizeFilterList ?? this.plankList
      } else {
        if (Number(this.minSize) > Number(this.maxSize)) {
          this.sizeError = true
          return
        } else {
          this.sizeError = false
        }
        // 进行筛选
        let plankList = this.beforeSizeFilterList ?? this.filterPlankList
        let newPlankList = plankList.map((plank) => {
          return {
            ...plank,
            parts: plank.parts.filter((part) => this.isInRangeSize(part)),
          }
        })
        this.filterPlankList = newPlankList.filter(
          (plank) => plank.parts.length
        )
      }
      this.filterPlankList = dealPlankListLabelID(this.filterPlankList)
    },
    // 是否在区间内
    isInRangeSize(part) {
      return (
        (part.specHeight <= Number(this.maxSize) &&
          part.specHeight >= Number(this.minSize)) ||
        (part.specWidth <= Number(this.maxSize) &&
          part.specWidth >= Number(this.minSize))
      )
    },
    handleCancel() {
      this.isPaibanErr = false
      this.showProLineDialog = false
      this.isConfirmLoading = false
    },
    handleSendToFactory() {
      this.sendToFactoryDialog = true
    },
    handleCloseSend() {
      this.sendToFactoryDialog = false
    },
    async handleConfirmSend(temp, state) {
      this.sendToFactoryDialog = false
      this.showProLineDialog = false
      this.isSendToFactory = true
      try {
        if (state) this.state = state
        this.dealLayoutData(state, true)
      } catch (error) {
        this.$confirm(this.$t('materialPage.sendFactoryErr'))
      }
    },
    setProLineList(proLineList) {
      this.proLineList = proLineList
    },
    // 复制行数据
    copyLine({ part }) {
      const plank = this.plankList.find((it) => it.symbol === part.typeSymbol)
      let idx = plank.parts.findIndex(
        (it) => it.partUniqueId === part.partUniqueId
      )
      idx++
      part = JSON.parse(JSON.stringify(part))
      // 删除标识板件的数据
      delete part.plankID
      delete part.simplePlankNum
      delete part.ggid
      part.amount = 1
      const plankNum = uniquePlankNum.createUniquePlankNum()
      uniquePlankNum.plankNumCollect.clear()
      part.plankNum = plankNum
      part.oriPlankNum = plankNum
      part.plankID = generatePlankId(this.preLayoutData)
      part.partUniqueId = nanoid()
      generateSimplePlankNum(part)
      plank.parts.splice(idx, 0, part)
      this.updateLayoutData()
      if (this.beforeSearchMaterialList) {
        const newBeforeSearchList = JSON.parse(
          JSON.stringify(this.beforeSearchMaterialList)
        )
        newBeforeSearchList.forEach((plank) => {
          if (plank.symbol === part.typeSymbol) {
            let idx = plank.parts.findIndex(
              (it) => it.partUniqueId === part.partUniqueId
            )
            plank.parts.splice(idx, 0, part)
          }
        })
        this.setBeforeSearchMaterialList(newBeforeSearchList)
        this.setBeforeSizeFilterList(newBeforeSearchList)
      }
      this.dealFilterData()
      this.fixPlankDataType(part, this.lastFilterProp)
      this.$message.success(
        this.$t('materialPage.copySuccess', { name: part.partName })
      )
    },
    // 保存偏好设置
    savePreferences() {
      this.preferencesSetting.isLoadMoreTable = this.isLoadMoreTable
      savePreferencesSetting(this.preferencesSetting)
    },
    // 保存偏好设置（默认收起数据）
    async savePreferencesForDefaultFoldData(obj) {
      try {
        const {
          target: { checked },
        } = obj
        // 新增菜单项默认收起数据
        this.preferencesSetting.pick_up_data = checked
        await savePreferencesSetting(this.preferencesSetting)
        this.handleDefaultFoldData(checked)
      } catch (error) {
        this.$message.error('默认收起数据设置失败')
      }
    },
    handlePlankFilter(item) {
      this.filterVisibel = false
      this.getFilterItem(item.prop)
      this.filterVisibel = true
    },
    getFilterItem(prop) {
      this.filterSearchVal = this.searchVal.get(prop) ?? ''
      this.filterProp = prop // 当前过滤项
      let partArr = this.plankList.map((plank) => plank.parts).flat(1)
      const allPartProps = Array.from(
        new Set(partArr.map((part) => part[this.filterProp]))
      )
      if (!this.lastFilterProp) {
        this.filterProps = Array.from(
          new Set(partArr.map((part) => part[prop]))
        )
        this.filterProps.unshift(prop)
      } else if (this.lastFilterProp == prop) {
        this.filterProps = this.lastFilterProps
      } else {
        this.filterProps = Array.from(
          new Set(
            partArr
              .filter((part) => {
                if (this.filterObj[this.lastFilterProp]) {
                  return this.filterObj[this.lastFilterProp].includes(
                    part[this.lastFilterProp]
                  )
                } else {
                  return allPartProps
                }
              })
              .map((p) => p[prop])
          )
        )
        this.filterProps.unshift(prop)
      }

      // if (!this.filterObj[prop] || !this.filterObj[prop].length)
      //   this.filterObj[prop] = this.filterProps
    },
    onAfterFilterClose() {
      this.filterSearchVal = ''
    },
    handleCancelFilter() {
      this.filterVisibel = false
    },
    handleConfirmFilter(filterVals, search) {
      this.plankKeyWord = ''
      this.minSize = ''
      this.maxSize = ''
      this.filterObj[this.filterProp] = filterVals
      this.searchVal.set(this.filterProp, search)
      if (!filterVals.length || filterVals.length == this.filterProps.length) {
        delete this.filterObj[this.filterProp]
        this.searchVal.delete(this.filterProp)
        this.dealFilterData()
        this.filterVisibel = false
      }
      this.dealFilterData()
      this.lastFilterProp = this.filterProp
      this.lastFilterProps = this.filterProps
      this.setBeforeSearchMaterialList(this.filterPlankList)
      this.setBeforeSizeFilterList(this.filterPlankList)
      this.filterVisibel = false
    },
    handlePlankEdit() {
      this.editerVisibel = !this.editerVisibel
    },
    handleConfirmEditer(form) {
      if (form.prop == 'allEdge') {
        let edgeList = ['frontEdge', 'backEdge', 'leftEdge', 'rightEdge']
        edgeList.forEach((edge) => {
          form = { ...form, prop: edge }
          this.filterPlankList = this.dealPlankList(
            this.filterPlankList,
            form,
            form.isAllRows
          )
        })
      } else {
        this.filterPlankList = this.dealPlankList(
          this.filterPlankList,
          form,
          form.isAllRows
        )
      }
      this.filterPlankList = dealPlankListLabelID(this.filterPlankList)
      this.setFilterMaterialList(this.filterPlankList)
      this.editerVisibel = false
      // TODO 需要修改this.preLayoutData
      this.updateLayoutData()
    },
    handleCancelEditer() {
      this.editerVisibel = false
    },
    dealPlankList(plankList, form, isAll) {
      const copyForm = cloneDeep(form)
      let hasEditPart = []
      if (copyForm.prop === 'is_high_gloss_plank') {
        copyForm.editValue = !!copyForm.editValue
      }
      let result = plankList.map((plank) => ({
        ...plank,
        parts: plank.parts.map((part) => {
          let newPart = part
          const copyPart = JSON.parse(JSON.stringify(part))
          if (isAll) {
            newPart[copyForm.prop] = copyForm.editValue
            if (copyForm.prop === 'texDir') {
              newPart.beforeTexDir = copyPart.texDir
              this.changePlankTexDir(copyForm.editValue, newPart)
            }
            this.clearPlankObjHoleSlotsInfo(part, copyForm.prop)

            hasEditPart.push(part.partUniqueId)
          } else if (part.isSelected) {
            newPart[copyForm.prop] = copyForm.editValue
            if (copyForm.prop === 'texDir') {
              newPart.beforeTexDir = copyPart.texDir
              this.changePlankTexDir(copyForm.editValue, newPart)
            }
            this.clearPlankObjHoleSlotsInfo(part, copyForm.prop)
            hasEditPart.push(part.partUniqueId)
          }
          return newPart
        }),
      }))
      this.plankList.forEach((plank) => {
        plank.parts.forEach((part) => {
          if (hasEditPart.includes(part.partUniqueId)) {
            part[copyForm.prop] = copyForm.editValue
            this.clearPlankObjHoleSlotsInfo(part, copyForm.prop)
          }
        })
      })
      if (this.beforeSearchMaterialList) {
        const newList = JSON.parse(
          JSON.stringify(this.beforeSearchMaterialList)
        )
        newList.forEach((plank) => {
          plank.parts.forEach((part) => {
            if (hasEditPart.includes(part.partUniqueId)) {
              part[copyForm.prop] = copyForm.editValue
              this.clearPlankObjHoleSlotsInfo(part, copyForm.prop)
            }
          })
        })
        this.setBeforeSearchMaterialList(newList)
        this.setBeforeSizeFilterList(newList)
      }
      this.plankList = dealMaterialList(this.plankList)
      this.setMaterialList(this.plankList)

      result = dealMaterialList(result)

      return result
    },
    clearPlankObjHoleSlotsInfo(part, prop) {
      if (['specWidth', 'specHeight'].includes(prop) || prop.includes('Edge')) {
        let newPart = this.plankSpecialDeal(part)
        Object.assign(part, newPart)
        delete part.path
        delete part.oPath
        delete part.curveHoles
        delete part.millInfo
        delete part.edge_length_info
      }
    },

    handleResetFilter() {
      delete this.filterObj[this.filterProp]
      this.searchVal.delete(this.filterProp)

      this.dealFilterData()
      this.filterVisibel = false
      setTimeout(() => {
        this.filterProp = ''
        this.filterProps = []
        this.lastFilterProp = ''
        this.lastFilterProps = []
      }, 200)
    },
    dealFilterData() {
      // 给plankList增加isUnfold属性
      this.plankList = this.plankList.map((plank) => {
        const filterPlank = this.filterPlankList.find(
          (fp) =>
            `${fp.matCode}-${fp.thick}-${fp.texture}` ===
            `${plank.matCode}-${plank.thick}-${plank.texture}`
        )
        if (filterPlank) {
          plank.isUnfold = filterPlank.isUnfold
        }
        return {
          ...plank,
          isUnfold: plank.isUnfold,
        }
      })
      this.filterPlankList = (
        this.plankKeyWord || (this.minSize && this.maxSize)
          ? this.filterPlankList
          : this.plankList
      )
        .map((plank) => ({
          ...plank,
          parts: plank.parts.filter((part) => {
            let isFilterArr = []
            Object.keys(this.filterObj).forEach((key) => {
              if (this.filterObj[key].includes(part[key])) {
                isFilterArr.push(true)
              } else {
                isFilterArr.push(false)
              }
            })
            return isFilterArr.every((isFilter) => isFilter)
          }),
        }))
        .filter((plank) => plank.parts.length)
      this.plankList = dealPlankListLabelID(dealMaterialList(this.plankList))
      this.filterPlankList = dealPlankListLabelID(
        dealMaterialList(this.filterPlankList)
      )
      this.setFilterMaterialList(this.filterPlankList)
      this.setFilterObject(this.filterObj)
    },
    // 板件数据类型修正
    fixPlankDataType(plank, prop) {
      const isNumber = [
        'thick',
        'specHeight',
        'specWidth',
        'frontEdge',
        'backEdge',
        'leftEdge',
        'rightEdge',
      ].includes(prop)
      const transProps = isNumber ? Number(plank[prop]) : plank[prop]
      this.plankList.forEach((plank) => {
        plank.parts.forEach((part) => {
          if (isNumber) part[prop] = Number(part[prop])
        })
      })
      if (this.filterObj[prop]) {
        if (!this.filterObj[prop].includes(transProps)) {
          this.filterObj[prop].push(transProps)
        }
        this.filterObj[prop] = arrDeduplication(this.filterObj[prop])
      }
      if (!this.lastFilterProps.includes(transProps)) {
        this.lastFilterProps.push(transProps)
      }
      this.lastFilterProps = arrDeduplication(this.lastFilterProps)
    },
    handleConfirmCustom() {
      this.filterObj = {}
      this.searchVal.clear()

      this.filterPlankList = this.plankList
      // 修改了表头以后， 让数据的收起状态遵循默认偏好设置（这里是为了解决输入条件进行筛选之后，会修改列表中的isUnfold属性）
      this.filterPlankList = this.filterPlankList.map((plank) => {
        return {
          ...plank,
          isUnfold: !this.isDefaultFoldData,
        }
      })
      this.setFilterMaterialList(this.filterPlankList)
      this.setBeforeSearchMaterialList(this.filterPlankList)
      this.setBeforeSizeFilterList(this.filterPlankList)
      this.searchPlank(true)
      // 判断用户是否保存，如果没有
      if (!this.savedHightLightProp) {
        this.savedHightLightProp = true
        // 保存偏好设置
        this.preferencesSetting.saved_highlight_prop = this.savedHightLightProp
        savePreferencesSetting(this.preferencesSetting)
      }
    },
    getSelectLine(value) {
      this.selectProLine = value
    },
    async handleExportThreeView() {
      buryPointApi('material', 'plank_hole_slot_three_views')
      const parts = this.filterPlankList.map((plank) => plank.parts).flat(1)
      // 加载字体
      await jsPdfTool.loadFont('msyh')
      const drawView = new DrawPartView()
      try {
        parts.forEach((part, idx) => {
          const tableInfo = {
            date: ' ',
            version: ' ',
            modifiedCount: '',
            designer: ' ',
            size: `${Number(part.oRect.width).toFixed(1)}*${Number(
              part.oRect.height
            ).toFixed(1)}*${Number(part.thick).toFixed(1)}`,
            pic: ' ',
            matCode: part.is_high_gloss_plank
              ? `高光_${part.matCode}`
              : part.matCode,
            check: ' ',
            color: part.texture,
            count: part.amount,
            percent: ' ',
            approval: ' ',
            index: idx + 1,
            total: parts.length,
            orderNo: part.orderNo,
            partName: part.partName,
            proCount: ' ',
          }
          if (part.specWidth < part.specHeight) {
            const newPart = JSON.parse(JSON.stringify(part))
            rotatePart(newPart)
            drawView.drawThreeView(newPart, () => {
              drawView.drawInfoTable(tableInfo)
            })
          } else {
            drawView.drawThreeView(part, () => {
              drawView.drawInfoTable(tableInfo)
            })
          }
          drawView.pdf.addPage()
        })
        drawView.pdf.deletePage(drawView.pdf.getNumberOfPages())
        drawView.save(`${this.orderInfo.order_address}-三视图`)
      } catch (err) {
        this.$message({
          type: 'error',
          message: this.$t('materialPage.exportThreeViewErr'),
          center: true,
        })
      }
    },
    dealRecentHistoryName(historyName) {
      let targetName = historyName
      if (historyName.includes('FromWebPage')) {
        const nameList = historyName.split('_')
        const nameListLen = nameList.length
        const dealName = nameList.slice(0, nameListLen - 2).join('_')
        targetName = dealName
      }
      if (historyName.includes('WebBluen')) {
        const nameList = historyName.split('_')
        const nameListLen = nameList.length
        const dealName = nameList.slice(0, nameListLen - 2).join('_')
        targetName = dealName
      }
      return targetName
    },
    handleBackTotask() {
      if (!this.hasSendToTask && this.preLayoutData.length) {
        this.sendTaskModal = this.$antdConfirm({
          title: this.$t('common.tip'),
          content: this.$t('materialPage.createTaskCard'),
          okText: this.$t('common.confirm'),
          cancelText: this.$t('common.cancel'),
          centered: true,
          closable: true,
          onOk: () => {
            this.isTaskLoading = true
            createTask(this.preLayoutData).then(() => {
              this.isTaskLoading = false
              this.setHasSendToTask(true)
              this.$router.push('/taskList')
              buryPointApi('task', 'creat_task')
            })
          },
          onCancel: (e) => {
            this.handleCancelToTask(e)
          },
        })
      } else {
        this.$router.push('/taskList')
      }
    },
    handleCancelToTask(e) {
      const isCancel = typeof e == 'function'
      this.sendTaskModal && this.sendTaskModal.destroy()
      if (isCancel) {
        this.$router.push('/taskList')
      }
    },
    // 数据检查
    checkFolderName(ncSetting, zip) {
      let repeatZipFileNameList = []
      let repeatZipFolderNameList = []
      let tempZipFileNameList = []
      if (zip?.files) {
        repeatZipFileNameList = JSON.parse(
          JSON.stringify(this.$store.state.repeatFileName)
        )
      } else {
        // 内嵌
        zip.forEach((item) => {
          if (!item?.file_type === 'saw') {
            // 判断合并文件内部是否有文件存在重名
            if (
              item.originFile.indexOf(
                ncSetting.customizeFolder?.merge_folder_display_name
              ) !== -1
            ) {
              if (tempZipFileNameList.includes(item.fileName)) {
                repeatZipFileNameList.push(item.fileName)
              } else {
                // 存入值
                tempZipFileNameList.push(item.fileName)
              }
            }
          }
        })
        repeatZipFileNameList = [...new Set(repeatZipFileNameList)]
      }
      // 获取当前存储的文件内容数据，判断它是否在合并文件夹中
      // 如果不在，那么它的名称不能和合并文件夹一样
      Object.keys(this.$store.state?.folderContents)?.forEach((item) => {
        if (
          !ncSetting?.customizeFolder?.merge_folder_list.includes(
            fileNameSetObj[item] + '文件'
          ) &&
          ncSetting?.customizeFolder?.merge_folder_list.length > 1
        ) {
          if (
            ncSetting.customizeFolder.merge_folder_display_name.trim() ===
            this.$store.state.folderContents[item].trim()
          ) {
            repeatZipFolderNameList.push(fileNameSetObj[item])
          }
        }
      })

      this.repeatFileNameList = repeatZipFileNameList
      this.repeatFolderNameList = repeatZipFolderNameList
      this.repeatNameList = []
        .concat(repeatZipFolderNameList)
        .concat(repeatZipFileNameList)
      if (this.repeatNameList.length) {
        this.isShowWarningModal = true
        this.isShowLoading = false
        this.isConfirmLoading = false
        return false
      }
      return true
    },
    displayRender({ labels }) {
      return labels[labels.length - 1]
    },
    handleSelectTexture(val) {
      this.newPlankInfo.texture = val[val.length - 1] ?? ''
      this.colorSearchKey = ''
      this.setNewMaterialColorList(val)
    },
    // 选择材质
    handleSelectMatCode(val) {
      this.newPlankInfo.matCode = val
      this.setNewMaterialMatCode(val)
    },
    onBeforeFilter(val) {
      this.colorSearchKey = val
    },
    //按回车对材质进行的操作
    handleEnterMatCode(e) {
      // 编辑时使用this.newPlankInfo.matCode的值去判断
      const inputMatCode = e?.target?.value ?? this.newPlankInfo.matCode
      // 当为空或者全是空格时不进行操作
      if (!inputMatCode) return
      // 判断数组是否已经存在当前添加的数据
      const isExist = checkMatCodeIsExist(inputMatCode, this.matcodeList)
      // 获取本地的材质数据
      let otherMatCodes = localStorage.getItem('matcodeList')
        ? JSON.parse(localStorage.getItem('matcodeList'))
        : []
      if (!isExist) {
        // 如果不存在
        otherMatCodes.push(inputMatCode)
        localStorage.setItem('matcodeList', JSON.stringify(otherMatCodes))
        // 将数据添加到数组中，并且显示
        let matcodeSelectOption = {
          label: inputMatCode,
          value: inputMatCode,
        }
        this.matcodeList.push(matcodeSelectOption)
      }
      // 保证条目被选中
      this.newPlankInfo.matCode = inputMatCode
      this.setNewMaterialMatCode(inputMatCode)
    },
    handleEnterColor() {
      if (!this.colorSearchKey) return
      const isExist = checkColorIsExist(
        this.colorSearchKey,
        this.materialColorOptions
      )

      let otherColors = localStorage.getItem('colorList')
        ? JSON.parse(localStorage.getItem('colorList'))
        : []

      if (isExist) {
        this.newPlankInfoTexture = isExist
        this.colorSearchKey = isExist[isExist.length - 1]
      } else {
        otherColors.push(this.colorSearchKey)
        this.newPlankInfoTexture = ['other', this.colorSearchKey]
        localStorage.setItem('colorList', JSON.stringify(otherColors))
        const lastIndex = this.materialColorOptions.length - 1
        this.materialColorOptions[lastIndex].children.push({
          label: this.colorSearchKey,
          value: this.colorSearchKey,
        })
      }
      // 使用enter选择颜色后,给表单项赋值
      this.newPlankInfo.texture = this.colorSearchKey
      this.setNewMaterialColorList(this.newPlankInfoTexture)
    },
    handleDeleteColorSearch(type) {
      const ref = this.$refs['addColorCascaderRef']
      this.colorSearchKey = ref.$children[0].value
    },
    async handleOrderMarkOk() {
      this.isShowOrderMark = false
      this.isCancontinue = true
      this.handleProlineIsElectronicSaw(this.isSendToFactory)
      this.$token('/save_preferences_setting', { data: this.state }, (res) => {
        if (res.status != 1) {
          this.isConfirmLoading = false
          this.$message.error(res.msg)
        } else {
          this.setActivePaibanWay([this.state.paibanWay])
        }
      })
      if (!this.orderIds.length) {
        return
      }
      // 更新标记状态
      const data = {
        order_ids: [...new Set(this.orderIds)],
        mark_action: this.proucePopupState.mark_action,
      }
      await updateProduceStatus(data)
    },
    handleOrderMarkCancel() {
      this.isShowOrderMark = false
      this.isCancontinue = false
    },
    async handleShowOrderMark() {
      const hasOrderIds = JSON.parse(
        JSON.stringify(this.selectionPlankList)
      ).some((item) => item.orderId)
      if (hasOrderIds) {
        this.orderIds = JSON.parse(JSON.stringify(this.selectionPlankList)).map(
          (item) => item.orderId
        )
      } else {
        this.isCancontinue = true
        return
      }
      const fliterGGIds = JSON.parse(localStorage.getItem('ggOrderIds')) || []
      // 计算 fliterGGIds 中每个值的重复次数
      const countMap = fliterGGIds.reduce((map, value) => {
        map.set(value, (map.get(value) || 0) + 1)
        return map
      }, new Map())

      // 过滤 this.orderIds 中包含在 fliterGGIds 中的值，并确保输出数组中每个值的重复次数不超过 fliterGGIds 中的重复次数
      this.orderIds = this.orderIds.filter((value) => {
        if (countMap.has(value) && countMap.get(value) > 0) {
          countMap.set(value, countMap.get(value) - 1)
          return true
        }
        return false
      })
      // 所有的板件都是补件也不显示任何弹窗
      const isAllBujian = this.preLayoutData.every(
        (item) => item._isBujian === true
      )
      if (!this.orderIds.length || isAllBujian) {
        return (this.isCancontinue = true)
      }
      const data = {
        order_ids: [...new Set(this.orderIds)], //真实ids
      }
      const proucePopupState = await getProucePopupState(data)
      if (proucePopupState.status) {
        const { data } = proucePopupState
        Object.assign(this.proucePopupState, data)
      }

      if (
        proucePopupState.data.is_display_mark ||
        proucePopupState.data.is_produced_orders
      ) {
        this.isShowOrderMark = true
      } else {
        this.isShowOrderMark = false
      }
    },
    // 默认收起数据赋值操作
    handleDefaultFoldData(isDefaultFoldData) {
      this.isDefaultFoldData = isDefaultFoldData
      // 如果勾选了默认收起数据,需要给开料清单，可用余料，可用补件设置默认收起,修改表格不改变默认收起状态
      if (this.isDefaultFoldData) {
        // 可用补件
        store.commit('onChangeTrigger', !this.isDefaultFoldData)
        // 可用余料
        this.onChangeIsShowSurplusList(this.isDefaultFoldData)
        // 开料清单
        this.filterPlankList = this.filterPlankList.map((plank) => {
          return {
            ...plank,
            isUnfold: !this.isDefaultFoldData,
          }
        })
        // 待排版库收起
        this.$nextTick(() => {
          this.$refs['awaitStoreRef']?.setExpand(!this.isDefaultFoldData)
        })
      }
    },
    // 开料清单切换为图展示的时候，切换折叠状态
    handleChangeFold(plank) {
      plank.isUnfold = !plank.isUnfold
    },
    // 是否显示tooltip
    showTooltip(value, surplus, prop) {
      let dom = this.$refs[`surplus_${surplus.id}_${prop}`][0]
      if (dom) {
        if (dom.offsetWidth < dom.scrollWidth) {
          this.$set(surplus, `show${prop}`, true)
        } else {
          this.$set(surplus, `show${prop}`, false)
        }
        if (!value) {
          surplus[`show${prop}`] = false
        }
      }
    },
    // 显示未排小板清单
    handleShowPartDetail() {
      this.showPartDetail = true
      this.showProLineDialog = false
    },
    handleClosePartDetail() {
      this.showPartDetail = false
      this.showProLineDialog = true
    },
    translateLang(key) {
      return translate(key)
    },
    dealRecord() {
      this.recordSaveTableHeader = this.$store.state.inPerferencesTableHeader
        ?.filter((item) => item.label !== '选择')
        .map((item) => item.label)
    },
    dealPlateErr() {
      this.isError = true
      this.isShowLoading = false
      this.$confirm(plate_knife_map[key].error, '提示', {
        confirmButtonText: '前往添加',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const setting_id = this.state.process_line_id,
            line = this.activeProLineName,
            equipType = 'engraving'
          this.$router.push({
            path: `/${equipType}`,
            query: {
              setting_id,
              line,
              equipType,
            },
          })
        })
        .catch(() => {
          this.isError = false
        })
    },
    updateMaterialData(res) {
      this.lackBaseMaterials = res.lackBaseMaterials
      this.needBaseMaterials = res.needBaseMaterials
      this.autoAddSpecialPlate = res.autoAddSpecialPlate
      this.materialRes = res.materialRes
    },
  },
  created() {
    if (
      !this.$route.query.thinkerx_material &&
      !sessionStorage.getItem('first') &&
      !this.$route.query.guigui_token
    ) {
      //不是从第三方跳转过来的 && 刚登陆 显示弹窗
      const data = { page: 1, limit: 7, plate_from: 'bluen', auto_save: 2 }
      // this.visible = true
      this.$token('/get_material_data', data, (res) => {
        //弹出弹窗则请求数据
        if (res.status == 1) {
          let data = res.data.material_list || []
          this.recentHistory = data
        }
      })
    }
    var search = window.location.href
    if (
      search.includes('thinkerx_material') &&
      this.$route.query.thinkerx_material
    ) {
      this.newPlankInfo.texDir = 'notcare'
    } else if (search.includes('thinkerx_material') == false) {
      this.newPlankInfo.texDir = 'normal'
    }
    if (this.thirdPlankData && this.thirdPlankData.length) {
      let arr = dealThinkerxMaterialData(this.thirdPlankData)
      this.dealData(arr)
    }
  },
  async mounted() {
    // 开启loadding
    this.isTaskLoading = true
    // 加载偏好设置
    await getPreferencesSetting(
      window.sessionStorage.getItem('thinkerx_material'),
      this.userInfo.platform
    )
      .then((res) => {
        if (res.status === 1) {
          // 更新store模块中的偏好设置，后续需要使用store中的偏好设置替换页面中的使用方法
          this.$store.commit('preferencesSetting/setSetting', res.data)
          if (res.data.paibanWay.includes('blue_ai')) {
            res.data.paibanWay = 'paiban_v2'
            this.selectedPaibanWay = 'paiban_v2'
          }
          const {
            isBujianRecommend,
            isPaibanStoreRecommend,
            isSurplusRecommend,
          } = res.data
          this.preferencesSetting = res.data
          // 推荐参数默认值
          this.preferencesSetting.isBujianRecommend = isBujianRecommend ?? true
          this.preferencesSetting.isPaibanStoreRecommend =
            isPaibanStoreRecommend ?? true
          this.preferencesSetting.isSurplusRecommend =
            isSurplusRecommend ?? true
          this.isCheckBigPlankSynchronous = res.data.isCheckBigPlankSynchronous
          this.isLoadMoreTable =
            this.preferencesSetting.isLoadMoreTable ?? false

          // 获取该用户是否保存过表头
          this.savedHightLightProp = res.data.saved_highlight_prop

          // 新增菜单项默认收起数据处理
          this.handleDefaultFoldData(
            this.preferencesSetting.pick_up_data ?? false
          )
        }
      })
      .finally(() => {
        // 确保渲染完成后，取消loading
        this.$nextTick(() => {
          setTimeout(() => {
            this.isTaskLoading = false
          }, 500)
        })
      })
    this.currentSelectStandardPlank = {}
    this.currentHistoryPlankEdgeOff = 0
    // 获取材质列表的数据
    this.matcodeList = await fetchMaterialMatCodes()
    if (window?.webToQt?.loadIndicatorHide) {
      window.webToQt.loadIndicatorHide()
    }
    if (!this.materialColorOptions.length) {
      this.materialColorOptions = this.materialColorList
    }
    this.proLineList = this.$store.state.proLineList
    if (this.isTrialProduct && this.trailProductState === 1) {
      this.$antdConfirm({
        title: '温馨提示',
        content:
          '当前试生产订单已调整为使用当前账号的工艺设置数据，请注意您的工艺设置！',
        okText: '确定',
        class: 'trail-tech-modal',
        centered: true,
      })
    }
    if (this.$route) {
      if (this.$route.query.isTask) {
        this.setHasSendToTask(true)
        const res = await httpRequest.get(this.$route.query.paibanJson)
        this.dealJsonData(res)
      } else if (this.$route.query.isBatch) {
        this.setHasSendToTask(true)
        if (this.batchPaiban) {
          this.dealJsonData(this.batchPaiban)
        } else {
          const res = await httpRequest.get(this.$route.query.paibanJson)
          this.dealJsonData(res)
        }
      }
      this.setImportCount(1)
    }
    this.samPleFormData = JSON.parse(JSON.stringify(this.sampleTableData))
    this.$nextTick(() => {
      if (
        !this.$route.query.thinkerx_material &&
        !sessionStorage.getItem('first') &&
        !this.$route.query.guigui_token
      ) {
        //不是从第三方跳转过来的 && 刚登陆 显示弹窗
        this.visible = true
        this.$token('/get_material_data', { page: 1, limit: 7 }, (res) => {
          //弹出弹窗则请求数据
          if (res.status == 1) {
            let data = res.data.material_list || []
            this.recentHistory = data
          }
        })
      }
      sessionStorage.setItem('first', 1) //是否是刚登录
    })
    if (this.$route.query && this.$route.query.from) {
      this.$store.commit('changeQtFrom', this.$route.query.from)
      sessionStorage.setItem('QtFrom', this.$route.query.from)
    }

    let a = [1, 2, 3]
    let b = []
    let c = b.concat(a)
    a[0] = 6
    this.detectGuiguiBaseMIsUpdate()
    if (window.sessionStorage.getItem('isHistory')) {
      this.changeHistoryStatus(true)
    }
    const guiguiJumpEquipmentParams = JSON.parse(
      window.sessionStorage.getItem('guiguiJumpEquipmentParams')
    )
    // 根据token和data_id获取线上数据
    const queryKeys = Object.keys(this.$route.query)
    if (!queryKeys.includes('isTask') && !queryKeys.includes('isBatch')) {
      if (
        (queryKeys.length > 0 &&
          !queryKeys.includes('material_cache_data') &&
          !this.$route.query.thinkerx_material) ||
        sessionStorage.getItem('isThirdOder') ||
        (guiguiJumpEquipmentParams &&
          Object.keys(guiguiJumpEquipmentParams).length > 0)
      ) {
        if (this.$route.query.data_id || guiguiJumpEquipmentParams?.data_id) {
          this.dealDataFromGuigui()
          return
        }
        if (this.$route.query.oids) {
          this.dealDataFromLbl()
          return
        }
        return
      } else {
        this.isTableEmpty = false
      }
    }
    if (this.preLayoutData.length > 0) {
      this.layoutData = this.preLayoutData
      this.dealData(this.preLayoutData)
      this.dealFilterData()
      return
    }
    let thinkerx_material = sessionStorage.getItem('thinkerx_material')
    let third_material = sessionStorage.getItem('material_cache_data')
    if (window.sessionStorage.getItem('materialParams') && !thinkerx_material) {
      this.isTableEmpty = true
      this.emptyShow = true
      this.setIsShowSample(false)
      this.setIsLinkLogin(true)
      let params = JSON.parse(window.sessionStorage.getItem('materialParams'))
      this.setPreLayoutParam(params)
      this.setOrderInfo(params.orderInfo)
      let obj = {
        addition_info: this.orderInfo.addition_info,
      }
      if (params.type == 'roomId') {
        obj.room_ids = params.data
      } else {
        obj.order_ids = params.data
      }
      this.$token('/get_layout_data', obj, (res) => {
        this.isTableEmpty = false
        if (res.status == 1) {
          this.setImportCount(1)
          let arr = []
          for (let i = 0; i < res.data.result_data.length; i++) {
            arr = [
              ...arr,
              ...res.data.result_data[i].map((part) => ({
                ...part,
                srcTexDir: part.texDir,
              })),
            ]
          }
          if (res.data.addition_data && res.data.addition_data.length) {
            arr = arr.concat(this.handleBujianPlanks(res.data.addition_data))
          }
          this.dealData(arr)
          this.dealFilterData()
          this.emptyShow = false
          const orderGroup = res.result_data.reduce((res, item) => {
            const orderId = item[0].order_id
            res[orderId] = item.length
            return res
          }, {})
          this.setOrderInfo(
            Object.assign(params.orderInfo, {
              batch: orderGroup,
            })
          )
        }
      })
    } else if (thinkerx_material) {
      this.emptyShow = true
      this.isTableEmpty = true
      this.setIsShowSample(false)
      this.setIsLinkLogin(true)
      let base = ''
      try {
        base =
          thinkerx_material && sessionStorage.getItem('thinkerx_url')
            ? sessionStorage.getItem('thinkerx_url').replace(/http:/g, 'https:')
            : ''
      } catch (error) {
        console.error('error', error)
      }

      const guimen_url = base
        ? `${base}${thinkerx_material}.json`
        : `https://mini2015.thinkerx.com/menerp/runtime/export/${thinkerx_material}.json`
      axios({
        url: guimen_url,
      }).then((res) => {
        this.setImportCount(1)
        if (res.data.length > 0) {
          this.isTableEmpty = false
          let guimenKeys = []
          for (let key in res.data[0]) {
            if (key != 'remark_names') {
              guimenKeys.push(key)
            }
          }
          this.recordGuimenDataKeys(guimenKeys)
        } else {
          this.isTableEmpty = false
        }
        let arr = dealThinkerxMaterialData(res.data)
        this.dealData(arr)
        this.dealFilterData()
        if (res.data.length !== 0) {
          let { factoryId } = res.data[0]
          sessionStorage.setItem('thinkerx_material_factory_id', factoryId)
        }
        this.emptyShow = false
      })
    } else if (third_material) {
      // 第三方数据参数处理
      const dataKey = sessionStorage.getItem('material_cache_data')
      const token = sessionStorage.getItem('third_token')
      this.isTableEmpty = true
      getThirdData({
        token: token,
        bluen_data_key: dataKey,
      }).then((res) => {
        this.setImportCount(1)
        if (res.data.plank_data.length) {
          let arr = dealThinkerxMaterialData(res.data.plank_data)
          this.dealData(arr)
          this.dealFilterData()
          this.emptyShow = false
        }
      })
    }
  },
  beforeDestroy() {
    // 在离开开料清单的时候，根据小板的高光属性将小板的材质拼接上高光
    const newPreLayoutData = this.preLayoutData.map((part) => {
      return {
        ...part,
        matCode: part.is_high_gloss_plank
          ? `高光_${part.matCode}`
          : part.matCode,
      }
    })
    this.setPreLayoutData(newPreLayoutData)
  },
}
</script>

<style scoped lang="less">
.new-material-list {
  margin: 16px;
  /deep/ .sampleTable {
    flex: 1;
    min-width: 1548px;
    height: 100%;
    background: #f5f5f5;

    .el-form .el-row {
      align-items: center;
      justify-content: space-around;
      margin: 0;

      .el-col {
        width: 300px;

        .el-form-item {
          display: flex;
          align-content: center;
          margin-bottom: 0;

          label {
            color: #000;
            font-weight: 600;
          }
        }
      }
    }
  }
  .material-list-main {
    width: 100%;
    min-width: 1600px;
    background: #fff;

    // margin: 0 auto;
    // margin-top: 24px;
    .top-operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 24px;
      overflow-x: auto;

      .top-left-operation {
        display: flex;
        align-items: center;

        .ant-btn {
          margin-right: 8px;
          max-width: 132px;
          span {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        input[type='file'] {
          display: none;
        }

        .more-setting {
          .more-icon {
            transform: rotate(-180deg);
            transition: transform 0.5s;
          }

          &:hover .more-icon {
            transform: rotate(0deg);
          }
        }
      }

      .ant-btn {
        display: flex;
        align-items: center;

        .iconfont {
          margin-right: 2px;
        }

        &.paiban-btn {
          color: #fff;
          background: #18a8c7;
        }
      }

      .top-right-operation {
        display: flex;
        align-items: center;

        /deep/.ant-input-search .ant-input {
          width: 226px;
        }

        /deep/ .ant-input-group-wrapper {
          width: auto;
        }

        /deep/ .ant-input-search-button {
          padding-right: 5px;
          padding-left: 5px;
        }

        :deep(.ant-input-number-handler-wrap) {
          display: none;
        }

        :deep(.el-input-number__decrease) {
          display: none;
        }

        :deep(.el-input-number__increase) {
          display: none;
        }

        :deep(.el-input__inner) {
          padding: 0 11px;
          text-align: left;

          &:focus {
            border-color: #18a8c7;
          }
        }

        .size-error {
          :deep(.el-input__inner) {
            border-color: red;
          }
        }

        .toggle-box {
          margin-left: 8px;

          .toggle-icon {
            display: inline-block;
            width: 60px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border: 1px solid #ddd;
            cursor: pointer;
            user-select: none;
          }

          .toggle-icon:first-child {
            border-radius: 4px 0 0 4px;
          }

          .toggle-icon:last-child {
            margin-left: -4px;
            border-radius: 0 4px 4px 0;
          }

          .toggle-icon.active {
            color: #18a8c7;
            border-color: #18a8c7;
          }
        }
      }
    }

    .main-table {
      box-sizing: border-box;
      overflow: auto;

      .virtualList {
        /deep/ .grey {
          color: #ccc;
        }

        /deep/ .common {
          color: #18a8c7;
        }

        .plank-door-new-content {
          justify-content: space-evenly;

          /deep/ .ant-select {
            width: 60%;
            border: none;

            .ant-select-selection:active {
              border: none;
              box-shadow: unset;
            }

            .ant-select-selection {
              flex: 1;
              border: none;
            }
          }
        }

        .copy-line {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1000;
          width: 16px;
          height: 100%;
        }
      }
      .high-plank {
        box-sizing: border-box;
        padding: 3px 7px;
        color: #18a8c7;
        text-align: center;
        border: 2px solid #18a8c7;
        border-radius: 5px;
        margin-left: 5px;
      }
    }

    .plank-category {
      position: sticky;
      z-index: 2001;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      height: 50px;
      padding: 0 8px;
      line-height: 50px;
      background: #fff;
      border: 1px solid #eee;
      cursor: pointer;
    }

    .draw-part {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      // justify-content: space-between;
    }

    .common-height {
      box-sizing: border-box;
      height: 40px;
      padding: 0 24px;
      color: #333;
      font-size: 14px;
      line-height: 40px;
      background: #f5f5f5;
      box-shadow: 0 -1px 0 0 rgb(204, 204, 204), 0 1px 0 0 rgb(204, 204, 204);
      cursor: default;
    }

    .surplus-headers {
      span {
        display: inline-block;
      }
    }

    .surplus-list {
      max-height: 300px;
      overflow-y: scroll;

      /* 改造滚动条样式 */
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.05);
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }

      &::-webkit-scrollbar-track {
        background-color: #eee;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track:hover {
        background-color: #eee;
      }

      .surplus-row {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        min-height: 40px;
        padding: 0 24px;
        overflow: hidden;
        white-space: normal;
        word-break: break-all;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }

    .no-surplus {
      padding: 13px 0;
      text-align: center;

      img {
        display: block;
        width: 64px;
        margin: 0 auto 8px;
      }

      span {
        color: #333;
        font-size: 14px;
        cursor: default;
      }

      .new-surplus {
        color: #18a8c7;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .surplus-blank {
      width: 100%;
      height: 24px;
      background: #fff;
      box-shadow: 0 -1px 0 0 #ccc, 0 1px 0 0 #ccc;
    }

    .top-headers {
      span {
        display: inline-block;
      }
    }

    .surplus-info {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      color: #333;
      font-size: 14px;

      .surplus-num {
        color: #18a8c7;
      }
      .surplus-title {
        cursor: pointer;
      }
    }

    .order-info {
      display: flex;
      align-items: center;
      overflow: hidden;
      border-top: 1px solid rgb(204, 204, 204);

      .order-info-name {
        display: inline-block;
        margin-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .order-title-box {
        margin-left: 20px;
        display: flex;
        align-items: center;
      }
    }

    .plank-list-table-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 24px;
      color: #333;
      font-size: 14px;
      line-height: 40px;
      background: #edf9fc;
    }

    .fold-operation {
      cursor: pointer;
      user-select: none;

      &:hover span {
        color: #18a8c7;
      }
    }

    .plank-row {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      min-height: 40px;
      padding: 0 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }

    .active-row {
      background: #bff8de;
    }

    .plank-cell {
      display: inline-block;
      min-height: 40px;
      color: #333;
      font-size: 14px;
      line-height: 40px;
      word-break: break-all;
      cursor: default;
    }

    .able-edit {
      box-sizing: border-box;
      padding-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      > span {
        cursor: pointer;
      }
    }

    .tex-dir /deep/.ant-select {
      width: 90%;
      border: none;

      .ant-select-selection {
        border: none;
      }

      .ant-select-selection:active {
        border: none;
        box-shadow: unset;
      }
    }
  }

  /deep/.el-drawer__header {
    margin-bottom: 0;
    padding: 0;
  }

  .drawer-main {
    position: relative;
    width: 100%;
    height: calc(100vh - 50px);
    padding-bottom: 60px;
  }

  .drawer-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 24px;
    font-size: 18px;
    border-color: rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid;
  }
  .content {
    max-height: 100%;
    overflow: auto;
  }
  .new-plank-form {
    box-sizing: border-box;
    padding: 24px 24px 0 24px;
    height: 90%;
    .new-plank-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }

      .plank-form-title {
        display: inline-block;
        flex-shrink: 0;
        width: 82px;
        color: #666;
        font-size: 14px;
        text-align: right;
        cursor: default;
      }

      .plank-form-input {
        width: 368px;
      }

      .plank-form-limit {
        color: #b0b0b0;
        font-size: 14px;
        cursor: default;
        user-select: none;
      }

      .plank-form-after {
        color: #b0b0b0;
        font-size: 14px;
        cursor: default;
        user-select: none;
      }

      .important-icon {
        margin-right: 4px;
        color: red;
        font-size: 14px;
      }

      .ant-select {
        width: 368px;
      }
      .plank-form-select,
      .plank-form-high-plank-select {
        width: 368px;
        .el-select {
          width: 100%;
        }
        .ant-select {
          width: 353px;
        }
      }
    }
  }

  .drawer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding: 0 56px 0 68px;
    background-color: #fff;
    box-shadow: 0 1px 0 0 rgb(229, 229, 229);

    span {
      display: inline-block;
      width: 120px;
      height: 32px;
      line-height: 32px;
      text-align: center;
    }

    .drawer-bottom-btn {
      box-sizing: border-box;
      color: #333;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 2px;
      cursor: pointer;
    }

    .confirm-btn {
      color: #fff;
      background: #18a8c7;
      border: none;
    }
  }

  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2005;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #0008;
  }

  .addBaseMModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1051;
    width: 100%;
    height: 100%;
    margin: auto;
    background: rgba(0, 0, 0, 0.7);

    .content {
      position: absolute;
      position: relative;
      top: 50%;
      right: 0;
      left: 0;
      box-sizing: border-box;
      width: 480px;
      margin: 0 auto;
      padding: 24px;
      background: #fff;
      border-radius: 4px;
      transform: translateY(-50%);
      .head {
        // display: flex;
        // align-items: center;
        .icon-warn {
          font-size: 30px;
          color: #fd7400;
        }
        .icon-close {
          font-size: 14px;
        }
      }

      .detailInfo {
        max-height: 300px;
        overflow-y: auto;
        .info-space,
        .info-detail {
          padding-left: 30px;
        }
        .info-detail {
          color: #18a8c7;
        }
        .tipInfo {
          margin-left: 35px;
        }
        span {
          font-size: 16px;
        }
      }

      .btn {
        .ant-btn {
          border: 0;
        }
        .add-btn {
          background-color: #3bbdd4;
          color: #fff;
          border-radius: 2px;
        }
      }

      .footer {
        margin-top: 40px;
        .prompt {
          height: 50px;
          .prompt-info {
            color: #d9001b;
          }
        }
      }
    }
  }

  //板件详情样式
  .plate-details {
    width: 480px;
    padding: 24px;
    top: 195px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    .icon-warn {
      font-size: 30px;
      color: #fd7400;
    }
    .icon-close {
      font-size: 14px;
    }
    .content {
      margin-left: 30px;
      .over-span {
        background-color: #000;
        padding: 0 10px;
        border-radius: 3px;
        line-height: 28px;
        color: #fff;
        top: -35px;
        left: 70px;
      }
      .input-box {
        height: 32px;
        width: 310px;
        line-height: 32px;
        border: 1px solid #ccc;
        .show-detail {
          width: 240px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .footer {
      .next-btn {
        color: #fff;
        background: #18a8c7;
      }
    }
  }

  .auto-add-big-plate {
    position: fixed;
    z-index: 1051;
    margin: auto;
    background: rgba(0, 0, 0, 0.7);
    .content {
      top: 50%;
      box-sizing: border-box;
      width: 800px;
      margin: 0 auto;
      transform: translateY(-50%);

      .head {
        padding: 10px 24px;
        .detail {
          color: #18a8c7;
        }
      }
      .tip {
        padding: 10px 24px;
        background-color: rgba(24, 168, 199, 0.1);
      }

      .btn {
        width: 120px;
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        background-color: #18a8c7;
      }
    }
  }
  .big-plate-detail {
    position: fixed;
    z-index: 1051;
    margin: auto;
    background: rgba(0, 0, 0, 0.7);
    .content {
      top: 50%;
      box-sizing: border-box;
      width: 1220px;
      margin: 0 auto;
      transform: translateY(-50%);

      .head {
        padding: 10px 20px 10px 10px;
      }
    }
  }
}

:deep(.prompt-info) {
  color: #d9001b;
}
.paiban-setting {
  .ant-form-item {
    display: flex;
    margin-bottom: 18px;
  }

  /deep/.ant-select-selection {
    width: 400px;
  }

  /deep/.ant-col {
    display: inline-block;
    width: 100%;

    label::after {
      content: '';
    }
  }

  /deep/.ant-modal {
    transition: all 0.3s;
  }

  /deep/.ant-modal-body {
    // height: 370px;
    padding: 24px;
    overflow: hidden;
    transition: all 0.3s;
  }

  .cut-checked {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 17px;
  }

  .group {
    overflow: hidden;
    transition: all 0.3s;

    > div {
      margin-bottom: 20px;

      .table-content {
        display: flex;
        justify-content: flex-start;

        .table {
          // width: 50%;
          flex: 1;
          margin-top: 30px;

          .btns {
            z-index: 999;
          }

          .page-table {
            margin-bottom: 10px;

            /deep/.container {
              .el-table--mini {
                .el-table__body-wrapper {
                  max-height: 160px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  div > span {
    margin-right: 16px;
  }
}

.is-surplus-cutting {
  /deep/.ant-modal-body {
    height: 750px;
    overflow-y: auto;
  }

  /deep/.ant-modal {
    width: 1100px !important;
  }
}

.plank-type-option {
  display: inline-block;
  height: 16px;
  text-align: center;
}
</style>

<style lang="less">
.w15 {
  width: 15%;
}

.new-material-list {
  .tex-dir {
    .el-select {
      .el-input {
        background: inherit;
        border: 0;

        .el-input__inner {
          padding-left: 0;
          text-align: left;
          background: inherit;
          border: none;
        }
      }
    }
  }
}

.plank-form-select.ant-select-dropdown {
  z-index: 3000;
}

//直接登录显示的弹窗的样式
.custom-model div.ant-modal-content {
  min-width: 800px;
  right: 27.5%;

  div.ant-modal-header {
    height: 58px;
    background-color: #fafafa;

    div.ant-modal-title {
      color: #333;
      font-weight: 500;
      font-size: 18px;
      font-family: PingFangSC-Medium, 'PingFang SC';
    }
  }

  div.ant-modal-body {
    height: 460px;
    padding: 0;

    article {
      display: flex;
      height: 100%;

      aside {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        width: 230px;
        padding: 60px 46px;
        background: #f7f7f7;

        div {
          width: 138px;
          height: 138px;
          background-size: 100% 100%;
          cursor: pointer;
          transition: all 0.3s;

          &.import {
            background-image: url('../../assets/image/import-default.png');

            &:hover {
              background-image: url('../../assets/image/import-active.png');
            }
          }

          &.add {
            background-image: url('../../assets/image/add-default.png');

            &:hover {
              background-image: url('../../assets/image/add-active.png');
            }
          }
        }
      }

      main {
        flex: 1;
        background-color: #fff;

        section.header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          height: 54px;
          padding: 0 19px 0 24px;
          box-shadow: inset 0 -1px 0 0 #e5e5e5;

          > span {
            color: #333;
            font-weight: 500;
            font-size: 16px;
            font-family: PingFangSC-Medium, 'PingFang SC';
          }

          a {
            span {
              color: #999;
              font-weight: 400;
              font-size: 14px;
              font-family: PingFangSC-Regular, 'PingFang SC';
            }

            i {
              color: #ccc;
            }
          }
        }

        section.body {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: 22px 16px 24px;

          ul.list {
            li.item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              height: 48px;
              padding: 0 16px;
              list-style: none;
              border-radius: 4px;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                background-color: #eee;
              }

              div {
                height: 100%;
                line-height: 48px;

                &.info {
                  display: flex;
                  align-items: center;

                  span {
                    &.data {
                      padding-right: 24px;
                      color: #18a8c7;
                      font-weight: 500;
                      font-size: 18px;
                      font-family: PingFangSC-Medium, 'PingFang SC';
                    }

                    &.title {
                      max-width: 18em;
                      overflow: hidden;
                      color: #333;
                      font-weight: 400;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, 'PingFang SC';
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                }

                &.time {
                  span {
                    color: #999;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, 'PingFang SC';
                  }
                }
              }
            }
          }

          div.no-data {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 100%;
            height: calc(100% - 46px);

            p {
              color: #999;
              font-size: 14px;
              font-family: PingFangSC-Regular, 'PingFang SC';
            }

            div.ant-empty-image {
              height: 41px;

              img {
                width: 64px;
                height: 41px;
              }
            }
          }
        }
      }
    }
  }
}

.filter-icon:hover {
  cursor: pointer;
  color: red;
}

.is-filter {
  color: #18a8c7;
}

.material-tooltip-card {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      height: 140px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}
.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9;
}

#material_color_input {
  width: 100%;
}
#materialListOrderMark {
  /deep/ .ant-modal {
    top: 30% !important;
  }
}
.trail-tech-modal {
  .ant-modal-confirm-btns {
    .ant-btn:first-child {
      display: none;
    }
  }
}
</style>
