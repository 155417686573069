import { SpecialPlankInfoType } from '@/apis/baseMaterial/type'
import type { IGetProductionNcSettingResponseType } from '@/apis/materialList/types'
import { IPropList } from '@/components/materialTable/src/config/tableConfig'

import { PartType } from './part'

// 开料清单可编辑字段
const editableFields = [
  'plankID',
  'roomName',
  'loc',
  'partName',
  'matCode',
  'texture',
  'thick',
  'specHeight',
  'specWidth',
  'amount',
  'texDir',
  'frontEdge',
  'backEdge',
  'leftEdge',
  'rightEdge',
  'type',
  'is_high_gloss_plank',
] as const

// 可筛选的字段
const filterableFields = [
  'roomName',
  'loc',
  'matCode',
  'texture',
  'thick',
  'specHeight',
  'specWidth',
  'amount',
  'texDir',
  'frontEdge',
  'backEdge',
  'leftEdge',
  'rightEdge',
  'type',
  'customer_name',
  'remark',
  'plank_remarks',
  'address',
  'order_id',
  'plankNum',
  'specialShape',
  'is_high_gloss_plank',
] as const

// 可编辑表格字段
export type IEditableFields = typeof editableFields[number]

//表头中可筛选的字段
export type IFilterableFieldsType = (
  | keyof Pick<PartType, typeof filterableFields[number]>
  | ''
) &
  IPropList['prop']

enum plankHighGlossType {
  highPlank = 1,
  notHighPlank = 0,
}
// 自动添加特殊的大板的字段类型
export interface IAutoAddPlankFieldsType {
  matCode: string
  thick: number
  color: string
  height: number | string
  width: number | string
  isPicked: boolean
  trim_edge: number
  is_high_gloss_plank: plankHighGlossType
}

// 样表表单字段
export interface SamPleFormDataType {
  address: string
  clientData: string
  factoryOrderNum: string
  roomName: string
  orderRemark: string
}

// 排版时，需要使用到的ncSetting类型
export type INcSettingType = IGetProductionNcSettingResponseType & {
  process_setting_id: number | null
  process_setting_name: string
  cuttingEquipment: string
  drawPlankWidth: number
  drawPlankHeight: number
}
// 获取Promise的类型
export type GetPromiseType<T> = T extends Promise<infer R>
  ? R extends Promise<any>
    ? GetPromiseType<R>
    : R
  : T

// 特殊大板的字段类型
export type INewMaterialListSpecialPlankType = SpecialPlankInfoType & {
  useJudgeWidth: number
  useJudgeHeight: number
  symbol: string
}

// 超尺小板的字段类型
export type INewMaterialListExtraPartType = PartType & {
  blankWidth: number
  blankHeight: number
  originWidth: number
  originHeight: number
}
